import { Typography } from "@mui/material";

export const getDiscountErrors = (invalidDiscounts: any[]) => {
  const errors = [];
  for (const invalidDiscount of invalidDiscounts) {
    //if we have a code error we dont care
    if (invalidDiscount.errors.includes("CODES")) {
      continue;
    }

    const error = invalidDiscount.errors.filter(
      (type: string) => type !== "CODES"
    )[0];

    const amount =
      invalidDiscount.discount.type === "PERCENTAGE"
        ? invalidDiscount.discount.amount * 100
        : invalidDiscount.discount.amount;

    const isPercent = invalidDiscount.discount.type === "PERCENTAGE";

    if (
      error === "MIN_AMOUNT" &&
      invalidDiscount.discount.products.length === 0 &&
      invalidDiscount.discount.categories.length === 0
    ) {
      errors.push({
        error: (
          <>
            À partir de{" "}
            <Typography color={"primary"} component={"span"} fontStyle="italic">
              {invalidDiscount.discount.minimumAmount}€
            </Typography>{" "}
            d’achat, profitez d’une remise de{" "}
            <Typography color={"primary"} component={"span"} fontStyle="italic">
              {amount}
              {isPercent ? "%" : "€"}
            </Typography>
          </>
        ),
        discount: invalidDiscount.discount,
        type: "MIN_AMOUNT",
      });
    } else if (error === "NUM_USAGES") {
      errors.push({
        error: `Mince, vous avez déjà utiliser ce code lors d’une précédente commande, il n’est malheureusement plus actif`,
        discount: invalidDiscount.discount,
        type: "NUM_USAGES",
      });
    }
  }

  return errors;
};
