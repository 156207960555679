import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import constants from "../constants";

import { BaseInput, Button, useIsMobile } from "@kuupanda/commons";
import {
  RiCheckFill as CheckIcon,
  RiCloseLine as ClearIcon,
} from "react-icons/ri";
import { Box, Typography } from "@mui/material";
import MinAmount from "./MinAmount";
import toast from "react-hot-toast";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "10px",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const APPLY_BUTTON_STATUS = {
  LOADING: "LOADING",
  STANDBY: "STANDBY",
  VALID: "VALID",
  INVALID: "INVALID",
};

const PromoCodeInput: React.FC<{
  initialCodes?: string[];
  onSubmit: any;
  codeEvaluationStatus: string;
  invalidDiscounts: any[];
  cartTotal: number;
}> = ({
  initialCodes = [],
  onSubmit,
  codeEvaluationStatus,
  invalidDiscounts,
  cartTotal,
}) => {
  const isMobile = useIsMobile();
  const [codes, setCodes] = useState(initialCodes.join(","));
  const [applyButtonStatus, setApplyButtonStatus] = useState(
    APPLY_BUTTON_STATUS.STANDBY
  );

  const handleCodeChange = useCallback(
    (event: any) => {
      if (applyButtonStatus !== APPLY_BUTTON_STATUS.STANDBY) {
        setApplyButtonStatus(APPLY_BUTTON_STATUS.STANDBY);
      }
      setCodes(event.target.value);
    },
    [applyButtonStatus]
  );
  // use if one wants to avoid requests if the code does not change
  // const splitCodes = useMemo(() => codes.split(","), [codes]);
  const handleChangeCodes = useCallback(() => {
    setApplyButtonStatus(APPLY_BUTTON_STATUS.LOADING);

    return onSubmit(codes.split(","));
  }, [codes, onSubmit]);

  const handleClear = useCallback(() => {
    setApplyButtonStatus(APPLY_BUTTON_STATUS.LOADING);
    setCodes("");

    toast.success("code promo supprimé", {
      id: "discount",
    });
    return onSubmit([]);
  }, [onSubmit]);

  const hasMinAmountDiscount = useMemo(
    () =>
      invalidDiscounts.filter(
        (error) =>
          error.type === "MIN_AMOUNT" &&
          error.discount.products.length === 0 &&
          error.discount.categories.length === 0
      ).length > 0,
    [invalidDiscounts]
  );

  useEffect(() => {
    if (
      codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.UNDETERMINED
    ) {
      return;
    }
    if (codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.VALID) {
      setApplyButtonStatus(APPLY_BUTTON_STATUS.VALID);
      toast.success("Remise valide", {
        id: "discount",
      });
    }
    if (codeEvaluationStatus === constants.CODE_EVALUATION_STATUS.INVALID) {
      const message = hasMinAmountDiscount
        ? "Le code est valide cependant vos devez atteindre un minimum de commande afin de bénéficier de la remise"
        : "Remise invalide";

      toast.error(message, {
        id: "discount",
      });
    }
  }, [codeEvaluationStatus, hasMinAmountDiscount]);

  return (
    <Box>
      <Container>
        <BaseInput
          placeholder="Code promo"
          onChange={handleCodeChange}
          value={codes}
          fullWidth={isMobile}
        />
        <Button onClick={handleChangeCodes} startIcon={<CheckIcon />}>
          Appliquer
        </Button>

        {codes && codes.length > 0 && (
          <Button onClick={handleClear} startIcon={<ClearIcon />}>
            Supprimer
          </Button>
        )}
      </Container>

      {invalidDiscounts && invalidDiscounts.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Box>
            {invalidDiscounts.map((error) => {
              if (
                error.type === "MIN_AMOUNT" &&
                error.discount.products.length === 0 &&
                error.discount.categories.length === 0
              ) {
                return (
                  <MinAmount
                    cartAmount={cartTotal}
                    minAmount={error.discount.minimumAmount}
                    discountAmount={error.discount.amount}
                    type={error.discount.type}
                  />
                );
              } else if (error.type === "NUM_USAGES") {
                return (
                  <Typography
                    fontStyle="italic"
                    color={"textSecondary"}
                    fontSize={14}
                    component={"div"}
                  >
                    {error.error}
                  </Typography>
                );
              } else {
                return null;
              }
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PromoCodeInput;
