import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Box } from "@mui/material";

const Container = styled("div", {
  shouldForwardProp: (prop: any) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  background: selected ? theme.palette.secondary.main : theme.palette.grey[200],
  padding: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  color: selected ? "#ffffff" : theme.palette.others.a,
  cursor: "pointer",
  border: selected
    ? `2px solid ${theme.palette.secondary.light}`
    : `2px solid ${theme.palette.grey[100]}`,
  [theme.breakpoints.up("md")]: {
    width: "235px",
  },
}));

const LargeButton: React.FC<{
  title: string;
  icon: React.ReactNode;
  selected: boolean;
  onClick: any;
}> = ({ title, icon, selected, onClick }) => {
  return (
    <Container selected={selected} onClick={onClick}>
      {icon}

      <Typography variant="caption" color="inherit">
        <Box fontWeight={500} textAlign="center" sx={{ width: "100%" }}>
          {title}
        </Box>
      </Typography>
    </Container>
  );
};

export default LargeButton;
