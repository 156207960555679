import React, { useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import ProductItemList from "./ProductItemList";
import { Box, Typography, useMediaQuery } from "@mui/material";
import ProductItemCard from "./ProductItemCard";
import { ProductModal } from "../common/ProductModal";
import { closeModalIframe, setModalIframePosition } from "../helpers/modal";
import { usePresentation } from "../context/PresentationContext";
import { useTheme } from "@mui/material/styles";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { EmailModal } from "../common/EmailModal";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const GridContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: "15px",
  gridTemplateColumns: "repeat(4, 1fr)",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

const ProductContainer: React.FC<{
  children: any;
  currentView: any;
}> = ({ children, currentView }) => {
  return currentView === CommonTypes.ProductViewMode.LIST ? (
    <Container>{children}</Container>
  ) : (
    <GridContainer>{children}</GridContainer>
  );
};

const ProductViewItem: React.FC<{
  product: any;
  currentView: any;
  onChangeCart: any;
  cart: any;
  saleChannel: CommonTypes.SaleChannel;
  isGroup: boolean;
  onVariableWeightClick: () => void;
  onSubmitNotifyStock: (value: Record<string, any>) => Promise<void>;
  onValidateCart: any;
  store: Record<string, any>;
}> = ({
  product,
  currentView,
  onChangeCart,
  cart,
  saleChannel,
  isGroup,
  onVariableWeightClick,
  onSubmitNotifyStock,
  onValidateCart,
  store,
}) => {
  const { isEmbedded } = usePresentation();

  const quantity = useMemo(
    () =>
      cart[product.id] && cart[product.id] ? cart[product.id].quantity : 0,
    [cart, product.id]
  );

  const theme = useTheme();

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productModalProduct, setProductModalProduct] = useState(null);
  const [isStockNotifyModalOpen, setIsStockNotifyModalOpen] = useState(false);
  const [stockModal, setStockModal] = useState({});

  const isProductModalFullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const onChange = useCallback(
    (quantity: any, size: string, childProducts: string[]) => {
      return onChangeCart({ product, quantity, size: size, childProducts });
    },
    [onChangeCart, product]
  );

  const closeProductModal = useCallback(() => {
    setIsProductModalOpen(false);
    closeModalIframe(isEmbedded, isProductModalFullScreen, "kuu-product-modal");
  }, [isEmbedded, isProductModalFullScreen]);

  const onProductClick = useCallback(
    (product: any) => {
      if (!isProductModalOpen) {
        setProductModalProduct(product);
        setIsProductModalOpen(true);
        setModalIframePosition(
          isEmbedded,
          isProductModalFullScreen,
          "kuu-product-modal"
        );
      }
    },
    [
      isEmbedded,
      isProductModalFullScreen,
      isProductModalOpen,
      setIsProductModalOpen,
    ]
  );

  const closeStockModal = useCallback(() => {
    setIsStockNotifyModalOpen(false);
    closeModalIframe(isEmbedded, isProductModalFullScreen, "kuu-stock-modal");
  }, [isEmbedded, isProductModalFullScreen]);

  const handleNotifyStock = useCallback(
    (product: any) => {
      if (!isStockNotifyModalOpen) {
        setStockModal(product);
        setIsStockNotifyModalOpen(true);
        setModalIframePosition(
          isEmbedded,
          isProductModalFullScreen,
          "kuu-stock-modal"
        );
      }
    },
    [isStockNotifyModalOpen, isEmbedded, isProductModalFullScreen]
  );

  const onSubmitStockAlert = async (email: string) => {
    closeStockModal();

    await onSubmitNotifyStock({ ...stockModal, email });
  };

  return (
    <>
      {currentView === CommonTypes.ProductViewMode.LIST ? (
        <ProductItemList
          product={product}
          quantity={quantity}
          onChangeCart={onChangeCart}
          onProductClick={onProductClick}
          saleChannel={saleChannel}
          isGroup={isGroup}
          onWeightClick={onVariableWeightClick}
          onValidateCart={onValidateCart}
        />
      ) : (
        <ProductItemCard
          product={product}
          quantity={quantity}
          onChangeCart={onChangeCart}
          onProductClick={onProductClick}
          saleChannel={saleChannel}
          isGroup={isGroup}
          onWeightClick={onVariableWeightClick}
          onValidateCart={onValidateCart}
        />
      )}

      {isProductModalOpen && productModalProduct && (
        <ProductModal
          product={productModalProduct}
          onClose={closeProductModal}
          open={isProductModalOpen}
          onChange={onChange}
          quantity={quantity}
          saleChannel={saleChannel}
          onWeightClick={onVariableWeightClick}
          handleNotifyStock={handleNotifyStock}
          onValidateCart={onValidateCart}
          store={store}
        />
      )}

      {isStockNotifyModalOpen && stockModal && (
        <EmailModal
          onClose={closeStockModal}
          open={isStockNotifyModalOpen}
          onSubmit={onSubmitStockAlert}
        />
      )}
    </>
  );
};

const Category = styled(Typography)`
  font-size: 16px;
  text-transform: uppercase;
`;

const CategoryContainer = styled("div")(({ theme }) => ({
  margin: "10px 0",
  background: theme.palette.grey[200],
  padding: "2px 8px",
  borderRadius: 8,
  display: "flex",
}));

const ProductView: React.FC<{
  products: any;
  currentView: any;
  onChangeCart: any;
  cart: any;
  saleChannel: CommonTypes.SaleChannel;
  isGroup: boolean;
  categories: { value: string; label: string }[];
  onVariableWeightClick: () => void;
  onSubmitNotifyStock: (value: Record<string, any>) => Promise<void>;
  onValidateCart: any;
  store: Record<string, any>;
}> = ({
  products,
  currentView,
  onChangeCart,
  cart,
  saleChannel,
  isGroup,
  categories,
  onVariableWeightClick,
  onSubmitNotifyStock,
  onValidateCart,
  store,
}) => {
  const categoryMap: { [key: string]: string } = useMemo(
    () =>
      categories.reduce(
        (acc, category) => ({ ...acc, [category.value]: category.label }),
        {}
      ),
    [categories]
  );

  return (
    <>
      {Object.keys(products).map((category: any) => (
        <Box
          key={category}
          id={`cat${category}`}
          sx={{
            marginBottom: 2,
            paddingTop: "40px",
            marginTop: "-40px",
          }}
        >
          <CategoryContainer>
            <Category>
              {categoryMap[category] ? categoryMap[category] : category} (
              {products[category].length})
            </Category>
          </CategoryContainer>

          <ProductContainer currentView={currentView}>
            {products[category].map((product: any) => {
              return (
                <ProductViewItem
                  key={product.id}
                  product={product}
                  currentView={currentView}
                  onChangeCart={onChangeCart}
                  cart={cart}
                  saleChannel={saleChannel}
                  isGroup={isGroup}
                  onVariableWeightClick={onVariableWeightClick}
                  onSubmitNotifyStock={onSubmitNotifyStock}
                  onValidateCart={onValidateCart}
                  store={store}
                />
              );
            })}
          </ProductContainer>
        </Box>
      ))}
    </>
  );
};

export default ProductView;
