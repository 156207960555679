import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
  BaseCheckbox,
  BaseSelect,
  ButtonOnlyIcon,
  FilterCheckbox,
  BaseSearchBar,
  useIsMobile,
  BaseLabel,
} from "@kuupanda/commons";
import { Box, FormControl, MenuItem, Tooltip } from "@mui/material";
import { RiImageLine, RiListCheck } from "react-icons/ri";
import { CommonTypes } from "@kuupanda/kuu-contract";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  position: "sticky",
  zIndex: 2,
  background: "#fff",
  padding: "16px 0",
  top: 0,
  [theme.breakpoints.down("md")]: {
    position: "relative",
    zIndex: "unset",
  },
}));

const ViewToggles = styled("div")`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
`;

const Toggles = styled("div")`
  display: flex;
  gap: 5px;
`;

const FilterCheckboxContainer = styled("div")`
  display: flex;
  gap: 8px;
`;

const Checkbox = styled(BaseCheckbox)({
  padding: "0 9px 0 0",
});

const ToggleButton = styled(ButtonOnlyIcon, {
  shouldForwardProp: (prop: any) => prop !== "selected",
})<{ selected: boolean }>(
  ({ theme, selected }) => `
  border-radius: 50%;
  background: ${
    selected ? theme.palette.secondary.main : theme.palette.grey[200]
  };
  svg {
    fill: ${selected ? "#FFFFFF" : theme.palette.others.a};
  }
  &:hover {
    background: ${
      selected ? theme.palette.secondary.dark : theme.palette.secondary.light
    };
  }
  height: 37px !important;
`
);

const Label = styled(BaseLabel)(
  ({ theme }) => `
  color: ${theme.palette.grey[700]};
`
);

const StyledSearchBar = styled(BaseSearchBar)`
  min-width: 200px;
`;

const Content = styled("div")({
  display: "flex",
  gap: 5,
});

const Placeholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.others.a,
}));

const SortBy = styled(BaseSelect)({
  width: 180,
});

const Categories = styled(BaseSelect)({
  width: 250,
});

const Producers = styled(BaseSelect)({
  width: 250,
});

const Filters: React.FC<{
  onChangeSearch: (val: any) => void;
  onClear: () => void;
  search: string;
  onChangeFilter: any;
  filters: any;
  categories: { value: string; label: string }[];
  currentView: string;
  changeView: (view: CommonTypes.ProductViewMode) => void;
  producers?: { value: string; label: string }[];
}> = ({
  onChangeSearch,
  onClear,
  search,
  onChangeFilter,
  filters,
  categories,
  currentView,
  changeView,
  producers,
}) => {
  const isMobile = useIsMobile();

  const isSelected = (value: any, option: any) => {
    if (!value) return false;

    value = value.toString();
    return value && value.includes(option);
  };

  const renderValue = (selected: any, options: any[], placeholder: string) => {
    if (!selected || (selected && selected.length === 0)) {
      return <Placeholder variant="caption">{placeholder}</Placeholder>;
    }

    return (
      selected &&
      selected
        .map((key: string) => options.find((c) => c.value === key)?.label)
        .join(", ")
    );
  };

  if (isMobile) {
    return (
      <Container>
        <Content>
          <FormControl fullWidth>
            <Label>Trier par</Label>
            <BaseSelect
              onChange={onChangeFilter}
              name="sortBy"
              value={filters.sortBy}
              fullWidth={isMobile}
            >
              <MenuItem value="default">Défaut</MenuItem>
              <MenuItem value="az">A-Z</MenuItem>
              <MenuItem value="za">Z-A</MenuItem>
              <MenuItem value="asc">Prix croissants</MenuItem>
              <MenuItem value="desc">Prix décroissants</MenuItem>
            </BaseSelect>
          </FormControl>

          <ViewToggles>
            <FormControl>
              <Label>Affichage</Label>
              <Toggles>
                <ToggleButton
                  selected={currentView === CommonTypes.ProductViewMode.LIST}
                  onClick={() => changeView(CommonTypes.ProductViewMode.LIST)}
                >
                  <RiListCheck />
                </ToggleButton>
                <ToggleButton
                  selected={currentView === CommonTypes.ProductViewMode.CARD}
                  onClick={() => changeView(CommonTypes.ProductViewMode.CARD)}
                >
                  <RiImageLine />
                </ToggleButton>
              </Toggles>
            </FormControl>
          </ViewToggles>
        </Content>

        {producers && producers.length > 1 && (
          <BaseSelect
            onChange={onChangeFilter}
            name="producer"
            value={filters.producer}
            fullWidth={isMobile}
            multiple={true}
            renderValue={(value: any) =>
              renderValue(value, producers, "Toutes les exploitations")
            }
          >
            {producers.map((producer) => (
              <MenuItem key={producer.value} value={producer.value}>
                <Checkbox
                  checked={isSelected(filters.producer, producer.value)}
                />
                {producer.label}
              </MenuItem>
            ))}
          </BaseSelect>
        )}

        <BaseSelect
          onChange={onChangeFilter}
          name="category"
          value={filters.category}
          fullWidth={isMobile}
          multiple={true}
          renderValue={(value: any) =>
            renderValue(value, categories, "Toutes les catégories")
          }
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              <Checkbox
                checked={isSelected(filters.category, category.value)}
              />
              {category.label}
            </MenuItem>
          ))}
        </BaseSelect>

        <Content sx={{ div: { flex: 1 } }}>
          <FilterCheckbox
            checked={filters.highlight}
            label={"Nouveauté"}
            onChange={onChangeFilter}
            name="highlight"
          />

          <FilterCheckbox
            checked={filters.promo}
            label={"Promo"}
            onChange={onChangeFilter}
            name="promo"
          />
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          {producers && producers.length > 1 && (
            <Producers
              onChange={onChangeFilter}
              name="producer"
              value={filters.producer}
              fullWidth={isMobile}
              multiple={true}
              renderValue={(value: any) =>
                renderValue(value, producers, "Toutes les exploitations")
              }
            >
              {producers.map((producer) => (
                <MenuItem key={producer.value} value={producer.value}>
                  <Checkbox
                    checked={isSelected(filters.producer, producer.value)}
                  />
                  {producer.label}
                </MenuItem>
              ))}
            </Producers>
          )}

          <Categories
            onChange={onChangeFilter}
            name="category"
            value={filters.category}
            fullWidth={true}
            multiple={true}
            renderValue={(value: any) =>
              renderValue(value, categories, "Toutes les catégories")
            }
          >
            {categories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                <Checkbox
                  checked={isSelected(filters.category, category.value)}
                />
                {category.label}
              </MenuItem>
            ))}
          </Categories>

          <FilterCheckboxContainer>
            <FilterCheckbox
              checked={filters.highlight}
              label={"Nouveauté"}
              onChange={onChangeFilter}
              name="highlight"
            />

            <FilterCheckbox
              checked={filters.promo}
              label={"Promo"}
              onChange={onChangeFilter}
              name="promo"
            />
          </FilterCheckboxContainer>
        </Box>

        <Box sx={{ alignItems: "flex-end", gap: 1, display: "flex" }}>
          <FormControl fullWidth>
            <Label>Trier par</Label>

            <SortBy
              onChange={onChangeFilter}
              name="sortBy"
              value={filters.sortBy}
            >
              <MenuItem value="default">Défaut</MenuItem>
              <MenuItem value="az">A-Z</MenuItem>
              <MenuItem value="za">Z-A</MenuItem>
              <MenuItem value="asc">Prix croissants</MenuItem>
              <MenuItem value="desc">Prix décroissants</MenuItem>
            </SortBy>
          </FormControl>
          <ViewToggles>
            <FormControl>
              <Label>Affichage</Label>

              <Toggles>
                <Tooltip title="Liste" aria-label="list">
                  <ToggleButton
                    selected={currentView === CommonTypes.ProductViewMode.LIST}
                    onClick={() => changeView(CommonTypes.ProductViewMode.LIST)}
                  >
                    <RiListCheck />
                  </ToggleButton>
                </Tooltip>

                <Tooltip title="Galerie" aria-label="card">
                  <ToggleButton
                    selected={currentView === CommonTypes.ProductViewMode.CARD}
                    onClick={() => changeView(CommonTypes.ProductViewMode.CARD)}
                  >
                    <RiImageLine />
                  </ToggleButton>
                </Tooltip>
              </Toggles>
            </FormControl>
          </ViewToggles>
        </Box>
      </Box>

      <StyledSearchBar
        onChange={onChangeSearch}
        onClear={onClear}
        value={search}
        fullWidth={isMobile}
        placeholder="Rechercher..."
      />
    </Container>
  );
};

export default Filters;
