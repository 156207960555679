import { getDistance } from "geolib";
import {
  getDay,
  getHours,
  getMinutes,
  isSameDay,
  parseISO,
  setHours,
  setMinutes,
} from "date-fns";
import constants from "../constants";
import { currencyRound, parseHourString } from "./misc";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { getProductsForProducer } from "./cart";

const vat = 0.2;

const days = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];
const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

export function getFormattedDate(date: Date) {
  if (!date) {
    return "";
  }

  return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]}`;
}

export function isValidDate(d: any) {
  return d instanceof Date && !Number.isNaN(d);
}

export const calculateAvailableRange = ({
  contactPointInfo,
  clientAddress,
}: {
  contactPointInfo: any;
  clientAddress: any;
}) => {
  const distance =
    getDistance(
      {
        latitude: contactPointInfo.address.lat,
        longitude: contactPointInfo.address.lng,
      },
      {
        latitude: clientAddress.lat,
        longitude: clientAddress.lng,
      }
    ) / 1000;

  return contactPointInfo.deliveryRates.find(
    (dr: any) => distance >= dr.from && distance < dr.to
  );
};

export const calculateNormalDeliveryRate = ({
  contactPointInfo,
  clientAddress,
  totalAmount,
}: {
  contactPointInfo: any;
  clientAddress: any;
  totalAmount: number;
}) => {
  let selectedRate;
  try {
    if (!clientAddress || !clientAddress.lat || !clientAddress.lng) {
      return undefined;
    }

    const selectedRange = calculateAvailableRange({
      contactPointInfo,
      clientAddress,
    });

    if (selectedRange) {
      const hasFreeDelivery =
        contactPointInfo.freeFeeOrderAmount &&
        totalAmount >= contactPointInfo.freeFeeOrderAmount;
      selectedRate = hasFreeDelivery ? 0 : selectedRange.price;
    }
  } catch (e) {
    console.log("error calculating distance", e);
  }
  // if no rate was found we return undefined
  return selectedRate;
};

export const calculateAgencyDeliveryRate = ({
  contactPointInfo,
  clientAddress,
  totalAmount,
  totalWeight,
}: {
  contactPointInfo: any;
  clientAddress: any;
  totalAmount: number;
  totalWeight: number;
}) => {
  let selectedRate;
  try {
    if (!clientAddress || !clientAddress.lat || !clientAddress.lng) {
      return undefined;
    }
    // to support LCS  agency prices delivery rates depending on the weight, we must check if the contact point has subventionRates
    // if they do, we use the find the weight range but ignore the zone!
    if (contactPointInfo.subventionRates?.length) {
      selectedRate =
        contactPointInfo.subventionRates.find(
          (sr: any) =>
            totalWeight >= sr.weightRange.start &&
            totalWeight < sr.weightRange.end &&
            sr.type === CommonTypes.ContactPointType.LCS_AGENCY
        ) || getMaxRateForZone(contactPointInfo.subventionRates, "ONE");
      if (selectedRate) {
        const hasFreeDelivery =
          contactPointInfo.freeFeeOrderAmount &&
          totalAmount >= contactPointInfo.freeFeeOrderAmount;
        selectedRate = hasFreeDelivery ? 0 : selectedRate.baseRate;
      }
    } else {
      const selectedRange = calculateAvailableRange({
        contactPointInfo,
        clientAddress,
      });

      if (selectedRange) {
        const hasFreeDelivery =
          contactPointInfo.freeFeeOrderAmount &&
          totalAmount >= contactPointInfo.freeFeeOrderAmount;
        selectedRate = hasFreeDelivery ? 0 : selectedRange.price;
      }
    }
  } catch (e) {
    console.log("error calculating distance", e);
  }
  // if no rate was found we return undefined

  if (!selectedRate) {
    return 0;
  }

  return addVAT(selectedRate);
};

export const calculateProductWeight = (product: Record<string, any>) => {
  const weight = product.weight || 1;
  return product.baseUnit === "g" || product.baseUnit === "ml"
    ? weight / 1000
    : weight;
};

export const calculateTotalWeight = ({ products }: any) => {
  if (!products) {
    return 0;
  }

  return products.reduce((total: number, product: any) => {
    const weight = calculateProductWeight(product);
    return total + weight * product.quantity;
  }, 0);
};

export const calculateShippingDeliveryRate = ({
  packages,
  products,
  contactPoint,
  deliveryType,
  totalAmount,
}: {
  packages: any[];
  products: any[];
  contactPoint: any;
  deliveryType: string;
  totalAmount: number;
}) => {
  if (
    contactPoint.minimumDeliveryAmount &&
    contactPoint.minimumDeliveryAmount > totalAmount
  ) {
    return 0;
  }

  if (
    contactPoint.freeFeeOrderAmount &&
    totalAmount >= contactPoint.freeFeeOrderAmount
  ) {
    return 0;
  }

  const packagesWeight = packages.reduce(
    (acc: number, { weight }: { weight: number }) => acc + weight,
    0
  );

  const splitWeightArray = splitPackagesToWeight(packagesWeight, contactPoint);

  return splitWeightArray.reduce(
    (acc: number, { weight }: { weight: number }) => {
      return (
        acc +
        getCPRateByWeight({
          weight,
          products: products,
          contactPoint: contactPoint,
          rateType: deliveryType,
        })
      );
    },
    0
  );
};

export const calculateAsssocDeliveryRate = ({
  contactPoint,
  totalAmount,
  totalWeight,
  clientAddress,
}: {
  contactPoint: any;
  totalAmount: number;
  totalWeight: number;
  clientAddress: any;
}) => {
  if (
    contactPoint.minimumDeliveryAmount &&
    contactPoint.minimumDeliveryAmount > totalAmount
  ) {
    return 0;
  }

  if (
    contactPoint.freeFeeOrderAmount &&
    totalAmount >= contactPoint.freeFeeOrderAmount
  ) {
    return 0;
  }

  const zone = calculateZone(clientAddress.postal_code);
  if (!zone) {
    return undefined;
  }

  const rate =
    contactPoint.subventionRates.find(
      (sr: any) =>
        totalWeight >= sr.weightRange.start &&
        totalWeight < sr.weightRange.end &&
        sr.type === CommonTypes.ContactPointType.LCS_ASSOC &&
        sr.zone === zone
    ) || getMaxRateForZone(contactPoint.subventionRates, zone);

  if (!rate) return 0;

  return addVAT(rate.baseRate - rate.subventionRate);
};

export const calculateDeliveryRate = ({
  cartProducts,
  contactPointInfo,
  cartSubTotal,
  clientAddress,
}: {
  cartProducts: any;
  contactPointInfo: any;
  cartSubTotal: number;
  clientAddress: any;
}) => {
  if (!isCpDelivery(contactPointInfo.type)) {
    return null;
  }
  const deliveryType = calculateServiceType({
    products: cartProducts,
    contactPoint: contactPointInfo,
  });

  const totalWeight = calculateTotalWeight({ products: cartProducts });

  const packages = splitPackagesToWeight(totalWeight, contactPointInfo);

  switch (contactPointInfo.type) {
    case CommonTypes.ContactPointType.LCS_ASSOC:
      return calculateAsssocDeliveryRate({
        contactPoint: contactPointInfo,
        totalAmount: cartSubTotal,
        totalWeight,
        clientAddress,
      });

    case CommonTypes.ContactPointType.LCS_AGENCY:
      return calculateAgencyDeliveryRate({
        contactPointInfo,
        clientAddress,
        totalAmount: cartSubTotal,
        totalWeight,
      });

    case CommonTypes.ContactPointType.DELIVERY:
      return calculateNormalDeliveryRate({
        contactPointInfo,
        clientAddress,
        totalAmount: cartSubTotal,
      });

    default:
      return calculateShippingDeliveryRate({
        packages,
        products: cartProducts,
        contactPoint: contactPointInfo,
        deliveryType,
        totalAmount: cartSubTotal,
      });
  }
};

export const splitPackagesToWeight = (
  totalWeight: number,
  contactPoint: any
) => {
  const maxWeight =
    contactPoint.type === constants.SHIPPING_MAX_WEIGHTS[contactPoint.type]
      ? constants.SHIPPING_MAX_WEIGHTS[contactPoint.type]
      : constants.SHIPPING_MAX_WEIGHTS.CHRONOPOST;

  let splitWeightArray = [];
  let rest = totalWeight % maxWeight;
  let division = Math.floor(totalWeight / maxWeight);

  for (let i = 0; i < division; i++) {
    splitWeightArray.push({ weight: maxWeight });
  }

  if (rest !== 0) {
    splitWeightArray.push({ weight: rest });
  }

  return splitWeightArray;
};

const getSearchRate = (contactPoint: any, rateType: any) => {
  if (
    [CommonTypes.ChronopostModes.DRY, CommonTypes.ChronopostModes.DRY].includes(
      rateType
    )
  ) {
    if (contactPoint.modes.includes(rateType)) {
      return rateType;
    }

    return rateType === CommonTypes.ChronopostModes.DRY
      ? CommonTypes.ChronopostModes.FRESH
      : CommonTypes.ChronopostModes.DRY;
  }

  return rateType;
};

export const getCPRateByWeight = ({
  weight,
  products,
  contactPoint,
  rateType,
}: any) => {
  if (!products || !contactPoint || !weight) {
    return 0;
  }

  const searchRate = getSearchRate(contactPoint, rateType);

  const rate =
    contactPoint.subventionRates.find(
      (sr: any) =>
        weight >= sr.weightRange.start &&
        weight < sr.weightRange.end &&
        sr.type === searchRate
    ) || getMaxRate(contactPoint.subventionRates);
  // if no rate was found we return 0
  if (!rate) return 0;

  return addVAT(rate.baseRate - rate.subventionRate);
};

export const calculateServiceType = ({ products, contactPoint }: any) => {
  if (contactPoint.type === CommonTypes.ContactPointType.UPS) {
    return CommonTypes.UPSShippingProduct.UPS_STANDARD;
  }

  if (contactPoint.type === CommonTypes.ContactPointType.MONDIAL_RELAY) {
    return CommonTypes.MondialRelayShippingProduct.RELAY_L;
  }

  if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_RELAY) {
    return constants.CHRONOPOST_TYPES.CHRONOPOST_RELAY;
  }

  if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_VITI) {
    return constants.CHRONOPOST_TYPES.CHRONOPOST_VITI;
  }

  if (contactPoint.type === constants.CHRONOPOST_TYPES.CHRONOPOST_SHOP2SHOP) {
    return constants.CHRONOPOST_TYPES.CHRONOPOST_SHOP2SHOP;
  }

  if (!products) {
    return CommonTypes.ChronopostModes.DRY;
  }

  const hasColdProducts = products.some(
    (product: any) => product.storage === constants.STORAGE_TYPE.COLD
  );

  return hasColdProducts
    ? CommonTypes.ChronopostModes.FRESH
    : CommonTypes.ChronopostModes.DRY;
};

const reverseMapping = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const getHourRanges = ({
  date,
  contactPoint,
}: {
  date: Date;
  contactPoint: any;
}) => {
  if (contactPoint.customSchedule && contactPoint.customSchedule.length > 0) {
    const found = contactPoint.customSchedule.find((cs: any) =>
      isSameDay(parseISO(cs.date), date)
    );

    if (found) {
      return found.values && found.values.length > 0 ? found.values : [];
    }
  }

  const weekDayName = reverseMapping[getDay(date)];

  return contactPoint.schedule[weekDayName].values;
};

export const getSlotsForDate = ({
  date,
  contactPoint,
}: {
  date: Date;
  contactPoint: any;
}) => {
  if (!date) {
    return [];
  }
  const hourRanges = getHourRanges({ date, contactPoint });

  const validHours = [];
  for (let idx = 0; idx < hourRanges.length; idx += 1) {
    const [startHour, startMinute] = parseHourString(hourRanges[idx].start);
    const [endHour, endMinute] = parseHourString(hourRanges[idx].end);

    const value = setHours(setMinutes(new Date(), startMinute), startHour);
    const label = `${startHour}h${pad(startMinute)} - ${endHour}h${pad(
      endMinute
    )}`;

    validHours.push({
      label,
      value,
    });
  }

  return validHours;
};

export const resetHour = ({
  date,
  contactPoint,
}: {
  date: Date;
  contactPoint: any;
}) => {
  if (!date) {
    return null;
  }

  const hourRanges = getHourRanges({ date, contactPoint });

  const availableSlots = hourRanges.map((it: any) => {
    const [hour, minute] = parseHourString(it.start);

    return setHours(setMinutes(new Date(), minute), hour);
  });

  const availableHours = hourRanges.map((it: any) => {
    return parseHourString(it.start)[0];
  });

  const firstAvailableHour = availableSlots[0];

  if (!firstAvailableHour) {
    return null;
  }

  return availableHours.includes(getHours(date))
    ? date
    : setHours(
        setMinutes(date, getMinutes(firstAvailableHour)),
        getHours(firstAvailableHour)
      );
};

export const isCpDelivery = (type: CommonTypes.ContactPointType) => {
  return [
    CommonTypes.ContactPointType.DELIVERY,
    CommonTypes.ContactPointType.CHRONOPOST,
    CommonTypes.ContactPointType.CHRONOPOST_RELAY,
    CommonTypes.ContactPointType.CHRONOPOST_VITI,
    CommonTypes.ContactPointType.LCS_AGENCY,
    CommonTypes.ContactPointType.LCS_ASSOC,
    CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP,
    CommonTypes.ContactPointType.UPS,
    CommonTypes.ContactPointType.MONDIAL_RELAY,
  ].includes(type);
};

export const isCpChronopost = (type: CommonTypes.ContactPointType) => {
  return [
    CommonTypes.ContactPointType.CHRONOPOST,
    CommonTypes.ContactPointType.CHRONOPOST_RELAY,
    CommonTypes.ContactPointType.CHRONOPOST_VITI,
    CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP,
  ].includes(type);
};

export const isCPChronoPostRelay = (type: CommonTypes.ContactPointType) => {
  return [
    CommonTypes.ContactPointType.CHRONOPOST_RELAY,
    CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP,
  ].includes(type);
};

export const isCpRelay = (type: CommonTypes.ContactPointType) => {
  return [
    CommonTypes.ContactPointType.CHRONOPOST_RELAY,
    CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP,
    CommonTypes.ContactPointType.MONDIAL_RELAY,
  ].includes(type);
};

export const isCPChronoPostViti = (type: CommonTypes.ContactPointType) => {
  return type === CommonTypes.ContactPointType.CHRONOPOST_VITI;
};

export const isCpShop2Shop = (type: CommonTypes.ContactPointType) => {
  return type === CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP;
};

export const isCPTakeaway = (type: CommonTypes.ContactPointType) => {
  return type === CommonTypes.ContactPointType.TAKE_AWAY;
};

export const isCpCarrier = (type: CommonTypes.ContactPointType) => {
  return [
    CommonTypes.ContactPointType.LCS_AGENCY,
    CommonTypes.ContactPointType.LCS_ASSOC,
  ].includes(type);
};

export const isCpExternalShipping = (type: CommonTypes.ContactPointType) => {
  if (
    type === CommonTypes.ContactPointType.UPS ||
    type === CommonTypes.ContactPointType.MONDIAL_RELAY
  ) {
    return true;
  }

  return isCpCarrier(type) || isCpChronopost(type);
};

export const isCpAgency = (type: CommonTypes.ContactPointType) =>
  CommonTypes.ContactPointType.LCS_AGENCY === type;

export const isCpMondialRelay = (type: CommonTypes.ContactPointType) =>
  CommonTypes.ContactPointType.MONDIAL_RELAY === type;

export const isCpUps = (type: CommonTypes.ContactPointType) =>
  CommonTypes.ContactPointType.UPS === type;

export function pad(n: number) {
  return n < 10 ? "0" + n : n;
}

export const calculateZone = (postcode: string) => {
  const zone2 = ["2A", "2B", "20"];
  const zone3 = ["97", "98"];

  if (!postcode) return null;
  const prefix = postcode.slice(0, 2);

  if (zone2.includes(prefix)) {
    return "TWO";
  }

  if (zone3.includes(prefix)) {
    return "THREE";
  }

  return "ONE";
};

const getMaxRate = (rates: any[]) =>
  rates.reduce((prev, current) =>
    prev.weightRange.end > current.weightRange.end ? prev : current
  );

const getMaxRateForZone = (rates: any[], zone: string) => {
  const ratesForZone = rates.filter((rate: any) => rate.zone === zone);

  return ratesForZone.reduce((prev, current) =>
    prev.weightRange.end > current.weightRange.end ? prev : current
  );
};

const addVAT = (rate: number): number => {
  const subventionRateWithVAT = rate * (1 + vat);

  return currencyRound(subventionRateWithVAT);
};

export const calculateProviderFromType = (
  type: CommonTypes.ContactPointType
) => {
  if (type === CommonTypes.ContactPointType.MONDIAL_RELAY) {
    return CommonTypes.ShipmentProvider.MONDIAL_RELAY;
  }

  return CommonTypes.ShipmentProvider.CHRONOPOST;
};

export const checkIfWeightExceeded = (
  cart: Record<string, any>,
  producerId: string,
  contactPoint: any
) => {
  const cartProducts = getProductsForProducer(cart, producerId, true);
  const totalWeight = calculateTotalWeight({ products: cartProducts });

  if (contactPoint && isCPChronoPostRelay(contactPoint.type)) {
    return totalWeight > 20;
  }
};

export const getPrepTime = (
  type: CommonTypes.ContactPointType,
  frequency: string
) => {
  if (!frequency || isCpAgency(type)) {
    return 0;
  }

  let timeNeeded: number;
  switch (frequency) {
    case constants.DELIVERY_FREQUENCY.EVERYDAY:
      timeNeeded = 3;
      break;
    case constants.DELIVERY_FREQUENCY.EVERY_TWO_DAYS:
      timeNeeded = 4;
      break;
    case constants.DELIVERY_FREQUENCY.TWICE_A_WEEK:
      timeNeeded = 5;
      break;
    case constants.DELIVERY_FREQUENCY.ONCE_A_WEEK:
      timeNeeded = 7;
      break;
    default:
      timeNeeded = 1;
      break;
  }

  return timeNeeded;
};
