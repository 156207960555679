import React from "react";
import {
  Grid,
  Typography,
  TypographyVariant,
  Box,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Components
import constants from "../constants";
import { NumberFormatCustom, useIsMobile } from "@kuupanda/commons";
import PromoCodeInput from "./PromoCodeInput";
import { getBaseUnit } from "../helpers/misc";
import QuantitySelector from "./QuantitySelector";
import { RiDeleteBin4Line } from "react-icons/ri";
import { getProductName } from "../helpers/product";

const Container = styled(Grid)`
  min-height: 40px;
`;

const DividerStyled = styled("div")`
  border-top: 1px solid #dfe2f2;
  margin: 10px 0;
`;

const Total = styled(Typography)`
  font-weight: bold;
`;

export const ProductBasePrice = styled("div")(
  ({ theme }) => `
  text-decoration: line-through;
  font-size: 13px;
  color: ${theme.palette.grey.A200};
`
);

const Price = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SmallQuantitySelector = styled(QuantitySelector)(({ theme }) => ({
  input: { width: "100%" },
  [theme.breakpoints.down("sm")]: {
    input: { width: 65 },
  },
}));

type CartSummaryProps = {
  dividerColor?: string;
  showCoupon?: boolean;
  total: number;
  totalVariant?: TypographyVariant;
  showDisabledCoupon?: boolean;
  applyPromo?: any;
  fees?: any;
  showTotal?: boolean;
  couponLines?: any;
  clearPromo?: any;
  invalidDiscounts: any[];
  codeEvaluationStatus?: any;
  initialCodes?: string[];
  applyCoupons?: any;
  promoEnabled?: boolean;
  onChangeCart?: any;
  cart: any;
  cartTotal: number;
  onValidateCart: any;
};

const getChildProductNames = (product: any, childProducts: string[]) => {
  return product.childProducts
    .filter((cp: any) => childProducts.includes(cp.id))
    .map((cp: any) => `${getProductName(cp)} x ${cp.quantity}`)
    .join(", ");
};

const CartSummary = ({
  total,
  dividerColor,
  totalVariant = "subtitle2",
  promoEnabled,
  fees,
  showTotal,
  couponLines,
  codeEvaluationStatus,
  initialCodes,
  applyCoupons,
  cart,
  onChangeCart,
  invalidDiscounts,
  cartTotal,
  onValidateCart,
}: CartSummaryProps) => {
  const isMobile = useIsMobile();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {Object.values(cart).map(
        ({ quantity, product, size, childProducts }: any, index: number) => (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography component="div">
                  <Box fontWeight={700} fontSize={14}>
                    {product.name}
                    {size && ` - (Taille ${size})`}
                    {childProducts &&
                      childProducts.length > 0 &&
                      ` (${getChildProductNames(product, childProducts)})`}
                  </Box>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <IconButton
                  size={"small"}
                  onClick={() => onChangeCart({ product, quantity: 0, size })}
                >
                  <RiDeleteBin4Line />
                </IconButton>

                <SmallQuantitySelector
                  value={quantity}
                  onChange={(value: any) =>
                    onChangeCart({ product, quantity: value, size })
                  }
                  decimalScale={product.baseUnit === "unit" ? 0 : 2}
                  onValidate={(value: any) =>
                    onValidateCart({ product, quantity: value, size })
                  }
                />
              </Box>

              {isMobile && (
                <Price>
                  <Typography component="div">
                    <Box fontWeight={500} fontSize={14}>
                      <NumberFormatCustom
                        value={product.price}
                        displayType={"text"}
                      />
                    </Box>
                  </Typography>
                  <Typography color={"textSecondary"} component="div">
                    <Box fontWeight={400} fontSize={12}>
                      / {getBaseUnit(product)}
                    </Box>
                  </Typography>
                </Price>
              )}

              {!isMobile && (
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    {promoEnabled && (
                      <Typography color="textSecondary" component="div">
                        <Box fontWeight={500} fontSize={12}>
                          <NumberFormatCustom
                            value={product.price}
                            displayType={"text"}
                          />
                          / {getBaseUnit(product)}
                        </Box>

                        {product.priceReduction > 0 && (
                          <ProductBasePrice>
                            <NumberFormatCustom
                              value={product.priceBeforeReduction}
                              displayType={"text"}
                            />
                          </ProductBasePrice>
                        )}
                      </Typography>
                    )}

                    <Typography component="div">
                      <Box fontWeight={700} fontSize={14}>
                        <NumberFormatCustom
                          value={quantity * product.price}
                          displayType={"text"}
                        />
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )
      )}

      {promoEnabled && (
        <>
          <DividerStyled color={dividerColor} />
          <PromoCodeInput
            initialCodes={initialCodes}
            onSubmit={applyCoupons}
            codeEvaluationStatus={codeEvaluationStatus}
            cartTotal={cartTotal}
            invalidDiscounts={invalidDiscounts}
          />
        </>
      )}

      {fees && fees.length > 0 && (
        <>
          <DividerStyled color={dividerColor} />
          <Container
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            {fees.map((fee: { rate: number; type: string }) => (
              <>
                <Total variant={totalVariant}>
                  {fee.type === constants.FEES_TYPE.DELIVERY && "Livraison"}
                </Total>
                <Total variant={totalVariant}>
                  <NumberFormatCustom value={fee.rate} displayType={"text"} />
                </Total>
              </>
            ))}
          </Container>
        </>
      )}

      {couponLines && couponLines.length > 0 && (
        <>
          <DividerStyled color={dividerColor} />
          {couponLines.map((coupon: { discount: number }) => (
            <Container
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center"
            >
              <Total variant="caption">Remise</Total>
              <Total variant="caption">
                -
                <NumberFormatCustom
                  value={coupon.discount}
                  displayType={"text"}
                />
              </Total>
            </Container>
          ))}
        </>
      )}

      {showTotal && total >= 0 && (
        <>
          <DividerStyled color={dividerColor} />
          <Container
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Total variant={totalVariant}>Total (TTC)</Total>
            <Total variant={totalVariant}>
              <NumberFormatCustom value={total} displayType={"text"} />
            </Total>
          </Container>
        </>
      )}
    </Box>
  );
};

export default CartSummary;
