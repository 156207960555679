import { useParams } from "react-router-dom";
import { reCaptchaWrapper } from "../../helpers/reCaptcha";
import { useHttpClient } from "../../hooks/useHttpClient";
import { useApiConfig } from "../../context/ApiContext";
import { useCallback, useEffect, useState } from "react";
import { PageLoader } from "@kuupanda/commons";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const Container = styled("div")`
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: fixed;
`;

const OrderPayment = () => {
  const { orderId } = useParams<{
    orderId: string;
  }>() as {
    orderId: string;
  };

  const { apiRoot }: any = useApiConfig();

  const httpClient = useHttpClient(apiRoot);

  const [error, setError] = useState(false);

  const getPaymentRedirectUrl = useCallback(async () => {
    await reCaptchaWrapper(async function (recaptchaToken: string) {
      if (!orderId) {
        setError(true);
        return;
      }

      const url = `${window.location.origin}${window.location.pathname}`;

      const payInCreationDTO = {
        returnURL: `${url}/complete`,
        locale: "fr",
      };

      try {
        const res = await httpClient.post(
          `/payment/mangopay/get-payment-url/${orderId}`,
          {
            ...payInCreationDTO,
            recaptchaToken,
          }
        );

        if (res && res.redirectURL) {
          window.location = res.redirectURL;
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
        console.log(e);
      }
    });
  }, [httpClient, orderId]);

  useEffect(() => {
    getPaymentRedirectUrl();
  }, [getPaymentRedirectUrl]);

  if (error) {
    return (
      <Container>
        <Typography component="div" variant="subtitle1" color="textSecondary">
          <Box fontWeight={400} fontSize={20} textAlign="center">
            Échec du paiement, veuillez réessayer
          </Box>
        </Typography>
      </Container>
    );
  }

  return <PageLoader />;
};

export default OrderPayment;
