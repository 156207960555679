import React, { useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import MediaSlider from "./MediaSlider";
import { NumberFormatCustom, Button, Tag, BaseSelect } from "@kuupanda/commons";
import QuantitySelector from "./QuantitySelector";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { RiRefreshLine } from "react-icons/ri";
import SizeBox from "./SizeBox";
import { productTagsMapping } from "../helpers/productLabels";
import {
  getProductName,
  getUnitName,
  getMaxAvailableUnits,
  getMinAvailableUnits,
  isProductAvailable,
  isComboSingle,
  isComboMultiple,
  isComboDefault,
  isValidCombo,
} from "../helpers/product";
import VariableWeight from "./VariableWeight/VariableWeight";
import constants from "../constants";
import Tooltip from "./Tooltip";
import { parseISO, format } from "date-fns";
import { ComboSelection } from "./ComboSelection";

const StyledDialog = styled(Dialog)`
  > div > div {
    max-width: 800px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #1a192e;
  padding: 2px;
  right: 15px;
  position: absolute;
  top: 15px;
`;

const mediaHeight = 300;
const MediaSliderContainer = styled("div")`
  width: 100%;
  height: ${mediaHeight}px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background: white;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  line-height: 127.5%;
`;

const Description = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.others.a};
  max-height: 200px;
  overflow-y: scroll;
`
);

const Content = styled("div")`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NoStock = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.error.main};
  line-height: 1;
  margin-top: 10px;
`
);

const PriceContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PriceBeforeDiscount = styled(Typography)(
  ({ theme }) => `
  text-decoration: line-through;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${theme.palette.grey[800]};
  line-height: 140%;
`
);

const Price = styled(Typography)(
  ({ theme }) => `
  font-weight: 500;
  font-size: 16px;
  color: ${theme.palette.others.a};
  line-height: 140%;
`
);

const PriceAfterDiscount = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${theme.palette.primary.dark};
`
);

const ProductTagContainer = styled("div")`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const ProductTag = styled("span")(
  ({ theme }) => `
  background: ${theme.palette.grey[200]};
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding: 4px 6px;
  color: ${theme.palette.others.a};
`
);

const Quantity = styled("div")`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

const Spacer = styled("div")`
  height: 24px;
`;

const VariableWeightModal = styled(VariableWeight)({
  width: "fit-content",
});

const getComboProducts = (
  product: Record<string, any>,
  comboOptions: string | string[]
) => {
  if (isValidCombo(product)) {
    if (isComboDefault(product)) {
      return [];
    }

    return Array.isArray(comboOptions) ? comboOptions : [comboOptions];
  }

  return [];
};

const getQuantityLabel = (baseUnit: string, availableQuantity: number) => {
  const multiple = availableQuantity > 1;
  const defaultUnit = multiple ? "unités" : "unité";
  const unit = baseUnit === "unit" ? defaultUnit : baseUnit;
  return `${availableQuantity} ${unit} ${
    multiple ? "disponibles" : "disponible"
  }`;
};

export const ProductModal: React.FC<{
  open: boolean;
  onClose: any;
  product: any;
  quantity: string;
  onChange: any;
  saleChannel: string;
  onWeightClick: any;
  handleNotifyStock: (product: any) => void;
  onValidateCart: any;
  store: Record<string, any>;
}> = ({
  open,
  onClose,
  product,
  quantity,
  onChange,
  saleChannel,
  onWeightClick,
  handleNotifyStock,
  onValidateCart,
  store,
}) => {
  const {
    description,
    price,
    priceReduction,
    priceBeforeReduction,
    tags,
    pictures,
    childProducts,
    baseUnit,
    sizes,
    sellByVariableWeight,
    averageWeight,
    variableUnit,
    maxSaleQuantity,
    variableWeightPrice,
    priceByUnit,
    stock,
    availableEndDate,
    minSaleQuantity,
    productComboType,
    manageComboStock,
  } = product;
  const isMultiple = isComboMultiple(product);

  const [size, setSize] = useState(sizes && sizes.length > 0 ? sizes[0] : "");

  const [comboOptions, setComboOptions] = useState<string[] | string>(
    isMultiple ? [] : ""
  );

  const isProfessional = saleChannel === CommonTypes.SaleChannel.PROFESSIONAL;

  const availableDate = useMemo(() => {
    if (
      product.preparationType === constants.PREPARATION_TYPE.SPECIFIC_DATE &&
      product.availableDate
    ) {
      const availableDateTime = new Date(product.availableDate).getTime();

      if (Date.now() <= availableDateTime) {
        return product.availableDate;
      }
    }
  }, [product.availableDate, product.preparationType]);

  const isPastAvailableEnd = availableEndDate
    ? Date.now() >= new Date(availableEndDate).getTime()
    : false;

  const isAvailable = isProductAvailable(product);

  const [selectedQuantity, setSelectedQuantity] = useState(quantity);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const onValidate = (quantity: number) => {
    return onValidateCart({
      product,
      quantity: quantity,
      childProducts: getComboProducts(product, comboOptions),
    });
  };

  const handleChange = (value: string) => {
    setSelectedQuantity(value);
  };

  const handleAddToCart = () => {
    const comboProducts = getComboProducts(product, comboOptions);
    onChange(selectedQuantity, size, comboProducts);
    onClose();
  };

  const onChangeSelection = (value: string) => {
    setComboOptions(value);
  };

  const needsComboSelection = useMemo(
    () =>
      productComboType &&
      productComboType !== CommonTypes.ProductComboType.DEFAULT &&
      comboOptions.length === 0,
    [productComboType, comboOptions]
  );

  const childProductsOutOfStock = useMemo(
    () => childProducts.filter((it: any) => it.availableQuantity === 0),
    [childProducts]
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      fullScreen={fullScreen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        id: "kuu-product-modal",
      }}
      maxWidth={"md"}
      fullWidth
    >
      <DialogContent>
        <MediaSliderContainer>
          <MediaSlider pictures={pictures} height={mediaHeight} allowZoom />
        </MediaSliderContainer>

        {onClose ? (
          <StyledIconButton aria-label="close" onClick={onClose}>
            <CloseIcon color="inherit" />
          </StyledIconButton>
        ) : null}

        <Content>
          <Title variant={"h5"}>{getProductName(product)}</Title>
          {sellByVariableWeight && (
            <VariableWeightModal onClick={onWeightClick} />
          )}

          {sizes && sizes.length > 0 && <SizeBox sizes={sizes} />}

          {availableDate && (
            <Tag variant="default" startIcon={<RiRefreshLine />}>
              <Typography color="inherit">
                Disponible à partir du{" "}
                {format(parseISO(availableDate), "dd/MM")}
              </Typography>
            </Tag>
          )}

          {availableEndDate && (
            <Tag variant="default" startIcon={<RiRefreshLine />}>
              <Typography color="inherit">
                Disponible jusqu’au{" "}
                {format(parseISO(availableEndDate), "dd/MM")}
              </Typography>
            </Tag>
          )}

          {isComboSingle(product) && (
            <Tag variant="default" startIcon={<RiRefreshLine />}>
              <Typography color="inherit">Produit avec variation</Typography>
            </Tag>
          )}

          {isComboMultiple(product) && (
            <Tag variant="default" startIcon={<RiRefreshLine />}>
              <Typography color="inherit">Panier avec options</Typography>
            </Tag>
          )}

          <PriceContainer>
            {priceReduction > 0 ? (
              <PriceBeforeDiscount>
                <NumberFormatCustom
                  value={priceBeforeReduction}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " € TTC"}
                />
              </PriceBeforeDiscount>
            ) : (
              <Price>
                <NumberFormatCustom
                  value={price}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " € TTC"}
                />
              </Price>
            )}

            {priceReduction > 0 && (
              <PriceAfterDiscount>
                <NumberFormatCustom
                  value={price}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " € TTC"}
                />
              </PriceAfterDiscount>
            )}

            <Typography color="textSecondary" variant="overline">
              / {baseUnit === "unit" ? "unité" : baseUnit}
            </Typography>
          </PriceContainer>

          <PriceContainer>
            {baseUnit === "unit" &&
              averageWeight &&
              variableUnit &&
              variableWeightPrice && (
                <>
                  <Price>
                    <NumberFormatCustom
                      value={priceByUnit}
                      displayType="text"
                      suffix={isProfessional ? " € HT" : " € TTC"}
                    />
                  </Price>
                  <Typography color="textSecondary" variant="overline">
                    / {getUnitName(product)}
                  </Typography>
                </>
              )}
          </PriceContainer>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {!isAvailable && (
              <>
                {isValidCombo(product) && manageComboStock ? (
                  <NoStock noWrap>
                    Rupture de stock sur{" "}
                    {childProductsOutOfStock.length === 1
                      ? "le produit"
                      : "les produits"}{" "}
                    {childProductsOutOfStock
                      .map(
                        (it: any) => `${getProductName(it)} x ${it.quantity}`
                      )
                      .join(", ")}
                  </NoStock>
                ) : (
                  <>
                    <NoStock noWrap>Rupture de stock</NoStock>
                    {!isValidCombo(product) && (
                      <Typography
                        color={"primary"}
                        onClick={() => handleNotifyStock({ product })}
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                      >
                        Me prévenir quand le produit sera à nouveau disponible
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}

            {!store.hideStockOnForm &&
              isAvailable &&
              stock !== null &&
              stock !== undefined && (
                <Typography color="textSecondary" variant={"caption"}>
                  {getQuantityLabel(baseUnit, stock)}
                </Typography>
              )}
          </Box>

          {Boolean(minSaleQuantity && minSaleQuantity > 0) && (
            <Typography color="textSecondary" variant={"caption"}>
              Quantité minimale par commande : {minSaleQuantity}{" "}
              {getMinAvailableUnits(product)}
            </Typography>
          )}

          {Boolean(maxSaleQuantity && maxSaleQuantity > 0) && (
            <Typography color="textSecondary" variant={"caption"}>
              Quantité maximale par commande : {maxSaleQuantity}{" "}
              {getMaxAvailableUnits(product)}
            </Typography>
          )}

          <Description>
            <div dangerouslySetInnerHTML={{ __html: description }} />
            {isComboDefault(product) && (
              <Box
                component={"ul"}
                sx={{ listStylePosition: "inside", paddingLeft: 1 }}
              >
                {childProducts.length > 0 &&
                  childProducts.map((it: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        component={"li"}
                        sx={{ margin: "0 0 4px 0" }}
                      >
                        {getProductName(it)} x {it.quantity}
                      </Box>
                    );
                  })}
              </Box>
            )}
          </Description>

          <ProductTagContainer>
            {tags.map((tag: any, index: number) => (
              <ProductTag key={index}>{productTagsMapping[tag]}</ProductTag>
            ))}
          </ProductTagContainer>

          {(isComboMultiple(product) || isComboSingle(product)) && (
            <>
              <Typography fontWeight={"bold"}>
                {productComboType === CommonTypes.ProductComboType.MULTIPLE
                  ? "Choisissez vos produits depuis la liste ci-dessous"
                  : "Choisissez votre produit depuis la liste ci-dessous"}
                {" :"}

                <Tooltip
                  title={
                    productComboType === CommonTypes.ProductComboType.MULTIPLE
                      ? "Sélectionnez les produits (plusieurs choix possible) à ajouter au panier. En cas d’indisponibilité d’un produit, le producteur prendra contact avec vous à la réception de la commande"
                      : "Sélectionnez la variation du produit que vous souhaitez ajouter au panier (un seul choix possible)"
                  }
                />
              </Typography>
            </>
          )}

          {(isComboMultiple(product) || isComboSingle(product)) && (
            <ComboSelection
              product={product}
              value={comboOptions}
              onChange={onChangeSelection}
              disabled={!isAvailable}
            />
          )}

          <Spacer />

          <Quantity>
            {sizes && sizes.length > 0 && (
              <BaseSelect
                onChange={(event: any) => setSize(event.target.value)}
                name="size"
                value={size}
                options={sizes.map((option: string) => ({
                  value: option,
                  label: option,
                }))}
              />
            )}

            <QuantitySelector
              value={selectedQuantity}
              onChange={handleChange}
              disabled={
                !isAvailable || isPastAvailableEnd || needsComboSelection
              }
              decimalScale={baseUnit === "unit" ? 0 : 2}
              onValidate={onValidate}
            />
            <Button
              color="primary"
              onClick={handleAddToCart}
              disabled={
                !isAvailable ||
                parseFloat(selectedQuantity) === 0 ||
                needsComboSelection
              }
            >
              Ajouter au panier
            </Button>
          </Quantity>
        </Content>
      </DialogContent>
    </StyledDialog>
  );
};
