import localStorageManager, { LOCAL_STORAGE_KEYS } from "./localStorageManager";

export const storeClientAddressInLocalStorage = (
  clientAddress: any,
  vendorId: string
) => {
  const current =
    localStorageManager.getItem(LOCAL_STORAGE_KEYS.CLIENT_ADDRESS) || "{}";
  const vendorIdAddressMap = JSON.parse(current) || {};
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CLIENT_ADDRESS,
    JSON.stringify({ ...vendorIdAddressMap, [vendorId]: clientAddress })
  );
};

export const storeContactPointInLocalStorage = (
  contactPointId: string,
  vendorId: string
) => {
  const vendorIdContactPointIdMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CONTACT_POINT) || "{}"
    ) || {};
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CONTACT_POINT,
    JSON.stringify({ ...vendorIdContactPointIdMap, [vendorId]: contactPointId })
  );
};

export const getClientAddressFromLocalStorage = (vendorId: string) => {
  const vendorIdAddressMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CLIENT_ADDRESS) || "{}"
    ) || {};

  return vendorIdAddressMap[vendorId];
};

export const getAllContactPointFromLocalStorage = () =>
  JSON.parse(
    localStorageManager.getItem(LOCAL_STORAGE_KEYS.CONTACT_POINT) || "{}"
  ) || {};

export const getCustomerInfoFromLocalStorage = (vendorId: string) => {
  const vendorIdAddressMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO) || "{}"
    ) || {};

  return vendorIdAddressMap[vendorId];
};

export const storeCustomerInfoInLocalStorage = (
  customerInfo: any,
  vendorId: string
) => {
  const vendorIdAddressMap =
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CUSTOMER_INFO) || "{}"
    ) || {};
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CUSTOMER_INFO,
    JSON.stringify({ ...vendorIdAddressMap, [vendorId]: customerInfo })
  );
};

export const storeCartInLocalStorage = (cart: any) => {
  localStorageManager.setItem(
    LOCAL_STORAGE_KEYS.CART_INFO,
    JSON.stringify(cart)
  );
};

export const getCartInfoFromLocalStorage = () => {
  return (
    JSON.parse(
      localStorageManager.getItem(LOCAL_STORAGE_KEYS.CART_INFO) || "{}"
    ) || {}
  );
};
