import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { Button } from "@kuupanda/commons";
import { ReactComponent as Hands } from "../../assets/Images/hands.svg";
import Typography from "@mui/material/Typography";

const StyledDialog = styled(Dialog)`
  > div > div {
    max-width: 800px;
  }
`;

const StyledIconButton = styled(IconButton)`
  color: #1a192e;
  padding: 2px;
  right: 15px;
  position: absolute;
  top: 15px;
`;

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 16,
  textAlign: "center",
  padding: "0 24px",
});

const Cases = styled("div")({
  display: "flex",
  gap: 16,
});

const Case = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: 8,
  background: theme.palette.secondary.light,
  padding: 8,
  borderRadius: 8,
}));

const CaseTitle = styled("div")(({ theme }) => ({
  alignSelf: "stretch",
  borderBottom: `1px solid ${theme.palette.grey[600]}`,
  display: "flex",
  flexDirection: "row",
  padding: "3px 8px",
  alignItems: "flex-start",
  justifyContent: "center",
  color: theme.palette.secondary.dark,
}));

export const VariableExplanationModal: React.FC<{
  open: boolean;
  onClose: any;
  variableWeightPercent: number;
}> = ({ open, onClose, variableWeightPercent }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      fullScreen={fullScreen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        id: "kuu-weight-modal",
      }}
      maxWidth={"md"}
      fullWidth
    >
      <DialogContent>
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon color="inherit" />
        </StyledIconButton>
        <Content>
          <Hands />
          <Typography variant={"h3"}>Payez le juste prix !</Typography>
          <Typography component={"div"} textAlign={"left"}>
            Viandes, poissons mais encore fruits ou légumes… Chaque produit est
            unique et son poids peut parfois être variable. Au moment de la
            préparation des commandes, lorsque le producteur sélectionne vos
            produits
            <Typography fontWeight={"bold"} component={"span"}>
              , le poids final peut être légèrement supérieur ou inférieur à la
              quantité demandée
            </Typography>
          </Typography>
          <Box textAlign={"left"}>
            <Typography
              sx={{ textDecoration: "underline" }}
              fontWeight={"bold"}
            >
              Si vous optez pour le paiement par carte :
            </Typography>
            <Typography>
              Le prix du produit au poids variable sera temporairement majoré de
              10 à 20% selon le taux choisi par le producteur. Le producteur
              devra alors renseigner le poids réel du ou des produits afin de
              déterminer le montant final de votre commande.
            </Typography>
          </Box>
          <Box>
            <Typography color={"textSecondary"}>Voici un exemple :</Typography>
            <Typography color={"textSecondary"}>
              Julie commande un poulet rôti de 1,5kg à 5€/kg
            </Typography>
            <Typography color={"textSecondary"}>
              Le prix estimé est de 7,50 €.
            </Typography>
          </Box>
          <Cases>
            <Case>
              <CaseTitle>
                <Typography fontWeight={"bold"} color={"inherit"}>
                  Cas n°1
                </Typography>
              </CaseTitle>
              <Typography>Poids réel : 1,4kg</Typography>
              <Typography fontWeight={"bold"}>Prix final : 7,00€</Typography>
            </Case>
            <Case>
              <CaseTitle>
                <Typography fontWeight={"bold"} color={"inherit"}>
                  Cas n°2
                </Typography>
              </CaseTitle>
              <Typography>Poids réel : 1,5kg</Typography>
              <Typography fontWeight={"bold"}>Prix final : 7,50€</Typography>
            </Case>
            <Case>
              <CaseTitle>
                <Typography fontWeight={"bold"} color={"inherit"}>
                  Cas n°3
                </Typography>
              </CaseTitle>
              <Typography>Poids réel : 1,6kg</Typography>
              <Typography fontWeight={"bold"}>Prix final : 8,00€</Typography>
            </Case>
          </Cases>

          <Typography fontWeight={"bold"}>
            Si le poids réel est inférieur au poids estimé par le producteur,
            alors vous percevrez un remboursement directement sur votre compte
            bancaire.
          </Typography>

          <Box textAlign={"left"}>
            <Typography
              sx={{ textDecoration: "underline" }}
              fontWeight={"bold"}
            >
              Si vous optez pour un autre moyen de paiement :
            </Typography>
            <Typography>
              Après confirmation de la commande par votre producteur, vous
              recevrez par email le montant exact à payer selon votre choix de
              mode de paiement.
            </Typography>
          </Box>
          <Button onClick={onClose} color={"secondary"}>
            J’ai compris !
          </Button>
        </Content>
      </DialogContent>
    </StyledDialog>
  );
};

export default VariableExplanationModal;
