import React from "react";
import MUITooltip from "@mui/material/Tooltip";
import { useIsMobile } from "@kuupanda/commons";
import IconButton from "@mui/material/IconButton";
import { RiInformationLine } from "react-icons/ri";
import { ClickAwayListener } from "@mui/material";

const Tooltip: React.FC<{ title: any }> = ({ title }) => {
  const isMobile = useIsMobile();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <MUITooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={<span style={{ whiteSpace: "pre-line" }}>{title}</span>}
        >
          <IconButton
            aria-label="help"
            size="small"
            onClick={handleTooltipOpen}
          >
            <RiInformationLine />
          </IconButton>
        </MUITooltip>
      </ClickAwayListener>
    );
  }

  return (
    <MUITooltip
      disableFocusListener
      disableTouchListener
      placement={"right-start"}
      title={<span style={{ whiteSpace: "pre-line" }}>{title}</span>}
    >
      <IconButton aria-label="help" size="small">
        <RiInformationLine />
      </IconButton>
    </MUITooltip>
  );
};

export default Tooltip;
