import { Link, Typography } from "@mui/material";
import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { RiArrowUpDownFill } from "react-icons/ri";
import constants from "../../constants";
import { checkColorIsLightOrDark } from "../../helpers/misc";

const VariableWeightDesc = styled("div")<{ $mode: string }>(
  ({ $mode, theme }) => ({
    background: theme.palette.secondary.light,
    padding: 8,
    borderRadius: 8,
    marginBottom: 8,
    color: $mode === "light" ? theme.palette.text.primary : "#ffffff",
    fontSize: 14,
  })
);

const LearnMoreContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginBottom: 8,
  [theme.breakpoints.down("md")]: {
    gap: 0,
  },
}));

const Arrow = styled(RiArrowUpDownFill)<{ $mode: string }>(
  ({ $mode, theme }) => ({
    fill: $mode === "light" ? theme.palette.grey[700] : "#ffffff",
  })
);

const LearnMore = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: 12,
}));

const VariableWeightHelper: React.FC<{
  products: any[];
  onClick: () => void;
  paymentMethod: string;
  variableWeightPercent: number;
}> = ({ products, onClick, paymentMethod, variableWeightPercent }) => {
  const theme = useTheme();
  const mode = checkColorIsLightOrDark(theme.palette.secondary.light);

  return (
    <VariableWeightDesc $mode={mode}>
      <LearnMoreContainer>
        <Arrow $mode={mode} />
        <Typography variant={"caption"} color={"inherit"}>
          Poids variable
        </Typography>
        <LearnMore onClick={onClick}>En savoir plus</LearnMore>
      </LearnMoreContainer>

      <Typography fontWeight={"bold"} color={"inherit"} variant={"caption"}>
        Attention, les produits ci-dessous :
      </Typography>

      <ul>
        {products.map((product) => (
          <li>
            {product.name} ({product.averageWeight}
            {product.variableUnit})
          </li>
        ))}
      </ul>

      <Typography component={"div"} color={"inherit"} variant={"caption"}>
        <Typography
          fontWeight={"bold"}
          component={"span"}
          color={"inherit"}
          variant={"caption"}
        >
          sont des produits à poids variable.{" "}
        </Typography>

        {paymentMethod === constants.PAYMENT_METHOD.CREDIT_CARD
          ? `Ainsi, le montant final de la commande peut légèrement varier. Si vous optez pour le paiement par carte, le prix du produit au poids variable sera temporairement majoré de ${
              variableWeightPercent * 100
            }%. Le producteur devra alors renseigner le poids réel du produit afin de déterminer le montant final de votre commande. Si le poids réel est inférieur au poids estimé par le producteur, alors vous percevrez un remboursement directement sur votre compte bancaire.`
          : "Ainsi, le montant final de la commande peut légèrement varier. Le producteur va renseigner le poids réel des produits afin de déterminer le montant final de votre commande."}
      </Typography>
    </VariableWeightDesc>
  );
};

export default VariableWeightHelper;
