import { Box } from "@mui/material";
import chronopost from "../assets/Images/chronopost.png";
import chronofresh from "../assets/Images/chronofresh.png";
import shop2shop from "../assets/Images/chronopost_shop2shop.png";
import ups from "../assets/Images/ups.png";
import modialrelay from "../assets/Images/mondial_relay.png";
import { CommonTypes } from "@kuupanda/kuu-contract";

export const TransporterLogo = ({
  contactPoint,
  hasFreshProducts,
}: {
  contactPoint: any;
  hasFreshProducts: boolean;
}) => {
  if (
    contactPoint.type === CommonTypes.ContactPointType.CHRONOPOST &&
    contactPoint.modes.includes(CommonTypes.ChronopostModes.FRESH) &&
    hasFreshProducts
  ) {
    return (
      <Box
        component="img"
        src={chronofresh}
        alt={"Chronofresh"}
        sx={{ height: 40 }}
      />
    );
  }

  if (
    contactPoint.type === CommonTypes.ContactPointType.CHRONOPOST ||
    contactPoint.type === CommonTypes.ContactPointType.CHRONOPOST_RELAY ||
    contactPoint.type === CommonTypes.ContactPointType.CHRONOPOST_VITI
  ) {
    return (
      <Box
        component="img"
        src={chronopost}
        alt={"Chronopost"}
        sx={{ height: 40 }}
      />
    );
  }

  if (contactPoint.type === CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP) {
    return (
      <Box
        component="img"
        src={shop2shop}
        alt={"Shop2Shop"}
        sx={{ height: 16 }}
      />
    );
  }

  if (contactPoint.type === CommonTypes.ContactPointType.UPS) {
    return <Box component="img" src={ups} alt={"UPS"} sx={{ height: 35 }} />;
  }

  if (contactPoint.type === CommonTypes.ContactPointType.MONDIAL_RELAY) {
    return (
      <Box
        component="img"
        src={modialrelay}
        alt={"Mondial Relay"}
        sx={{ height: 35 }}
      />
    );
  }
  return null;
};
