import React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import { BaseRadio, Alert } from "@kuupanda/commons";

const StyledContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const StyledFormControl = styled(FormControl)`
  max-height: 310px;
  top: 20px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
`;

const WrapperListItem = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const ListItem = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "selected",
})<{ selected: boolean }>(
  ({ theme, selected }) => `
      width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${theme.palette.grey["600"]};
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${selected ? "rgba(214, 230, 249, 0.3)" : "white"};
`
);

const RelayName = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  color: ${theme.palette.grey["900"]};
  line-height: 21px;
  text-transform: lowercase;
  :first-line {
    text-transform: capitalize;
  }
`
);

const RelayAddress = styled(Typography)`
  font-size: 12px;
  line-height: 17px;
  text-transform: lowercase;
  :first-line {
    text-transform: capitalize;
  }
`;

const SelectRelayPoint = (props: any) => {
  const handleSelectRelay = (event: any) => {
    const currentRelay = props.relayPoints.find(
      (relay: any) => relay.code === event.target.value
    );
    props.handleSelectedRelayPoint(currentRelay);
    props.handleClose();
  };

  if (!props.address) {
    return (
      <div>
        <Typography variant="caption">
          Vous serez informé par email lorsque le colis sera arrivé au point
          relais
        </Typography>

        <Alert
          variant="error"
          message="Renseignez votre adresse pour voir les points relais disponible."
        />
      </div>
    );
  }

  return (
    <StyledContainer>
      {!props.relayPoints ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="caption">
            Vous serez informé par email lorsque le colis sera arrivé au point
            relais
          </Typography>

          <StyledFormControl>
            <RadioGroup
              onChange={handleSelectRelay}
              defaultValue={props?.relayPoint?.code}
            >
              {props.relayPoints.map((relay: any) => (
                <WrapperListItem key={relay.code}>
                  <StyledFormControlLabel
                    sx={{ width: "100%" }}
                    value={relay.code}
                    control={<BaseRadio />}
                    label={
                      <ListItem
                        selected={relay.code === props?.relayPoint?.code}
                      >
                        <RelayName>{relay.name}</RelayName>
                        <RelayAddress color="primary.dark">{`${relay.house_number} ${relay.street} ${relay.city}`}</RelayAddress>
                      </ListItem>
                    }
                  />
                </WrapperListItem>
              ))}
            </RadioGroup>
          </StyledFormControl>

          {Object.values(props.relayPoints).length === 0 && (
            <Alert
              variant="error"
              message="Aucun point de retrait disponible à l'adresse indiquée. Veuillez
                choisir une autre méthode de retrait."
            />
          )}
        </>
      )}
    </StyledContainer>
  );
};
export default SelectRelayPoint;
