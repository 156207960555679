import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import {
  BaseAddressInput,
  BaseAutoCompleteInput,
  BaseInput,
  BaseLabel,
} from "@kuupanda/commons";
import { getCountries } from "../helpers/countries";
import { isCpChronopost } from "../helpers/delivery";

const Container = styled("div")``;

const Row = styled("div")`
  display: flex;
  gap: 20px;
`;

const AddressInput: React.FC<{
  value: any;
  onChangeField: any;
  className?: string;
  contactPoint: any;
}> = ({ value, onChangeField, className, contactPoint }) => {
  const countries = getCountries();

  const [invalid, setInvalid] = useState({
    locality: !Boolean(value?.locality),
    postal_code: !Boolean(value?.postal_code),
    address: !Boolean(value?.text),
  });

  const [values, setValues] = useState<Record<string, any>>({
    name: value?.name || "",
    lastName: value?.lastName || "",
    text: value?.text || "",
    additional_info: value?.additional_info || "",
    street_number: value?.street_number || "",
    route: value?.route || "",
    locality: value?.locality || "",
    administrative_area_level_1: value?.administrative_area_level_1 || "",
    country: value?.country || "FR",
    postal_code: value?.postal_code || "",
    lat: value?.lat || "",
    lng: value?.lng || "",
    place_id: value?.place_id || "",
    address: value,
  });

  const handleAddressChange = async (value: any) => {
    const addressObj = {
      ...values,
      text: value?.text,
      additional_info: values?.additional_info,
      street_number: value?.street_number,
      route: value?.route,
      locality: value?.locality,
      administrative_area_level_1: value?.administrative_area_level_1,
      postal_code: value?.postal_code,
      lat: value?.lat,
      lng: value?.lng,
      place_id: value?.place_id,
      address: {
        text: value?.text,
        additional_info: values?.additional_info,
        street_number: value?.street_number,
        route: value?.route,
        locality: value?.locality,
        administrative_area_level_1: value?.administrative_area_level_1,
        postal_code: value?.postal_code,
        lat: value?.lat,
        lng: value?.lng,
        country: values?.country,
        place_id: value?.place_id,
      },
    };

    const { address, ...rest } = addressObj;

    setInvalid((prev) => ({
      ...prev,
      address: Boolean(!value),
      locality: !Boolean(value?.locality),
      postal_code: !Boolean(value?.locality),
    }));

    setValues({ ...address, ...rest });

    onChangeField(address);
  };

  const handleChange = async (component: string, value: any) => {
    setInvalid((prev) => ({
      ...prev,
      [component]: !Boolean(value && value.length > 0),
    }));

    const newValues = { ...values, [component]: value };
    setValues(newValues);
    onChangeField(newValues);
  };

  const selectedCountry = countries.find(
    (country) => country.value === values.country
  );

  const isChrono = useMemo(
    () => isCpChronopost(contactPoint.type),
    [contactPoint.type]
  );

  return (
    <Container className={className}>
      <FormControl fullWidth margin="normal" error={invalid.address}>
        <BaseLabel htmlFor={`address`}>Numéro et nom de rue</BaseLabel>

        <BaseAddressInput
          id={`address`}
          name={`address`}
          value={values.address}
          onChange={(value: any) => handleAddressChange(value)}
          error={invalid.address}
          allowedCountries={isChrono ? ["FR"] : undefined}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <BaseLabel htmlFor={`additional_info`}>Complément d'adresse</BaseLabel>
        <BaseInput
          fullWidth
          id={`additional_info`}
          name={`additional_info`}
          value={values.additional_info}
          onChange={(event) =>
            handleChange("additional_info", event.target.value)
          }
        />
      </FormControl>

      <Row>
        <FormControl fullWidth margin="normal" error={invalid.postal_code}>
          <BaseLabel htmlFor={`postal_code`}>Code postal</BaseLabel>
          <BaseInput
            id={`postal_code`}
            fullWidth
            name={`postal_code`}
            value={values.postal_code}
            onChange={(event) =>
              handleChange("postal_code", event.target.value)
            }
            type="number"
            inputProps={{ pattern: "[0-9]{10}" }}
            error={invalid.postal_code}
          />
        </FormControl>

        <FormControl fullWidth margin="normal" error={invalid.locality}>
          <BaseLabel htmlFor={`locality`}>Ville</BaseLabel>
          <BaseInput
            id={`locality`}
            fullWidth
            name={`locality`}
            value={values.locality}
            onChange={(event) => handleChange("locality", event.target.value)}
            error={invalid.locality}
          />
        </FormControl>
      </Row>
      <FormControl fullWidth margin="normal">
        <BaseLabel htmlFor={`country`}>Pays</BaseLabel>
        <BaseAutoCompleteInput
          id={`country`}
          name={`country`}
          value={[selectedCountry]}
          options={countries}
          onChange={(event: { value: string }) =>
            handleChange("country", event.value)
          }
          disabled={isChrono}
        />
      </FormControl>
    </Container>
  );
};

export default AddressInput;
