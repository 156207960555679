import React, { useMemo } from "react";

import {
  ButtonOnlyIcon,
  NumberFormatCustom,
  Tag,
  useIsMobile,
} from "@kuupanda/commons";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import QuantitySelector from "../common/QuantitySelector";
import {
  RiPriceTag3Line,
  RiRefreshLine,
  RiShoppingCart2Line,
} from "react-icons/ri";
import { CommonTypes } from "@kuupanda/kuu-contract";
import SizeBox from "../common/SizeBox";
import ResponsivePicture from "../common/ResponsivePicture";
import { productTagsMapping } from "../helpers/productLabels";
import {
  getProductName,
  getUnitName,
  isComboMultiple,
  isComboSingle,
  isProductAvailable,
} from "../helpers/product";
import VariableWeight from "../common/VariableWeight/VariableWeight";
import constants from "../constants";
import { format, parseISO } from "date-fns";

const Container = styled("div")(
  ({ theme }) => `
  border: 2px solid ${theme.palette.grey[100]};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  &:hover {
    border: 2px solid ${theme.palette.primary.light};
  }
`
);

const Content = styled("div")`
  display: flex;
  gap: 8px;
  flex-direction: column;
  height: 100%;
`;

const PriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const PriceBeforeDiscount = styled(Typography)(
  ({ theme }) => `
  text-decoration: line-through;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: ${theme.palette.grey[800]};
  line-height: 140%;
`
);

const Prices = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Price = styled(Typography)(
  ({ theme }) => `
  font-weight: 500;
  font-size: 14px;
  color: ${theme.palette.others.g};
  line-height: 140%;
`
);

const PriceUnit = styled(Typography)(
  ({ theme }) => `
  font-size: 12px;
  color: ${theme.palette.grey[700]};
  line-height: 140%;
`
);

const PriceAfterDiscount = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${theme.palette.primary.dark};
`
);

const TagContainer = styled("div")`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 5px;
  align-self: flex-start;
  margin: 5px 0 0 5px;
`;

const SmallTag = styled(Tag)({
  padding: "3px 6px",
  fontSize: 12,
  height: 14,
});

const VariableWeightCard = styled(VariableWeight)({
  margin: "4px 0",
});

const CustomQuantitySelector = styled(QuantitySelector)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    div: { width: "100%" },
  },
}));

const isDefaultImage = (url: string) => {
  return url.includes("/default/");
};

const ProductItemCard: React.FC<{
  product: any;
  className?: string;
  onChangeCart: any;
  quantity: any;
  onProductClick: any;
  saleChannel: CommonTypes.SaleChannel;
  isGroup: boolean;
  onWeightClick: any;
  onValidateCart: any;
}> = ({
  product,
  className,
  onChangeCart,
  quantity,
  onProductClick,
  saleChannel,
  isGroup,
  onWeightClick,
  onValidateCart,
}) => {
  const isMobile = useIsMobile();

  const height = !isMobile ? 250 : 125;

  const isProfessional = saleChannel === CommonTypes.SaleChannel.PROFESSIONAL;

  const {
    price,
    priceBeforeReduction,
    priceReduction,
    discounts = [],
    highlight,
    pictures,
    baseUnit,
    sizes,
    producer,
    sellByVariableWeight,
    averageWeight,
    variableUnit,
    variableWeightPrice,
    priceByUnit,
    availableEndDate,
    discount,
  } = product;

  const name = getProductName(product);

  const availableDate = useMemo(() => {
    if (
      product.preparationType === constants.PREPARATION_TYPE.SPECIFIC_DATE &&
      product.availableDate
    ) {
      const availableDateTime = new Date(product.availableDate).getTime();

      if (Date.now() <= availableDateTime) {
        return product.availableDate;
      }
    }
  }, [product.availableDate, product.preparationType]);

  const isPastAvailableEnd = availableEndDate
    ? Date.now() >= new Date(availableEndDate).getTime()
    : false;

  const isAvailable = isProductAvailable(product);

  const onChange = (value: string) => {
    onChangeCart({
      product,
      quantity: value,
    });
  };

  const onValidate = (quantity: number) => {
    return onValidateCart({
      product,
      quantity: quantity,
    });
  };

  const totalDiscountPercent = useMemo(() => {
    const amount = discounts.reduce(
      (acc: number, discount: { amount: number }) => acc + discount.amount,
      0
    );

    return amount > 0 ? amount * 100 : 0;
  }, [discounts]);

  const imageIsDefault = isDefaultImage(pictures[0].url);

  return (
    <Container className={className}>
      <Content>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <TagContainer>
            {isGroup && producer.storeName && producer.storeName.length > 0 && (
              <SmallTag variant="default">{producer.storeName}</SmallTag>
            )}

            {discount && discount.minimumAmount > 0 && (
              <SmallTag variant="purple" startIcon={<RiPriceTag3Line />}>
                Promo{" "}
                <NumberFormatCustom
                  value={
                    discount.type === "PERCENTAGE"
                      ? discount.amount * 100
                      : discount.amount
                  }
                  displayType="text"
                  suffix={discount.type === "PERCENTAGE" ? " %" : " €"}
                />{" "}
                à partir de{" "}
                <NumberFormatCustom
                  value={discount.minimumAmount}
                  displayType="text"
                />
              </SmallTag>
            )}

            {totalDiscountPercent > 0 && (
              <SmallTag variant="purple" startIcon={<RiPriceTag3Line />}>
                PROMO -
                <NumberFormatCustom
                  value={totalDiscountPercent}
                  displayType="text"
                  suffix={" %"}
                />
              </SmallTag>
            )}

            {highlight === CommonTypes.ProductHighlightType.NEW && (
              <SmallTag variant="green">NOUVEAU !</SmallTag>
            )}

            {availableDate && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Disponible à partir du{" "}
                {format(parseISO(availableDate), "dd/MM")}
              </SmallTag>
            )}

            {availableEndDate && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Disponible jusqu’au{" "}
                {format(parseISO(availableEndDate), "dd/MM")}
              </SmallTag>
            )}

            {isComboSingle(product) && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Produit avec variation
              </SmallTag>
            )}

            {isComboMultiple(product) && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Panier avec options
              </SmallTag>
            )}

            {product.tags &&
              product.tags.length > 0 &&
              product.tags.map((tag: string, index: number) => (
                <SmallTag key={index} variant="default">
                  {productTagsMapping[tag]}
                </SmallTag>
              ))}

            {!isAvailable && (
              <SmallTag variant={"red"}>Rupture de stock</SmallTag>
            )}
          </TagContainer>
          <Box onClick={() => onProductClick(product)}>
            <ResponsivePicture
              alt={name}
              height={height - 2} //2px border
              baseImgUrl={pictures[0]?.url}
              containerStyle={imageIsDefault && { transform: "scale(0.5,0.5)" }}
            />
          </Box>

          {sizes && sizes.length > 0 && (
            <Box sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <SizeBox sizes={sizes} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            padding: "0 10px 10px 10px",
            height: "100%",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Typography component="div">
            <Box
              fontWeight={700}
              fontSize={16}
              onClick={() => onProductClick(product)}
            >
              {name}
            </Box>
          </Typography>
          {sellByVariableWeight && (
            <VariableWeightCard onClick={onWeightClick} />
          )}

          <Box sx={{ flex: 1 }} />
          <PriceContainer>
            <div>
              {priceReduction > 0 ? (
                <Prices>
                  <PriceBeforeDiscount>
                    <NumberFormatCustom
                      value={priceBeforeReduction}
                      displayType="text"
                      suffix={isProfessional ? " € HT" : " €"}
                    />
                  </PriceBeforeDiscount>

                  <PriceAfterDiscount>
                    <NumberFormatCustom
                      value={price}
                      displayType="text"
                      suffix={isProfessional ? " € HT" : " €"}
                    />
                  </PriceAfterDiscount>

                  <PriceUnit>
                    {baseUnit === "unit" &&
                      averageWeight &&
                      variableUnit &&
                      variableWeightPrice && (
                        <>
                          <NumberFormatCustom
                            value={priceByUnit}
                            displayType="text"
                            suffix={isProfessional ? " € HT" : " €"}
                          />
                          {" / "}
                        </>
                      )}

                    {getUnitName(product)}
                  </PriceUnit>
                </Prices>
              ) : (
                <Prices>
                  <Price>
                    <NumberFormatCustom
                      value={price}
                      displayType="text"
                      suffix={isProfessional ? " € HT" : " €"}
                    />
                  </Price>
                  <PriceUnit>
                    {baseUnit === "unit" &&
                      averageWeight &&
                      variableUnit &&
                      variableWeightPrice && (
                        <>
                          <NumberFormatCustom
                            value={priceByUnit}
                            displayType="text"
                            suffix={isProfessional ? " € HT" : " €"}
                          />
                          {" / "}
                        </>
                      )}

                    {getUnitName(product)}
                  </PriceUnit>
                </Prices>
              )}
            </div>

            {(sizes && sizes.length > 0) ||
            isComboSingle(product) ||
            isComboMultiple(product) ? (
              <ButtonOnlyIcon onClick={() => onProductClick(product)}>
                <RiShoppingCart2Line />
              </ButtonOnlyIcon>
            ) : (
              <CustomQuantitySelector
                value={quantity}
                onChange={onChange}
                decimalScale={baseUnit === "unit" ? 0 : 2}
                disabled={!isAvailable || isPastAvailableEnd}
                onValidate={onValidate}
              />
            )}
          </PriceContainer>
        </Box>
      </Content>
    </Container>
  );
};

export default ProductItemCard;
