import React from "react";
import { styled } from "@mui/material/styles";

//styleds
const SizeBoxContainer = styled("span")(
  ({ theme }) => `
  font-size: 12px;
  padding: 5px 8px 5px 8px;
  background: ${theme.palette.grey["900"]};
  opacity: 0.9;
  color: white;
  font-weight: 500;
  border-radius: 8px;
  display: flex;
  align-self: flex-start;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
`
);

const Item = styled("div")`
  text-align: center;
  flex: 1 1 0;
`;

const SizeBox: React.FC<{ sizes: string[] }> = ({ sizes }) => {
  const sizesList = sizes.slice(0, 4); // we limit here to keep the list pretty

  return (
    <SizeBoxContainer>
      {sizesList.map((size, index) => (
        <React.Fragment key={index}>
          <Item>{size}</Item>
          {sizesList.length > 1 && <div>|</div>}
        </React.Fragment>
      ))}
      {sizes.length > 4 && <Item>...</Item>}
    </SizeBoxContainer>
  );
};

export default SizeBox;
