import { createContext, useContext } from "react";
import { Theme } from "@mui/material";

const CustomThemeContext = createContext({
  updateTheme: (theme: Partial<Theme>) => {},
});

function useCustomTheme() {
  const customTheme = useContext(CustomThemeContext);
  if (customTheme == null) {
    throw new Error(
      "You must call useCustomTheme only when inside a CustomThemeProvider."
    );
  }

  return customTheme;
}

export { CustomThemeContext, useCustomTheme };
