import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import {
  BaseAddressInput,
  BaseAutoCompleteInput,
  BaseInput,
  BaseLabel,
} from "@kuupanda/commons";
import constants from "../constants";
import { getCountries } from "../helpers/countries";

const Container = styled("div")``;

const Row = styled("div")`
  display: flex;
  gap: 20px;
`;

const BillingInput: React.FC<{
  value: any;
  onChangeField: any;
  className?: string;
  saleChannel: string;
}> = ({ value, onChangeField, className, saleChannel }) => {
  const countries = getCountries();

  const [error, setError] = useState(false);

  const [values, setValues] = useState<Record<string, any>>({
    name: value?.name || "",
    lastName: value?.lastName || "",
    text: value?.text || "",
    additional_info: value?.additional_info || "",
    street_number: value?.street_number || "",
    route: value?.route || "",
    locality: value?.locality || "",
    administrative_area_level_1: value?.administrative_area_level_1 || "",
    country: value?.country || "FR",
    postal_code: value?.postal_code || "",
    lat: value?.lat || "",
    lng: value?.lng || "",
    place_id: value?.place_id || "",
    address: value,
  });

  const handleAddressChange = async (value: any) => {
    setError(false);

    const addressObj = {
      ...values,
      text: value.text,
      additional_info: values?.additional_info,
      street_number: value?.street_number,
      route: value?.route,
      locality: value?.locality,
      administrative_area_level_1: value?.administrative_area_level_1,
      postal_code: value?.postal_code,
      lat: value?.lat,
      lng: value?.lng,
      place_id: value?.place_id,
      address: {
        text: value?.text,
        additional_info: values?.additional_info,
        street_number: value?.street_number,
        route: value?.route,
        locality: value?.locality,
        administrative_area_level_1: value?.administrative_area_level_1,
        postal_code: value?.postal_code,
        lat: value?.lat,
        lng: value?.lng,
        country: values?.country,
        place_id: value?.place_id,
      },
    };

    const { address, ...rest } = addressObj;

    setValues({ ...address, ...rest });

    onChangeField(address);

    if (!value) {
      setError(true);
    }
  };

  const handleChange = async (component: string, value: any) => {
    const newValues = { ...values, [component]: value };
    setValues(newValues);
    onChangeField(newValues);
  };

  const selectedCountry = countries.find(
    (country) => country.value === values.country
  );

  return (
    <Container className={className}>
      <Row>
        <FormControl fullWidth margin="normal">
          <BaseLabel htmlFor={`name`}>Prenom</BaseLabel>
          <BaseInput
            id={`name`}
            fullWidth
            name={`name`}
            value={values.name}
            onChange={(event) => handleChange("name", event.target.value)}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <BaseLabel htmlFor={`lastName`}>Nom</BaseLabel>
          <BaseInput
            id={`lastName`}
            fullWidth
            name={`lastName`}
            value={values.lastName}
            onChange={(event) => handleChange("lastName", event.target.value)}
          />
        </FormControl>
      </Row>

      {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
        <Row>
          <FormControl fullWidth margin="normal">
            <BaseLabel htmlFor="company">Entreprise (facultatif)</BaseLabel>
            <BaseInput
              id="company"
              type="company"
              name="company"
              value={values.company}
              onChange={(event) => handleChange("company", event.target.value)}
            />
          </FormControl>
        </Row>
      )}
      <FormControl fullWidth margin="normal" error={error}>
        <BaseLabel htmlFor={`address`}>Numéro et nom de rue</BaseLabel>

        <BaseAddressInput
          id={`address`}
          name={`address`}
          value={values.address}
          onChange={(value: any) => handleAddressChange(value)}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <BaseLabel htmlFor={`additional_info`}>Complément d'adresse</BaseLabel>
        <BaseInput
          fullWidth
          id={`additional_info`}
          name={`additional_info`}
          value={values.additional_info}
          onChange={(event) =>
            handleChange("additional_info", event.target.value)
          }
        />
      </FormControl>

      <Row>
        <FormControl fullWidth margin="normal" error={error}>
          <BaseLabel htmlFor={`postal_code`}>Code postal</BaseLabel>
          <BaseInput
            id={`postal_code`}
            fullWidth
            name={`postal_code`}
            value={values.postal_code}
            onChange={(event) =>
              handleChange("postal_code", event.target.value)
            }
            type="number"
            inputProps={{ pattern: "[0-9]{10}" }}
            error={error}
          />
        </FormControl>

        <FormControl fullWidth margin="normal" error={error}>
          <BaseLabel htmlFor={`locality`}>Ville</BaseLabel>
          <BaseInput
            id={`locality`}
            fullWidth
            name={`locality`}
            value={values.locality}
            onChange={(event) => handleChange("locality", event.target.value)}
            error={error}
          />
        </FormControl>
      </Row>
      <FormControl fullWidth margin="normal">
        <BaseLabel htmlFor={`billingCountry`}>Pays</BaseLabel>
        <BaseAutoCompleteInput
          id={`billingCountry`}
          name={`country`}
          value={[selectedCountry]}
          options={countries}
          onChange={(event: { value: string }) =>
            handleChange("country", event.value)
          }
        />
      </FormControl>
    </Container>
  );
};

export default BillingInput;
