import React, { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";

// Material Components
import { Box, CircularProgress, Typography } from "@mui/material";

// Components
import ContactPointDateField from "../common/ContactPointDateField";
import DeliveryDateFrequency from "../common/DeliveryDateFrequency";
import ContactPointTypeButton from "../common/ContactPointTypeButton";
import ContactPointOptions from "../common/ContactPointOptions";

import {
  calculateCPFees,
  checkHasUndeliverableProducts,
  checkIfHasColdProducts,
  checkIfIsSelectable,
  getAvailableDates,
  getContactPointAvailableDate,
  getContactPointInfo,
  getUndeliverableCount,
  isContactPointValid,
  isDateInRange,
} from "../helpers/contactPoint";

import {
  isCpDelivery,
  isCpRelay,
  isCpExternalShipping,
  resetHour,
  isCPTakeaway,
  checkIfWeightExceeded,
  isCpChronopost,
  isCpCarrier,
  getPrepTime,
} from "../helpers/delivery";

//hooks
import ShippingRelayPoints from "../common/ShippingRelayPoints";
import ContactPointMap from "../common/ContactPointMap";

import AddressInput from "../common/AddressInput";
import { Alert, useIsMobile } from "@kuupanda/commons";
import { closeModalIframe } from "../helpers/modal";
import { usePresentation } from "../context/PresentationContext";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { addDays, differenceInDays, isEqual } from "date-fns";

// Styleds
const Spacer = styled("div")`
  height: 24px;
`;

const DeliveryContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  gap: "20px",
}));

const TakeAwayContainer = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  gap: "20px",
}));

const FlexItem = styled("div")`
  flex: 1;
`;

// Hooks
function useCalculateContactPoint({
  contactPoints,
  availableDate,
  preparationTime,
  defineOrderDueTo,
  handleFormInfoChange,
  selectedDate,
}: {
  contactPoints: any;
  availableDate: Date;
  preparationTime: number;
  defineOrderDueTo: any;
  handleFormInfoChange: any;
  selectedDate?: Date;
}) {
  const [selectedContactPoint, setSelectedContactPoint] =
    useState<any>(undefined);
  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [date, setDate] = useState(selectedDate);
  const [isDelivery, setIsDelivery] = useState(true);
  const [isExternalShipping, setIsExternalShipping] = useState(true);

  const set = useCallback(
    (newSelectedContactPoint: any) => {
      if (contactPoints && newSelectedContactPoint) {
        setSelectedContactPoint(newSelectedContactPoint);
      }
    },
    [contactPoints]
  );
  useEffect(() => {
    if (contactPoints && selectedContactPoint) {
      const contactPointInfo = getContactPointInfo(
        contactPoints,
        selectedContactPoint
      );

      if (!contactPointInfo) {
        return;
      }

      const isDelivery = isCpDelivery(contactPointInfo.type);
      const isExternalShipping = isCpExternalShipping(contactPointInfo.type);

      setIsExternalShipping(isExternalShipping);
      setIsDelivery(isDelivery);

      let orderDueTo;

      if (!isExternalShipping) {
        const availableDates = getAvailableDates(
          contactPointInfo,
          availableDate,
          preparationTime,
          contactPoints,
          selectedContactPoint
        );

        const isAvailable = isDateInRange({
          date: date,
          dates: availableDates,
        });

        if (date && isAvailable) {
          orderDueTo = date;
        } else {
          orderDueTo = getContactPointAvailableDate({
            contactPoint: selectedContactPoint,
            currentDate: null,
            contactPoints,
            availableDate,
            preparationTime,
          });
        }

        setAvailableDates(availableDates);
      } else {
        const timeNeeded = getPrepTime(
          contactPointInfo.type,
          contactPointInfo.frequency
        );

        const referenceDate = new Date();

        const delay = differenceInDays(availableDate, referenceDate) + 1;

        referenceDate.setHours(0, 0, 0, 0);

        const delayDays = delay < 7 ? timeNeeded : delay;

        orderDueTo = addDays(referenceDate, delayDays);
        orderDueTo.setHours(0, 0, 0, 0);
      }

      //TODO: rewrite this hook
      if (!date || (date && orderDueTo && !isEqual(orderDueTo, date))) {
        defineOrderDueTo(
          orderDueTo,
          contactPointInfo,
          setDate,
          handleFormInfoChange
        );
      }
    }
  }, [
    availableDate,
    contactPoints,
    defineOrderDueTo,
    handleFormInfoChange,
    preparationTime,
    selectedContactPoint,
    date,
  ]);

  return [
    selectedContactPoint,
    availableDates,
    date,
    set,
    setDate,
    isDelivery,
    isExternalShipping,
  ];
}

type ContactPointMainProps = {
  availableDate: Date;
  title?: string;
  vendorId: string;
  vendorIdx: number;
  vendorName: string;
  contactPoints: any[];
  selectedContactPoint?: any;
  showVendorName: boolean;
  className?: string;
  clientAddress?: any;
  totalAmount: number;
  preparationTime: number;
  cart: any;
  showDescription?: boolean;
  isSimplifiedStore: boolean;
  contactPoint?: string;
  handleFormInfoChange: any;
  handleChangeAddressInfo: any;
  isDeliveryInfoValid: boolean;
  invalidRate: boolean;
  selectedRate?: number;
  selectedDate?: Date;
};

function defineOrderDueTo(
  chosenDate: Date,
  contactPointInfo: any,
  setDate: any,
  handleFormInfoChange: any
) {
  const date = resetHour({
    date: chosenDate,
    contactPoint: contactPointInfo,
  });

  if (
    isCpChronopost(contactPointInfo.type) ||
    isCpCarrier(contactPointInfo.type) ||
    contactPointInfo?.type === CommonTypes.ContactPointType.UPS ||
    contactPointInfo?.type === CommonTypes.ContactPointType.MONDIAL_RELAY
  ) {
    setDate(chosenDate);
    handleFormInfoChange("orderDueTo", chosenDate);

    return;
  }

  setDate(date);
  handleFormInfoChange("orderDueTo", date);
}

const getProductNamesUndeliverableProducts = (
  cart: any,
  type: string
): string => {
  const names = [];

  for (const item of Object.keys(cart)) {
    if (cart[item].product.excludedContactPointTypes.includes(type)) {
      names.push(cart[item].product.name);
    }
  }

  return names.join(",");
};

function ContactPointMain({
  availableDate,
  title,
  vendorId,
  vendorIdx,
  vendorName,
  showVendorName,
  totalAmount,
  preparationTime,
  clientAddress,
  showDescription,
  isSimplifiedStore,
  cart,
  contactPoint,
  handleFormInfoChange,
  handleChangeAddressInfo,
  isDeliveryInfoValid,
  invalidRate,
  selectedRate,
  selectedDate,
  ...props
}: ContactPointMainProps) {
  const { isEmbedded } = usePresentation();
  const isMobile = useIsMobile();

  const [noContactPoints, setNoContactPoints] = useState(false);

  const [contactPoints, setContactPoints] = useState<any[] | null>(null);
  const [currentRelay, setCurrentRelay] = useState(null);

  const [isChronopostRelayOpen, setIsChronopostRelayOpen] = useState(false);
  const [
    selectedContactPoint,
    availableDates,
    date,
    setSelectedContactPoint,
    setDate,
    isDelivery,
    isExternalShipping,
  ] = useCalculateContactPoint({
    contactPoints,
    availableDate,
    preparationTime,
    defineOrderDueTo,
    handleFormInfoChange,
    selectedDate,
  });

  const contactPointInfo = useMemo(
    () => getContactPointInfo(contactPoints, selectedContactPoint),
    [selectedContactPoint, contactPoints]
  );

  const hasFreshProducts = useMemo(() => {
    return checkIfHasColdProducts(cart);
  }, [cart]);

  const hasUndeliverableProducts = useMemo(() => {
    if (!contactPointInfo) {
      return true;
    }
    return checkHasUndeliverableProducts(cart, contactPointInfo.type);
  }, [cart, contactPointInfo]);

  const exceedTotalWeight = useMemo(() => {
    if (!contactPointInfo) {
      return true;
    }
    return checkIfWeightExceeded(cart, vendorId, contactPointInfo);
  }, [cart, contactPointInfo, vendorId]);

  const createLabel = useCallback(
    (cp: any) => {
      //takeaway
      if (cp.type === CommonTypes.ContactPointType.TAKE_AWAY) {
        return `${cp.name}`;
      }

      if (cp.type === CommonTypes.ContactPointType.UPS) {
        return `Livraison UPS`;
      }

      if (cp.type === CommonTypes.ContactPointType.MONDIAL_RELAY) {
        return `Mondial Relay en point relais`;
      }

      if (
        cp.type === CommonTypes.ContactPointType.LCS_AGENCY ||
        cp.type === CommonTypes.ContactPointType.LCS_ASSOC
      ) {
        return `Livraison à domicile`;
      }

      if (cp.type === CommonTypes.ContactPointType.CHRONOPOST_RELAY) {
        return `Livraison express en point relais`;
      }

      if (cp.type === CommonTypes.ContactPointType.CHRONOPOST_SHOP2SHOP) {
        return `Livraison standard en point relais`;
      }

      if (cp.type === CommonTypes.ContactPointType.CHRONOPOST_VITI) {
        return "Livraison Chronoviti";
      }

      const freshEnabled = cp.modes.includes(CommonTypes.ChronopostModes.FRESH);

      return cp.type === CommonTypes.ContactPointType.CHRONOPOST
        ? `Livraison ${
            hasFreshProducts && freshEnabled ? "Chronofresh" : "Chronopost"
          }`
        : cp.name;
    },
    [hasFreshProducts]
  );

  const createCP = useCallback(
    (cp: any) => {
      return {
        ...cp,
        id: cp._id || cp.id,
        value: cp._id || cp.id,
        label: createLabel(cp),
        comment: cp.comment,
        isSelectable: checkIfIsSelectable(
          cp,
          hasFreshProducts,
          totalAmount,
          cart,
          vendorId
        ),
        freeFeeOrderAmount: cp.freeFeeOrderAmount,
        minimumDeliveryAmount: cp.minimumDeliveryAmount,
        type: cp.type,
        exceedTotalWeight: checkIfWeightExceeded(cart, vendorId, cp),
        hasFreshProducts,
      };
    },
    [cart, createLabel, hasFreshProducts, totalAmount, vendorId]
  );

  const takeAwayContactPoints = useMemo(
    () =>
      (contactPoints &&
        contactPoints
          .filter(
            (it: any) => it.type === CommonTypes.ContactPointType.TAKE_AWAY
          )
          .map(createCP)) ||
      [],
    [contactPoints, createCP]
  );

  const deliveryContactPoints = useMemo(
    () =>
      (contactPoints &&
        contactPoints
          .filter(
            (it: any) => it.type !== CommonTypes.ContactPointType.TAKE_AWAY
          )
          .map(createCP)) ||
      [],
    [contactPoints, createCP]
  );

  const hasDeliveryCPs = useMemo(
    () => deliveryContactPoints.length > 0,
    [deliveryContactPoints.length]
  );
  const hasTakeAwayCPs = useMemo(
    () => takeAwayContactPoints.length > 0,
    [takeAwayContactPoints.length]
  );

  useEffect(() => {
    if (!contactPoints) {
      const validContactPoints = props.contactPoints.filter(
        (contactPointInfo) =>
          isContactPointValid(
            contactPointInfo,
            availableDate,
            preparationTime,
            contactPoints
          )
      );
      if (!validContactPoints || !validContactPoints.length) {
        setNoContactPoints(true);

        return;
      }

      setContactPoints(validContactPoints);
    }
  }, [
    availableDate,
    contactPoints,
    preparationTime,
    cart,
    props.contactPoints,
    vendorId,
    isSimplifiedStore,
  ]);

  useEffect(() => {
    if (!contactPoints || !contactPoint) {
      return;
    }
    const result = calculateCPFees({
      contactPoints,
      contactPoint: { id: contactPoint },
      vendorId,
      clientAddress,
      totalAmount,
      isSimplifiedStore,
      cart,
    });
    handleFormInfoChange("deliveryRate", result);
  }, [
    contactPoints,
    contactPoint,
    vendorId,
    clientAddress,
    totalAmount,
    isSimplifiedStore,
    cart,
    handleFormInfoChange,
  ]);

  const onChangeContactPoint = useCallback(
    (contactPoint: any) => {
      handleFormInfoChange("contactPoint", contactPoint.id);
      handleFormInfoChange("deliveryType", contactPoint.type);

      if (!isCpRelay(contactPoint.type)) {
        handleFormInfoChange("shipmentInfo", null);
      }
    },
    [handleFormInfoChange]
  );

  useEffect(() => {
    if (contactPoints && !selectedContactPoint) {
      const deliveryCP = contactPoints.find(
        (it) =>
          it.type === CommonTypes.ContactPointType.DELIVERY &&
          !(it.minimumDeliveryAmount > totalAmount)
      );

      const takeAwayCP = contactPoints.find(
        (it) => it.type === CommonTypes.ContactPointType.TAKE_AWAY
      );

      const storedCP =
        contactPoint &&
        contactPoints.find(
          (it) =>
            it.id === contactPoint && !it.isSelectable && !isCpRelay(it.type) //dont select relay even if it is selectable as we dont store relay
        );

      const selectedContactPoint =
        deliveryCP &&
        storedCP &&
        storedCP.type === CommonTypes.ContactPointType.TAKE_AWAY
          ? [deliveryCP || storedCP || takeAwayCP || contactPoints[0]].map(
              createCP
            )
          : [storedCP || deliveryCP || takeAwayCP || contactPoints[0]].map(
              createCP
            );
      onChangeContactPoint(selectedContactPoint[0]);
      setSelectedContactPoint(selectedContactPoint[0]);
    }
  }, [
    contactPoints,
    selectedContactPoint,
    setSelectedContactPoint,
    vendorId,
    totalAmount,
    hasFreshProducts,
    cart,
    createCP,
    contactPoint,
    onChangeContactPoint,
  ]);

  const handleChangeContactPoint = useCallback(
    (contactPoint: any) => {
      setSelectedContactPoint(contactPoint);
      onChangeContactPoint(contactPoint);

      const clientAddressOk = Boolean(
        isCPTakeaway(contactPoint.type) ||
          (clientAddress && clientAddress.text && clientAddress.text.length > 0)
      );

      if (
        isCpRelay(contactPoint.type) &&
        !currentRelay &&
        clientAddressOk &&
        checkIfIsSelectable(
          contactPoint,
          hasFreshProducts,
          totalAmount,
          cart,
          vendorId
        )
      ) {
        setIsChronopostRelayOpen(true);
      }
    },
    [
      setSelectedContactPoint,
      onChangeContactPoint,
      clientAddress,
      currentRelay,
      hasFreshProducts,
      totalAmount,
      cart,
      vendorId,
    ]
  );

  const handleSelectContactPointType = useCallback(
    (type: string) => {
      const contactPoint =
        type === CommonTypes.ContactPointType.DELIVERY
          ? deliveryContactPoints.find(
              (cp) =>
                cp.isSelectable ||
                cp.minimumDeliveryAmount > totalAmount ||
                (isCpRelay(cp.type) && hasFreshProducts) ||
                checkIfWeightExceeded(cart, vendorId, cp) // this double check handle with simplified store
            )
            ? deliveryContactPoints.find(
                (cp) =>
                  cp.isSelectable ||
                  cp.minimumDeliveryAmount > totalAmount ||
                  (isCpRelay(cp.type) && hasFreshProducts) ||
                  checkIfWeightExceeded(cart, vendorId, cp)
              )
            : takeAwayContactPoints[0] // this second check is for the case where we dont have any available delivery
          : takeAwayContactPoints[0];

      if (contactPoint) {
        handleChangeContactPoint(contactPoint);
      }
    },
    [
      deliveryContactPoints,
      takeAwayContactPoints,
      totalAmount,
      hasFreshProducts,
      cart,
      vendorId,
      handleChangeContactPoint,
    ]
  );

  const handleOnSelect = useCallback(
    (event: any) => {
      const cp = contactPoints?.find((cp: any) => cp.id === event.target.value);

      const clientAddressOk = Boolean(
        isCPTakeaway(cp?.type) ||
          (clientAddress && clientAddress.text && clientAddress.text.length > 0)
      );

      if (
        isCpRelay(cp.type) &&
        clientAddressOk &&
        checkIfIsSelectable(cp, hasFreshProducts, totalAmount, cart, vendorId)
      ) {
        setIsChronopostRelayOpen(true);
      }
      handleChangeContactPoint(createCP(cp));
    },
    [
      contactPoints,
      clientAddress,
      hasFreshProducts,
      totalAmount,
      cart,
      vendorId,
      handleChangeContactPoint,
      createCP,
    ]
  );

  const handleChangeDate = useCallback(
    (chosenDate: Date) => {
      defineOrderDueTo(
        chosenDate,
        contactPointInfo,
        setDate,
        handleFormInfoChange
      );
    },
    [contactPointInfo, handleFormInfoChange, setDate]
  );

  const handleCloseChronopostRelay = () => {
    setIsChronopostRelayOpen(false);
    closeModalIframe(isEmbedded, isMobile, "kuu-relay-point-modal");
  };

  const onSelect = (relayPoint: any) => {
    setCurrentRelay(relayPoint);
    handleFormInfoChange("shipmentInfo", relayPoint);
  };

  const undeliverableCount = useMemo(() => {
    if (!contactPointInfo) {
      return 0;
    }
    return getUndeliverableCount(cart, contactPointInfo.type);
  }, [cart, contactPointInfo]);

  const names = useMemo(() => {
    if (!contactPointInfo) {
      return "";
    }
    return getProductNamesUndeliverableProducts(cart, contactPointInfo.type);
  }, [cart, contactPointInfo]);

  const onChangeAddress = useCallback(
    (info: any) => {
      handleChangeAddressInfo(info);

      if (
        Object.keys(info).length > 0 &&
        info.text.length > 0 &&
        contactPointInfo &&
        isCpRelay(contactPointInfo.type) &&
        checkIfIsSelectable(
          contactPointInfo,
          hasFreshProducts,
          totalAmount,
          cart,
          vendorId
        )
      ) {
        setIsChronopostRelayOpen(true);
      }
    },
    [
      cart,
      contactPointInfo,
      handleChangeAddressInfo,
      hasFreshProducts,
      totalAmount,
      vendorId,
    ]
  );

  if (!contactPointInfo && !noContactPoints) {
    return <CircularProgress />;
  }

  if (noContactPoints) {
    return (
      <Typography>
        Désolé mais votre producteur / commerçant a désactivé tous ses moyens de
        récupération ou livraison de commande. N’hésitez pas à le contacter en
        direct.
      </Typography>
    );
  }

  return (
    <>
      {title && (
        <Typography variant="caption" gutterBottom>
          {title}
        </Typography>
      )}
      {showVendorName && (
        <Typography variant="caption" gutterBottom>
          {`${vendorIdx}. ${vendorName}`}
        </Typography>
      )}
      <ContactPointTypeButton
        handleOnSelect={handleSelectContactPointType}
        hasDeliveryCPs={hasDeliveryCPs}
        hasTakeAwayCPs={hasTakeAwayCPs}
        contactPoint={contactPointInfo}
      />
      <Spacer />
      {hasDeliveryCPs && isDelivery && (
        <DeliveryContainer>
          <FlexItem>
            <Typography color="textSecondary" paragraph component="div">
              <Box fontWeight={500} fontSize={14}>
                1. Renseignez votre adresse
              </Box>
            </Typography>
            <AddressInput
              value={clientAddress}
              onChangeField={onChangeAddress}
              contactPoint={contactPointInfo}
            />
            {!isDeliveryInfoValid && !exceedTotalWeight && (
              <Alert
                variant="error"
                message="Remplissez votre adresse pour calculer le prix de livraison."
              />
            )}

            {exceedTotalWeight && (
              <Alert
                variant="error"
                message="Poids maximum (20kg) dépassé pour ce mode de livraison."
              />
            )}

            {selectedContactPoint &&
              selectedContactPoint.minimumDeliveryAmount &&
              selectedContactPoint.minimumDeliveryAmount > totalAmount && (
                <Alert
                  variant="error"
                  message={`Veuillez atteindre un montant de commande supérieur ou égal à ${selectedContactPoint.minimumDeliveryAmount} € pour pouvoir bénéficier de la livraison`}
                />
              )}
            {isDeliveryInfoValid && invalidRate && !isExternalShipping && (
              <Alert
                variant="error"
                message={
                  availableDates.length === 0
                    ? "Période d’activité du point de contact incompatible avec la date de disponibilité d'un produit de la commande"
                    : "Votre adresse est en dehors de la zone de livraison. Veuillez sélectionner une autre méthode pour récupérer vos produits."
                }
              />
            )}

            {!isExternalShipping &&
              availableDates &&
              isDeliveryInfoValid &&
              !invalidRate && (
                <>
                  <Spacer />

                  <Typography color="textSecondary" paragraph component="div">
                    <Box fontWeight={500} fontSize={14}>
                      3. Choisissez la date de livraison souhaitée
                    </Box>
                  </Typography>

                  <ContactPointDateField
                    contactPoint={contactPointInfo}
                    selectedDate={date}
                    availableDates={availableDates}
                    onChangeDate={handleChangeDate}
                  />
                </>
              )}
          </FlexItem>

          <FlexItem>
            <Typography color="textSecondary" paragraph component="div">
              <Box fontWeight={500} fontSize={14}>
                2. Choississez votre option de livraison
              </Box>
            </Typography>

            <ContactPointOptions
              selectedContactPoint={selectedContactPoint}
              onSelect={handleOnSelect}
              contactPointOptions={deliveryContactPoints}
              totalAmount={totalAmount}
              hasFreshProducts={hasFreshProducts}
              isDeliveryInfoValid={isDeliveryInfoValid}
            />

            {hasUndeliverableProducts && (
              <Alert
                variant="error"
                message={
                  undeliverableCount === 1
                    ? `Attention, ${names} est un produit indisponible à la livraison, afin de finaliser votre commande choisissez une autre option pour récupérer vos produits`
                    : `Attention, ${names} sont des produits indisponible à la livraison, afin de finaliser votre commande choisissez une autre option pour récupérer vos produits`
                }
              />
            )}
          </FlexItem>
        </DeliveryContainer>
      )}
      {hasTakeAwayCPs &&
        contactPointInfo.type === CommonTypes.ContactPointType.TAKE_AWAY && (
          <TakeAwayContainer>
            <FlexItem>
              <Typography color="textSecondary" paragraph component="div">
                <Box fontWeight={500} fontSize={14}>
                  1. Choississez votre option de retrait
                </Box>
              </Typography>

              <ContactPointOptions
                selectedContactPoint={selectedContactPoint}
                onSelect={handleOnSelect}
                contactPointOptions={takeAwayContactPoints}
                totalAmount={totalAmount}
                hasFreshProducts={hasFreshProducts}
                isDeliveryInfoValid={true} //always valid
              />

              {!isExternalShipping && availableDates && (
                <>
                  <Spacer />
                  <Typography color="textSecondary" paragraph component="div">
                    <Box fontWeight={500} fontSize={14}>
                      2. Choisissez la date et le créneau horaire du retrait
                      souhaité
                    </Box>
                  </Typography>

                  <ContactPointDateField
                    contactPoint={contactPointInfo}
                    selectedDate={date}
                    availableDates={availableDates}
                    onChangeDate={handleChangeDate}
                  />
                </>
              )}
            </FlexItem>

            <FlexItem>
              <ContactPointMap contactPoint={contactPointInfo} />
            </FlexItem>
          </TakeAwayContainer>
        )}
      <Spacer />
      {contactPointInfo.type !== CommonTypes.ContactPointType.TAKE_AWAY &&
        isDeliveryInfoValid &&
        !invalidRate && (
          <>
            <Spacer />

            <Typography color="textSecondary" paragraph component="div">
              <Box fontWeight={500} fontSize={14}>
                Information de la livraison
              </Box>
            </Typography>

            <DeliveryDateFrequency
              contactPoint={contactPointInfo}
              deliveryRate={selectedRate}
              relayPoint={currentRelay}
              availableDate={availableDate}
            />
          </>
        )}
      {isChronopostRelayOpen && (
        <ShippingRelayPoints
          address={clientAddress}
          handleClose={handleCloseChronopostRelay}
          onSelect={onSelect}
          currentRelay={currentRelay}
          isEmbedded={isEmbedded}
          type={contactPointInfo.type}
        />
      )}
    </>
  );
}

export default ContactPointMain;
