import {
  addDays,
  addMonths,
  differenceInDays,
  endOfDay,
  getDay,
  setHours,
  setMinutes,
  startOfDay,
  isSameDay,
  parseISO,
} from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import {
  calculateDeliveryRate,
  isCpChronopost,
  isCpDelivery,
  isCpRelay,
  isCPTakeaway,
  isCpExternalShipping,
  isValidDate,
  resetHour,
  isCpUps,
  isCpMondialRelay,
  checkIfWeightExceeded,
} from "./delivery";
import { getProductsForProducer } from "./cart";
import { CommonTypes } from "@kuupanda/kuu-contract";

export function getContactPointInfo(contactPoints: any, contactPoint: any) {
  return (
    contactPoints &&
    contactPoint &&
    contactPoints.find(
      (it: any) => it._id === contactPoint.id || it._id === contactPoint.value
    )
  );
}

const dayMapping = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

function getValidWeekDays(schedule = {}) {
  return Object.entries(schedule)
    .filter(([, value]: any) => value.values && value.values.length > 0)
    .map(([key]) => (dayMapping as any)[key]);
}

function getMinDate(
  contactPointInfo: any,
  preparationTime: number = 1,
  contactPoints: any,
  contactPoint: any
) {
  const defaultMinDate = addDays(new Date(), preparationTime);
  if (contactPointInfo || contactPoints) {
    const cpInfo =
      contactPointInfo || getContactPointInfo(contactPoints, contactPoint);
    const date =
      cpInfo &&
      cpInfo.activityPeriod &&
      cpInfo.activityPeriod.startDate &&
      new Date(cpInfo.activityPeriod.startDate);
    if (isValidDate(date)) {
      return defaultMinDate > date ? defaultMinDate : date;
    }
  }

  return defaultMinDate;
}

function getMaxDate(
  contactPointInfo: any,
  contactPoint: any,
  contactPoints: any
) {
  if (contactPointInfo || contactPoint) {
    const cpInfo =
      contactPointInfo || getContactPointInfo(contactPoints, contactPoint);
    const date =
      cpInfo &&
      cpInfo.activityPeriod &&
      cpInfo.activityPeriod.endDate &&
      new Date(cpInfo.activityPeriod.endDate);
    if (isValidDate(date)) {
      return endOfDay(date);
    }
  }

  return addMonths(endOfDay(new Date()), 10);
}

export function getAvailableDates(
  contactPointInfo: any,
  availableDate: any,
  actualPreparationTime: any,
  contactPoints: any,
  contactPoint: any
) {
  const minPreparationTime = Math.max(actualPreparationTime, 0);
  let preparationTime = minPreparationTime;
  const daysAvailableDate = differenceInDays(
    availableDate ? new Date(availableDate) : new Date(),
    startOfDay(new Date())
  );
  if (daysAvailableDate > minPreparationTime) {
    preparationTime = daysAvailableDate;
  }
  const startRange = getMinDate(
    contactPointInfo,
    preparationTime,
    contactPoints,
    contactPoint
  );
  const endRange = getMaxDate(contactPointInfo, contactPoint, contactPoints);
  if (endRange < startRange) {
    return [];
  }
  const dateRange = eachDayOfInterval({
    start: startRange,
    end: endRange,
  });
  const validWeekDays = getValidWeekDays(contactPointInfo.schedule);

  const availableRange: Date[] = [];

  for (const range of dateRange) {
    const found =
      contactPointInfo.customSchedule &&
      contactPointInfo.customSchedule.length > 0 &&
      contactPointInfo.customSchedule.find((cs: any) =>
        isSameDay(parseISO(cs.date), range)
      );

    // if its found, it's only available if we have values
    if (
      (found && found.values.length > 0) ||
      (!found && validWeekDays.includes(getDay(range)))
    ) {
      availableRange.push(range);
    }
  }

  return availableRange;
}

export function getContactPointAvailableDate({
  contactPoint,
  currentDate,
  contactPoints,
  availableDate,
  preparationTime,
}: {
  contactPoint: any;
  currentDate: Date | null;
  contactPoints: any;
  availableDate: Date;
  preparationTime: number;
}) {
  const contactPointInfo = getContactPointInfo(contactPoints, contactPoint);
  const availableDates = getAvailableDates(
    contactPointInfo,
    availableDate,
    preparationTime,
    contactPoints,
    contactPoint
  );

  let date = currentDate;

  if (availableDates.length === 0) {
    return null;
  }

  // we use getTime to avoid comparing date objects with includes
  // which compares by object reference and not value
  if (
    !date ||
    !availableDates
      .map((it) => it.getTime())
      .includes(startOfDay(date).getTime())
  ) {
    date = new Date(Math.min(...availableDates.map((d) => d.getTime())));
  }

  return date ? resetHour({ date, contactPoint: contactPointInfo }) : null;
}

export function calculateCPFees({
  contactPoints,
  contactPoint,
  vendorId,
  clientAddress,
  totalAmount,
  isSimplifiedStore,
  cart,
}: {
  contactPoints: any;
  contactPoint: any;
  vendorId: string;
  clientAddress: any;
  totalAmount: number;
  isSimplifiedStore: boolean;
  cart: any;
}) {
  const contactPointInfo = getContactPointInfo(contactPoints, contactPoint);

  if (!contactPointInfo) {
    return;
  }

  const isDelivery = isCpDelivery(contactPointInfo.type);

  const cartProducts = getProductsForProducer(
    cart,
    vendorId,
    isSimplifiedStore
  );

  if (!clientAddress.lat || !clientAddress.lng) {
    return;
  }

  let deliveryRate;

  if (isDelivery && clientAddress) {
    deliveryRate = calculateDeliveryRate({
      cartProducts,
      contactPointInfo,
      cartSubTotal: totalAmount,
      clientAddress,
    });
  }

  if (contactPointInfo.clientDeliveryAmount) {
    if (totalAmount >= contactPointInfo.clientDeliveryAmount.minAmount) {
      deliveryRate = contactPointInfo.clientDeliveryAmount.amount;
    }
  }

  if (contactPointInfo.clientDeliveryAmount && deliveryRate > 0) {
    if (totalAmount >= contactPointInfo.clientDeliveryAmount.minAmount) {
      deliveryRate = contactPointInfo.clientDeliveryAmount.amount;
    }
  }

  return deliveryRate;
}

export function isContactPointValid(
  contactPointInfo: any,
  availableDate: Date,
  preparationTime: number,
  contactPoints: any,
  contactPoint?: any
) {
  // available date not needed for external
  if (isCpExternalShipping(contactPointInfo?.type)) {
    return true;
  }

  return (
    getAvailableDates(
      contactPointInfo,
      availableDate,
      preparationTime,
      contactPoints,
      contactPoint
    ).length > 0
  );
}

export function checkIfIsSelectable(
  cp: any,
  hasFreshProducts: boolean,
  totalAmount: number,
  cart: any,
  producerId: string
) {
  //takeaway always selectable
  if (isCPTakeaway(cp.type)) return true;

  const isChronoPost = isCpChronopost(cp.type);

  const hasUnDeliverableProducts = checkHasUndeliverableProducts(cart, cp.type);
  if (hasUnDeliverableProducts) {
    return false;
  }

  if (
    isChronoPost &&
    !cp.modes.includes(CommonTypes.ChronopostModes.FRESH) &&
    hasFreshProducts
  ) {
    return false;
  }

  const exceedTotalWeight = checkIfWeightExceeded(cart, producerId, cp);
  if (exceedTotalWeight) {
    return false;
  }

  if (isCpRelay(cp.type) && exceedTotalWeight) {
    return false;
  }

  if (isCpUps(cp.type) && hasFreshProducts) {
    return false;
  }

  if (isCpMondialRelay(cp.type) && hasFreshProducts) {
    return false;
  }

  return !(cp.minimumDeliveryAmount && cp.minimumDeliveryAmount > totalAmount);
}

export function getMaxAvailableDate(cart: any) {
  const referenceDate = new Date();
  referenceDate.setHours(0, 0, 0, 0);

  return Object.values(cart).reduce(
    (maxAvailableDate: any, { product }: any) => {
      const productDate =
        product.availableDate && new Date(product.availableDate);

      return productDate && productDate > maxAvailableDate
        ? productDate
        : maxAvailableDate;
    },
    referenceDate
  );
}

export function getMaxPreparationTime(cart: any) {
  return Object.values(cart).reduce(
    (maxPreparationTime: any, { product }: any) =>
      product.preparationTime && product.preparationTime > maxPreparationTime
        ? product.preparationTime
        : maxPreparationTime,
    0
  );
}

export function checkHasUndeliverableProducts(cart: any, type: string) {
  return Object.keys(cart).some((it) =>
    cart[it].product.excludedContactPointTypes.includes(type)
  );
}

export function checkIfHasColdProducts(cart: any) {
  return Object.keys(cart).some((it) =>
    cart[it].product.storage.includes("COLD")
  );
}

export const isDateInRange = ({
  date,
  dates,
}: {
  date?: Date;
  dates: Date[];
}): boolean => {
  if (!date) {
    return false;
  }
  const found = dates.find((d) => {
    const newDate = setHours(setMinutes(date, 0), 0);
    return newDate.getTime() === d.getTime();
  });

  return !!found;
};

export const getProductNamesUndeliverableProducts = (
  cart: any,
  type: string
): string => {
  const names = [];

  for (const item of Object.keys(cart)) {
    if (cart[item].product.excludedContactPointTypes.includes(type)) {
      names.push(cart[item].product.name);
    }
  }

  return names.join(", ");
};

export const getUndeliverableCount = (cart: any, type: string): number => {
  let count = 0;

  for (const item of Object.keys(cart)) {
    if (cart[item].product.excludedContactPointTypes.includes(type)) {
      count++;
    }
  }

  return count;
};
