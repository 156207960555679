export const DEFAULT_CATEGORIES = [
  { value: "_AROMATIC_PLANTS", label: "Plantes aromatiques" },
  { value: "_ALGAE", label: "Algues" },
  { value: "_BAKERY", label: "Boulangerie/Patisserie" },
  { value: "_BEVERAGES", label: "Boissons" },
  { value: "_MEAT-AND-DELICATESSEN", label: "Boucherie/Charcuterie" },
  { value: "_BAKERY", label: "Boulangerie/Patisserie" },
  { value: "_COSMETICS", label: "Cosmétique et bien-être" },
  { value: "_SEEDLINGS", label: "Plants maraîchers" },
  { value: "_CEREALS-LEGUMES", label: "Céréales et légumineuses" },
  { value: "_SPICE", label: "Epices" },
  { value: "_EDIBLE-FLOWERS", label: "Fleurs comestibles" },
  { value: "_FRUITS-VEGETABLES", label: "Fruits/Légumes" },
  { value: "_DAIRY-PRODUCTS", label: "Produits laitiers" },
  { value: "_EGGS", label: "Oeufs" },
  { value: "_AROMATIC-PLANTS", label: "Plantes aromatiques" },
  { value: "_SEA-PRODUCTS", label: "Produits de la mer" },
  { value: "_BEE-PRODUCTS", label: "Produits de la ruche" },
  { value: "_TRANSFORMED-PRODUCTS", label: "Produits transformés" },
  { value: "_COMBO", label: "Panier composé" },
];

export const ALL_CATEGORIES = [
  ...DEFAULT_CATEGORIES,
  { value: "_FOOD", label: "Alimentaire" },
  { value: "_READY-TO-WEAR", label: "Prêt-à-porter" },
  { value: "_RESTAURATION", label: "Restauration" },
  { value: "_OTHER", label: "Autre" },
  { value: "_MULTIMEDIA", label: "Multimédia / Électroménager" },
  { value: "_ART-DECO", label: "Art et décoration" },
  { value: "_BOOK-GAME", label: "Librairie, jeux" },
  { value: "_JEWELLERY", label: "Bijouterie" },
  { value: "_CARE-COSMETICS", label: "Soin et bien être" },
  { value: "_FLORIST", label: "Fleuriste / Jardinerie" },
];

const constants: { [key: string]: any } = {
  DATE_FORMAT: "dd/MM/yyyy",
  DATE_TIME_FORMAT: "dd/MM/yyyy HH:mm",
  CONTACT_POINT_TYPE: {
    TAKE_AWAY: "TAKE_AWAY",
    DELIVERY: "DELIVERY",
    CHRONOPOST: "CHRONOPOST",
    CHRONOPOST_RELAY: "CHRONOPOST_RELAY",
    CHRONOPOST_VITI: "CHRONOPOST_VITI",
    LCS_AGENCY: "LCS_AGENCY",
    LCS_ASSOC: "LCS_ASSOC",
    CHRONOPOST_SHOP2SHOP: "CHRONOPOST_SHOP2SHOP",
    UPS: "UPS",
    MONDIAL_RELAY: "MONDIAL_RELAY",
  },
  FEES_TYPE: {
    DELIVERY: "DELIVERY",
  },
  STORE_TYPE: {
    PRODUCER: "producer",
    GROUP: "group",
  },
  ENVIRONMENT: {
    DEV: "development",
    PROD: "production",
  },
  STORAGE_TYPE: {
    STANDARD: "STANDARD",
    COLD: "COLD",
  },
  CHRONOPOST_TYPES: {
    CHRONOPOST: "CHRONOPOST",
    CHRONOPOST_RELAY: "CHRONOPOST_RELAY",
    CHRONOPOST_VITI: "CHRONOPOST_VITI",
    CHRONOPOST_SHOP2SHOP: "CHRONOPOST_SHOP2SHOP",
  },
  CHRONOPOST_MODES: { FRESH: "FRESH", DRY: "DRY" },
  SHIPPING_MAX_WEIGHTS: {
    CHRONOPOST: 30,
    CHRONOPOST_RELAY: 20,
    CHRONOPOST_SHOP2SHOP: 20,
    UPS: 70,
    MONDIAL_RELAY: 30,
  },
  DELIVERY_FREQUENCY: {
    EVERYDAY: "EVERYDAY",
    EVERY_TWO_DAYS: "EVERY_TWO_DAYS",
    TWICE_A_WEEK: "TWICE_A_WEEK",
    ONCE_A_WEEK: "ONCE_A_WEEK",
    THREE_WEEKS_AFTER: "THREE_WEEKS_AFTER",
  },
  COMPONENT: {
    STORE: "STORE",
    FORM: "FORM",
  },
  PRODUCT_ERRORS: {
    PRICE_CHANGE: "PRICE_CHANGE",
    UNAVAILABLE: "UNAVAILABLE",
    STOCK_CHANGE: "STOCK_CHANGE",
  },
  PREPARATION_TYPE: {
    SPECIFIC_DATE: "SPECIFIC_DATE",
    DELAY: "DELAY",
  },
  SALE_CHANNEL: {
    STANDARD: "STANDARD",
    PROFESSIONAL: "PROFESSIONAL",
  },
  PAYMENT_METHOD: {
    CHEQUE: "_cheque",
    CASH: "_cash",
    CREDIT_CARD: "_credit-card",
    TRANSFER: "_transfer",
    CREDIT_CARD_ON_SPOT: "_credit-card-on-spot",
  },
  PAYMENT_PROVIDER: {
    MANGOPAY: "MANGOPAY",
    STRIPE: "STRIPE",
  },
  CODE_EVALUATION_STATUS: {
    INVALID: "INVALID",
    VALID: "VALID",
    UNDETERMINED: "UNDETERMINED",
  },
  ORDER_ORIGIN: {
    ADMIN: "ADMIN",
    CLIENT: "CLIENT",
    FORM: "FORM",
  },
};

export default constants;
