import React, { useCallback, useEffect } from "react";

import { RiAddLine, RiSubtractLine } from "react-icons/ri";

import {
  BaseNumberInput,
  ButtonOnlyIcon,
  useIsMobile,
} from "@kuupanda/commons";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const Container = styled("div")({
  display: "flex",
  gap: 8,
});

const MobileContainer = styled("div")({
  display: "flex",
  gap: 8,
  flexDirection: "column",
  width: "100%",
});

const MinusIcon = styled(RiSubtractLine)({
  height: "20px !important",
  width: "20px !important",
});

const PlusIcon = styled(RiAddLine)({
  height: "20px !important",
  width: "20px !important",
});

const CenteredNumberInput = styled(BaseNumberInput)(({ theme }) => ({
  input: { textAlign: "center", width: 65 },
  width: 65,
  [theme.breakpoints.down("md")]: {
    flex: 1,
    width: "100%",
    input: { textAlign: "center", width: "100%" },
  },
}));

const StepperIcon = styled(ButtonOnlyIcon)(({ theme }) => ({
  width: 27,
  padding: "0px 4px",
  border: `2px solid ${theme.palette.grey["100"]}`,
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const QuantitySelector: React.FC<{
  className?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  allowNegative?: boolean;
  decimalScale?: number;
  onValidate: (quantity: any) => number;
}> = ({
  className,
  value,
  onChange,
  disabled,
  allowNegative = false,
  decimalScale,
  onValidate,
}) => {
  const isMobile = useIsMobile();
  const [quantity, setQuantity] = React.useState("");

  const add = useCallback(() => {
    const newValue = parseFloat(quantity) + 1;

    if (newValue <= 1000) {
      const salesQuantity = onValidate(newValue);
      setQuantity(salesQuantity.toFixed(2));
      onChange(salesQuantity.toFixed(2));
    }
  }, [onChange, onValidate, quantity]);

  const minus = useCallback(() => {
    const newValue = parseFloat(quantity) - 1;

    if (newValue >= 0) {
      const salesQuantity = onValidate(newValue);
      setQuantity(salesQuantity.toFixed(2));
      onChange(salesQuantity.toFixed(2));
    }
  }, [onChange, onValidate, quantity]);

  const onFocus = useCallback(
    (e: any) => {
      if (parseFloat(quantity) === 0) {
        setQuantity("");
        return;
      }

      if (decimalScale === 0) {
        e.target.select();
        return;
      }
    },
    [quantity, decimalScale]
  );

  const handleChange = useCallback((value: any) => {
    setQuantity(value);
  }, []);

  const onBlur = useCallback(() => {
    if (quantity === "") {
      //reset original value
      setQuantity(value);
      onChange(value);

      return;
    }

    const newValue = parseFloat(quantity);
    const salesQuantity = onValidate(newValue);

    setQuantity(salesQuantity.toFixed(2));
    onChange(salesQuantity.toFixed(2));
  }, [onChange, onValidate, quantity, value]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      setQuantity(value);
    }
  }, [value]);

  if (isMobile) {
    return (
      <MobileContainer className={className}>
        <CenteredNumberInput
          value={quantity}
          suffix={" "}
          fixedDecimalScale={decimalScale !== 0}
          allowNegative={allowNegative}
          onChange={handleChange}
          disabled={disabled}
          decimalScale={decimalScale}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <StepperIcon onClick={minus} disabled={disabled}>
            <MinusIcon />
          </StepperIcon>

          <StepperIcon onClick={add} disabled={disabled}>
            <PlusIcon />
          </StepperIcon>
        </Box>
      </MobileContainer>
    );
  }

  return (
    <Container className={className}>
      <StepperIcon onClick={minus} disabled={disabled}>
        <MinusIcon />
      </StepperIcon>

      <CenteredNumberInput
        value={quantity}
        suffix={" "}
        fixedDecimalScale={decimalScale !== 0}
        allowNegative={allowNegative}
        onChange={handleChange}
        disabled={disabled}
        decimalScale={decimalScale}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      <StepperIcon onClick={add} disabled={disabled}>
        <PlusIcon />
      </StepperIcon>
    </Container>
  );
};

export default QuantitySelector;
