import {
  format,
  isWithinInterval,
  setHours,
  setMinutes,
  eachDayOfInterval,
  addDays,
  isAfter,
} from "date-fns";
import { parseHourString } from "./misc";

export const isStoreOpen = (date: Date, schedule: any): boolean => {
  const dayOfWeek = format(date, "eeee").toLowerCase();

  if (
    !(
      schedule &&
      schedule[dayOfWeek] &&
      schedule[dayOfWeek].values &&
      schedule[dayOfWeek].values.length > 0
    )
  ) {
    return false;
  }

  //we are only use one period for now but this will handle unlimited periods
  return schedule[dayOfWeek].values.some(
    (range: { start: string; end: string }) => {
      const [startHour, startMinute] = parseHourString(range.start);
      const [endHour, endMinute] = parseHourString(range.end);

      const start = setHours(setMinutes(date, startMinute), startHour);
      const end = setHours(setMinutes(date, endMinute), endHour);

      return isWithinInterval(date, {
        start,
        end: endHour === 0 && endMinute === 0 ? addDays(end, 1) : end,
      });
    }
  );
};

export const getNextOpeningDate = (date: Date, schedule: any): Date => {
  const ranges = eachDayOfInterval({
    start: date,
    end: addDays(date, 7),
  });

  let openDate;
  for (const range of ranges) {
    const dayOfWeek = format(range, "eeee").toLowerCase();

    if (
      schedule &&
      schedule[dayOfWeek] &&
      schedule[dayOfWeek].values &&
      schedule[dayOfWeek].values.length > 0
    ) {
      for (const value of schedule[dayOfWeek].values) {
        const [startHour, startMinute] = parseHourString(value.start);

        const start = setHours(setMinutes(range, startMinute), startHour);

        if (isAfter(start, date)) {
          openDate = start;
          break;
        }
      }

      if (openDate) break;
    }
  }

  return openDate ? openDate : new Date();
};
