import React from "react";
import { Typography, Box } from "@mui/material";

import { styled } from "@mui/material/styles";
import { ReactComponent as Error404 } from "../../assets/Images/404.svg";
import { ReactComponent as Man } from "../../assets/Images/man.svg";

import { RiArrowLeftLine as BackIcon } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useIsMobile, Button } from "@kuupanda/commons";

const Container = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Content = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

const Error = styled(Error404, {
  shouldForwardProp: (prop: any) => prop !== "isMobile",
})<{ isMobile: boolean }>(
  ({ isMobile }) => `
  height: ${isMobile ? 150 : 275}px;
  width: ${isMobile ? 350 : 498}px;
`
);

const MessageBox = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ErrorBox = styled("div")`
  margin-bottom: 50px;
`;

const Title = styled(Typography, {
  shouldForwardProp: (prop: any) => prop !== "isMobile",
})<{ isMobile: boolean }>(
  ({ theme, isMobile }) => `
  color: ${theme.palette.others.g};
  font-size: ${isMobile ? 20 : 38}px;
  font-weight: bold;
  text-align: center;
`
);

const Subtitle = styled(Typography, {
  shouldForwardProp: (prop: any) => prop !== "isMobile",
})<{ isMobile: boolean }>(
  ({ theme, isMobile }) => `
   font-size: ${isMobile ? 16 : 20}px;
  color: ${theme.palette.grey[800]};
  text-align: center;
  word-wrap: break-word;
  width: ${isMobile ? 250 : 430}px;
`
);

const Text = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const ErrorPage = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MessageBox>
            <ErrorBox>
              <Error isMobile={isMobile} />
            </ErrorBox>
            <Text>
              <Title isMobile={isMobile}>Oups page non trouvée !</Title>
              <Subtitle isMobile={isMobile}>
                La page que vous souhaitez visiter n’existe pas ou a été
                supprimée
              </Subtitle>
            </Text>
          </MessageBox>
          {!isMobile && <Man />}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            startIcon={<BackIcon />}
            onClick={() => navigate("/")}
            href="https://www.kuupanda.com/"
          >
            Retour
          </Button>
        </Box>
      </Content>
    </Container>
  );
};

export default ErrorPage;
