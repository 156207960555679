import React from "react";
import { styled } from "@mui/material/styles";
import {
  isCPChronoPostRelay,
  isCPChronoPostViti,
  isCpMondialRelay,
  isCpShop2Shop,
  isCpUps,
} from "../helpers/delivery";

// Material Components
import { RadioGroup, FormControlLabel, Typography, Box } from "@mui/material";

import { Alert, BaseRadio } from "@kuupanda/commons";
import { TransporterLogo } from "./TransporterLogo";
import { CommonTypes } from "@kuupanda/kuu-contract";

// Styleds
const ContactPointOptionContainer = styled("div")<{ disabled: boolean }>(
  ({ disabled, theme }) => ({
    gap: 8,
    padding: 10,
    background: disabled ? theme.palette.grey[100] : "#ffffff",
    opacity: disabled ? 0.6 : 1,
    border: `2px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
  })
);

const StyledRadioGroup = styled(RadioGroup)`
  gap: 8px;
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.primary.dark};
  font-weight: 400;
  font-size: 12px;
`
);

const StyledTag = styled("div")<{ color: string }>(({ theme, color }) => ({
  boxSizing: "border-box",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "140%",
  backgroundColor: color ? color : theme.palette.grey[200],
  color: "#7AB0ED",
  borderRadius: "8px",
  padding: "5px 10px",
  display: "flex",
  gap: "5px",
  alignItems: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
}));

type ContactPointOptionsProps = {
  className?: string;
  contactPointOptions: any;
  selectedContactPoint: any;
  onSelect: any;
  hasFreshProducts: boolean;
  totalAmount: number;
  isDeliveryInfoValid: boolean;
};

const colorMap: { [key: string]: string } = {
  UPS: "#FFF4E2",
  MONDIAL_RELAY: "#FCEBED",
  CHRONOPOST: "#ebf4ff",
  CHRONOPOST_RELAY: "#ebf4ff",
  CHRONOPOST_SHOP2SHOP: "#ebf4ff",
  CHRONOPOST_VITI: "#ebf4ff",
};

export default function ContactPointOptions({
  contactPointOptions,
  selectedContactPoint,
  onSelect,
  totalAmount,
  className,
  hasFreshProducts,
}: ContactPointOptionsProps) {
  return (
    <StyledRadioGroup
      name={`contact_point`}
      onChange={onSelect}
      className={className}
      value={selectedContactPoint.value}
    >
      {contactPointOptions.map((cp: any) => {
        const isChronoRelay = isCPChronoPostRelay(cp.type);
        const isChronoViti = isCPChronoPostViti(cp.type);
        const isShop2Shop = isCpShop2Shop(cp.type);
        const isMondialRelay = isCpMondialRelay(cp.type);
        const isUPS = isCpUps(cp.type);

        const color = colorMap[cp.type];

        return (
          <ContactPointOptionContainer
            key={cp.value}
            disabled={!cp.isSelectable}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                id={`contact_point_${cp.value}`}
                value={cp.value}
                control={<BaseRadio />}
                disabled={!cp.isSelectable}
                label={
                  <>
                    <StyledTag color={color}>
                      <Typography variant="caption">{cp.label}</Typography>
                    </StyledTag>
                    {cp.type === CommonTypes.ContactPointType.TAKE_AWAY && (
                      <Label>{cp.address && cp.address.text}</Label>
                    )}
                  </>
                }
              />
              <TransporterLogo
                contactPoint={cp}
                hasFreshProducts={hasFreshProducts}
              />
            </Box>
            <Content>
              {cp.freeFeeOrderAmount && (
                <Typography variant="caption" color="primary.dark">
                  * Livraison gratuite à partir de {cp.freeFeeOrderAmount}€
                </Typography>
              )}

              {cp.minimumDeliveryAmount > totalAmount && (
                <Typography variant="caption" color="primary.dark">
                  * Livraison disponible à partir de {cp.minimumDeliveryAmount}€
                </Typography>
              )}

              {cp.comment && (
                <Typography color="textSecondary" variant="caption">
                  {cp.comment}
                </Typography>
              )}

              {(isChronoRelay || isChronoViti) &&
                !isShop2Shop &&
                hasFreshProducts && (
                  <Alert
                    variant="error"
                    message="* Les produits frais ne peuvent pas être livrés en point
                  relais"
                  />
                )}

              {isShop2Shop && hasFreshProducts && (
                <Alert
                  variant="error"
                  message="* Les produits frais ne peuvent pas être livrés avec Shop2Shop
                  relais"
                />
              )}

              {isMondialRelay && hasFreshProducts && (
                <Alert
                  variant="error"
                  message="* Les produits frais ne peuvent pas être avec Mondial Relay
                  relais"
                />
              )}

              {isUPS && hasFreshProducts && (
                <Alert
                  variant="error"
                  message="* Les produits frais ne peuvent pas être avec UPS"
                />
              )}
            </Content>
          </ContactPointOptionContainer>
        );
      })}
    </StyledRadioGroup>
  );
}
