import axios from "axios";
import mergeWith from "lodash/mergeWith";
import { storageBrandConfigItem } from "@kuupanda/commons";

const httpClient = axios.create();
httpClient.interceptors.request.use((resConfig: any) =>
  mergeWith(resConfig, {
    headers: { "x-brand-id": storageBrandConfigItem.jsonParsedValue?.id || "" },
  })
);

export default httpClient;
