import { useCallback, useRef } from "react";
import { debounce } from "lodash";

const useDebouncedCallback = (callback: any, delay: number) => {
  const callbackRef = useRef<any>();
  callbackRef.current = callback;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay),
    []
  );
};

export default useDebouncedCallback;
