import React from "react";
import { styled } from "@mui/material/styles";
import ResponsivePicture from "../../common/ResponsivePicture";
import Typography from "@mui/material/Typography";
import { Link, Avatar } from "@mui/material";
import { useIsMobile } from "@kuupanda/commons";
import { usePresentation } from "../../context/PresentationContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";

const Container = styled("div")(({ theme }) => ({
  background: "white",
  borderRadius: "8px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginTop: "24px",
  },
}));

const ProfilePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  width: 44px;
`;

const DescriptionBox = styled("div")(
  ({ theme }) => `
    background: ${theme.palette.grey[200]};
  border-radius: 6px;
  padding: 10px;
  color: ${theme.palette.others.a};
`
);

const LabelContainer = styled("div")({
  display: "flex",
  gap: 5,
});

const Label = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: "2px 4px",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  color: theme.palette.secondary.dark,
  fontSize: "14px",
}));

const Categories = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  font-size: 16px;
  font-weight: 500;
`
);

const LocationIcon = styled(FaMapMarkerAlt)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const PhoneIcon = styled(ImPhone)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const EmailIcon = styled(HiMail)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const Content = styled("div")(({ theme }) => ({
  padding: "24px 54px",
  [theme.breakpoints.down("md")]: {
    padding: "20px",
  },
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

const ProducerInfo = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Info = styled("div")(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${theme.palette.primary.dark};
`
);

const Spacer = styled("div")`
  height: 24px;
`;

const FarmPicture = styled(ResponsivePicture, {
  shouldForwardProp: (prop: string) => prop !== "hideInfo",
})<{ hideInfo: boolean }>(({ theme, hideInfo }) => ({
  borderRadius: hideInfo ? "6px" : "6px 6px 0 0",
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));

const EmbeddedSimplifiedHeader: React.FC<{
  categories: { value: string; label: string }[];
  name: string;
  producer: any;
  description: string;
  phone?: string;
  email?: string;
  bannerUrl?: string;
  profilePicture?: string;
  address?: string;
  labels: string[];
  hideNameOnForm: boolean;
  hideLocationOnForm: boolean;
}> = ({
  categories,
  name,
  producer,
  description,
  phone,
  email,
  bannerUrl = "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/user/farm/48db9a161bdd1e9b1e03063bb1a6fd68cd2cd7fd92fa433d05017064ef32ac2f.png",
  profilePicture,
  address,
  labels,
  hideNameOnForm,
  hideLocationOnForm,
}) => {
  const isMobile = useIsMobile();

  const {
    presentationOptions: { hideCover, hideInfo },
  } = usePresentation();

  if (hideCover && hideInfo) {
    return null;
  }

  return (
    <>
      <Container>
        {!hideCover && (
          <FarmPicture
            height={isMobile ? 100 : 250}
            sizeQuery="(min-width: 750px) 60vw, (max-width: 749px) 95vw"
            baseImgUrl={bannerUrl}
            fitType="cover"
            containerStyle={{}}
            alt={name}
            className=""
            hideInfo={hideInfo}
          />
        )}

        {!hideInfo && (
          <Content>
            <Typography fontWeight={700} variant={"h2"}>
              {name}
            </Typography>
            <LabelContainer>
              {labels.map((label, index) => (
                <Label key={index}>{label}</Label>
              ))}
            </LabelContainer>

            <Categories>
              {categories.map((category, index) => (
                <>
                  <Link
                    color={"primary.dark"}
                    href={`#cat${category.value}`}
                    title={category.label}
                    underline={"hover"}
                  >
                    {category.label}
                  </Link>
                  {index < categories.length - 1 && ", "}
                </>
              ))}
            </Categories>

            <ProducerInfo>
              <Info>
                {profilePicture && (
                  <Avatar alt={name}>
                    <ProfilePicture
                      containerStyle={{ width: "auto" }}
                      height={44}
                      sizeQuery={"44px"}
                      baseImgUrl={profilePicture}
                    />
                  </Avatar>
                )}
                {producer !== "" && !hideNameOnForm && (
                  <Typography fontWeight={500}>{producer}</Typography>
                )}
              </Info>

              {address && !hideLocationOnForm && (
                <Info>
                  <LocationIcon />
                  <Typography color="inherit">{address}</Typography>
                </Info>
              )}

              {phone && (
                <Info>
                  <PhoneIcon />

                  <Link color="inherit" href={`tel:${phone}`} underline="none">
                    {phone}
                  </Link>
                </Info>
              )}

              {email && (
                <Info>
                  <EmailIcon />

                  <Link
                    color="inherit"
                    href={`mailto:${email}?subject=[Kuupanda]%20`}
                    underline="none"
                  >
                    {email}
                  </Link>
                </Info>
              )}
            </ProducerInfo>

            <DescriptionBox dangerouslySetInnerHTML={{ __html: description }} />
          </Content>
        )}
      </Container>

      {!hideInfo && !hideCover && <Spacer />}
    </>
  );
};

export default EmbeddedSimplifiedHeader;
