import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import AlcoholImage from "../assets/Images/alcohol.png";
import { Button } from "@kuupanda/commons";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 715px;
    height: 435px;
    align-items: center !important;
    padding: 50px 10px 20px 10px !important;
    box-sizing: border-box !important;
    position: relative;

    @media (max-width: 700px) {
      min-width: 350px;
      height: 490px;
    }
  }
`;

const StyledImage = styled("img")`
  width: 155px;
  height: 60px;
  padding: 5px;
`;

const StyledCloseWrapper = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.grey[300]};
  cursor: pointer;
  position: absolute;
  left: 670px;
  padding: 5px;
  top: 0;
  .svg {
    width: 10px !important;
    height: 10px !important;
  }

  @media (max-width: 700px) {
    left: 300px;
  }
`
);

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 24px;
  width: 430px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  padding: 0 !important;

  @media (max-width: 700px) {
    width: 300px;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  width: 430px;
  padding: 15px;
  @media (max-width: 700px) {
    width: 300px;
  }
`;

const StyledTextContent = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
`;

const StyledConfirmButton = styled(Button)(
  ({ theme }) => `
  text-transform: capitalize;
  font-size: 16px;
  padding: 5px 10px;
  height: 38px;
  background-color: ${theme.palette.error.main};
`
);

const StyledCancelButton = styled(Button)`
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 100;
  padding: 5px 10px;
  height: 38px;
  color: black;
  border: none;
  :hover {
    border: none;
  }
`;

interface AgeConfirmationModalProps {
  handleConfirmation: (a: boolean) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
}

const AgeConfirmationModal = (props: AgeConfirmationModalProps) => {
  return (
    <StyledDialog onClose={props.handleCloseModal} open={props.isOpen}>
      <StyledCloseWrapper onClick={props.handleCloseModal}>
        <CloseIcon />
      </StyledCloseWrapper>
      <StyledImage src={AlcoholImage} alt={"vent alcool mineur"} />
      <StyledDialogTitle>
        Votre panier contient des produits dont la vente est réglementée.
      </StyledDialogTitle>

      <StyledDialogContent>
        <StyledTextContent>
          Depuis la loi n°2009-87 du 21 juillet 2009 portant réforme de
          l'hôpital et relative aux patients, à la santé et aux territoires, la
          vente de boissons alcooliques aux mineurs est interdite.
        </StyledTextContent>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledConfirmButton
          onClick={() => props.handleConfirmation(true)}
          color={"primary"}
        >
          Je certifie avoir 18 ans ou plus
        </StyledConfirmButton>
        <StyledCancelButton onClick={() => props.handleConfirmation(false)}>
          Je n’ai pas 18 ans ou plus
        </StyledCancelButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};
export default AgeConfirmationModal;
