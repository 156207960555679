import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import constants from "../constants";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import CartSummary from "../common/CartSummary";
import { NumberFormatCustom } from "@kuupanda/commons";
import { RiInformationLine } from "react-icons/ri";
import { getProductPrice } from "../helpers/product";

const TotalGrid = styled(Grid)`
  margin-top: 10px;
  padding-top: 10px;
  font-weight: 600;
`;

const BorderStyled = styled("div")(
  ({ theme }) => `
  border-top: 1px solid ${theme.palette.grey["200"]};
`
);

const LearnMore = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: 14,
}));

export const Totals: React.FC<{
  subTotalBeforeDiscount: any;
  deliveryRate: any;
  deliveryRateDt: any;
  total: any;
  subTotal: any;
  saleChannel: any;
  taxes: any;
  couponLinesTotal: any;
  hasVariableWeightProducts: boolean;
  priceAdjustment: number;
  variableWeightProducts: any[];
  onVariableWeightClick: () => void;
  variableWeightPercent: number;
}> = ({
  subTotalBeforeDiscount,
  deliveryRate,
  deliveryRateDt,
  total,
  subTotal,
  saleChannel,
  taxes,
  couponLinesTotal,
  hasVariableWeightProducts,
  priceAdjustment,
  variableWeightProducts,
  onVariableWeightClick,
  variableWeightPercent,
}) => {
  return (
    <TotalGrid container rowSpacing={1}>
      <Grid item xs={8}>
        <Typography variant="caption">
          Sous-total{" "}
          {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Typography variant="caption">
          <NumberFormatCustom
            value={subTotalBeforeDiscount}
            displayType={"text"}
          />
        </Typography>
      </Grid>
      {deliveryRate !== undefined && deliveryRate > 0 && (
        <React.Fragment>
          <Grid item xs={8}>
            <Typography variant="caption">
              Livraison{" "}
              {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="caption">
              <NumberFormatCustom
                value={
                  (saleChannel === constants.SALE_CHANNEL.PROFESSIONAL &&
                    deliveryRateDt) ||
                  deliveryRate
                }
                displayType={"text"}
              />
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      {couponLinesTotal !== undefined && couponLinesTotal > 0 && (
        <React.Fragment>
          <Grid item xs={12}>
            <BorderStyled />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="caption">Remise TTC</Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="caption">
              -
              <NumberFormatCustom
                value={couponLinesTotal}
                displayType={"text"}
              />
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
        <React.Fragment>
          <Grid item xs={12}>
            <BorderStyled />
          </Grid>{" "}
          <Grid item xs={8}>
            <Typography variant="caption">
              Total{" "}
              {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && "HT"}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="caption">
              <NumberFormatCustom value={subTotal} displayType={"text"} />
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
        <React.Fragment>
          <Grid item xs={8}>
            <Typography variant="caption">TVA</Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="caption">
              <NumberFormatCustom value={taxes} displayType={"text"} />
            </Typography>
          </Grid>
        </React.Fragment>
      )}

      {hasVariableWeightProducts && priceAdjustment > 0 && (
        <React.Fragment>
          <Grid item xs={8}>
            <Typography variant="caption">
              Majoration temporaire {variableWeightPercent * 100}% TTC{" "}
              <Tooltip
                disableFocusListener
                enterTouchDelay={0}
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    <span>
                      Produit(s) concerné(s) : <br />
                      <br />
                    </span>
                    {variableWeightProducts.map((product: any) => (
                      <span>
                        {product.name}
                        <br />
                      </span>
                    ))}
                    <br />
                    <LearnMore onClick={onVariableWeightClick}>
                      En savoir plus
                    </LearnMore>
                  </span>
                }
              >
                <IconButton aria-label="help" size="small">
                  <RiInformationLine />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Typography variant="caption">
              <NumberFormatCustom
                value={priceAdjustment}
                displayType={"text"}
              />
            </Typography>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={8}>
        <Typography variant="body1">Total TTC</Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <NumberFormatCustom value={total} displayType={"text"} />
      </Grid>
    </TotalGrid>
  );
};

const SimplifiedOrderSummary: React.FC<{
  deliveryRate: any;
  cart: any;
  saleChannel: any;
  onChangeCart?: any;
  applyCoupons: any;
  couponLines: any;
  invalidDiscounts: any[];
  initialCodes: string[];
  codeEvaluationStatus: any;
  promoEnabled?: boolean;
  variableWeightProducts: any[];
  paymentMethod: string;
  onVariableWeightClick: () => void;
  onValidateCart: any;
  className?: string;
  variableWeightPercent: number;
  excludeDeliveryVAT?: boolean;
}> = ({
  deliveryRate,
  cart,
  saleChannel,
  onChangeCart,
  applyCoupons,
  couponLines = [],
  invalidDiscounts,
  initialCodes,
  codeEvaluationStatus,
  promoEnabled = true,
  variableWeightProducts,
  paymentMethod,
  onVariableWeightClick,
  onValidateCart,
  className,
  variableWeightPercent,
  excludeDeliveryVAT,
}) => {
  const hasVariableWeightProducts = useMemo(
    () => variableWeightProducts.length > 0,
    [variableWeightProducts]
  );

  const isCartEmpty = useMemo(
    () => !cart || Object.values(cart).length === 0,
    [cart]
  );

  const couponLinesTotal = useMemo(
    () => couponLines.reduce((acc: number, cl: any) => acc + cl.discount, 0),
    [couponLines]
  );

  const deliveryFee = deliveryRate !== undefined ? deliveryRate : 0;

  //Free tax delivery fee
  const deliveryRateDt = excludeDeliveryVAT ? deliveryFee : deliveryFee / 1.2;

  const subTotalBeforeDiscount = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc: number, { quantity, product }: any) =>
          acc + quantity * product.price,
        0
      ),
    [cart]
  );

  const productTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc: number, { quantity, product }: any) =>
          acc + quantity * product.price,
        0
      ),
    [cart]
  );

  const productTotalVat = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc: number, { quantity, product }: any) =>
          acc + quantity * (product.price * (1 + product.vat)),
        0
      ),
    [cart]
  );

  const totalBeforeDiscount =
    productTotal + deliveryFee > 0 ? productTotal + deliveryFee : 0;

  const deliveryFeeSplit = excludeDeliveryVAT
    ? deliveryFee - (deliveryFee / totalBeforeDiscount) * couponLinesTotal
    : (deliveryFee - (deliveryFee / totalBeforeDiscount) * couponLinesTotal) /
      1.2;

  const subTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc: number, { quantity, product }: any) =>
          acc +
          (quantity * product.price -
            ((quantity * product.price) / totalBeforeDiscount) *
              couponLinesTotal),
        0
      ) + deliveryFeeSplit,
    [cart, totalBeforeDiscount, couponLinesTotal, deliveryFeeSplit]
  );

  const priceAdjustment =
    hasVariableWeightProducts &&
    paymentMethod === constants.PAYMENT_METHOD.CREDIT_CARD
      ? Object.values(cart)
          .filter((it: any) => it.product.sellByVariableWeight)
          .reduce(
            (acc: number, { quantity, product }: any) =>
              acc +
              quantity *
                getProductPrice(product, saleChannel) *
                variableWeightPercent,
            0
          )
      : 0;

  const productTotalToUse =
    saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
      ? productTotalVat
      : productTotal;
  const totalBeforeAdjustment =
    productTotalToUse + deliveryFee - couponLinesTotal > 0
      ? productTotalToUse + deliveryFee - couponLinesTotal
      : 0;

  const taxes = totalBeforeAdjustment - subTotal;

  const total = totalBeforeAdjustment + priceAdjustment;

  const applyPromo = (coupon: string) => {
    applyCoupons(coupon.split(","));
  };

  const clearPromo = () => {
    applyCoupons([]);
  };

  if (isCartEmpty) {
    return <Typography variant={"caption"}>Votre panier est vide</Typography>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Box className={className}>
        <CartSummary
          showTotal={false}
          applyPromo={applyPromo}
          clearPromo={clearPromo}
          invalidDiscounts={invalidDiscounts}
          codeEvaluationStatus={codeEvaluationStatus}
          initialCodes={initialCodes}
          applyCoupons={applyCoupons}
          promoEnabled={promoEnabled}
          onChangeCart={onChangeCart}
          cart={cart}
          total={total}
          cartTotal={productTotalToUse}
          onValidateCart={onValidateCart}
        />
      </Box>
      <Totals
        deliveryRate={deliveryRate}
        deliveryRateDt={deliveryRateDt}
        subTotalBeforeDiscount={subTotalBeforeDiscount}
        subTotal={subTotal}
        couponLinesTotal={couponLinesTotal}
        total={total}
        taxes={taxes}
        saleChannel={saleChannel}
        hasVariableWeightProducts={hasVariableWeightProducts}
        priceAdjustment={priceAdjustment}
        variableWeightProducts={variableWeightProducts}
        onVariableWeightClick={onVariableWeightClick}
        variableWeightPercent={variableWeightPercent}
      />
    </Box>
  );
};

export default SimplifiedOrderSummary;
