import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import httpClient from "../helpers/httpClient";
import constants from "../constants";
import qs from "qs";

const dataFetchReducer = (state: any, action: any) => {
  const { payload, ...extraParams } = action;
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        codesEvaluated: false,
        isLoading: true,
        isError: false,
        ...extraParams,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        codesEvaluated: true,
        isLoading: false,
        isError: false,
        data: payload,
        ...extraParams,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        codesEvaluated: false,
        isLoading: false,
        isError: true,
        ...extraParams,
      };
    default:
      throw new Error();
  }
};
let firstLoad = true;

const useDataApi = (
  initialProducerId: string,
  initialData: any,
  apiRoot: string,
  initialSaleChannel: string,
  isGroup: boolean,
  initialCodes: string[],
  initialCartAmount: number,
  isSimplifiedStore: boolean,
  initialStoreFrontSlug?: string
) => {
  const [producerId, setProducer] = useState(initialProducerId);
  const [storeFrontConfigSlug, setStoreFrontConfigSlug] = useState(
    initialStoreFrontSlug
  );

  const [codes, setCodes] = useState(initialCodes);
  const [cartAmount, setCartAmount] = useState(initialCartAmount);

  const [customer, setCustomer] = useState("");
  const [cartProducts, setCartProducts] = useState<Record<string, number>>({});

  const [state, dispatch] = useReducer(dataFetchReducer, {
    codesEvaluated: false,
    isLoading: true,
    isError: false,
    data: initialData,
  });

  const buildUrls = ({
    isGroup,
    producerId,
    initialSaleChannel,
    isSimplifiedStore,
    storeFrontConfigSlug,
  }: {
    isGroup: boolean;
    producerId: string;
    initialSaleChannel: string;
    isSimplifiedStore: boolean;
    storeFrontConfigSlug?: string;
  }) => {
    let productURL = isGroup
      ? `${apiRoot}/product/productGroupBySlug/${producerId}`
      : `${apiRoot}/product/productBySlug/${producerId}`;

    let storeURL = isGroup
      ? `${apiRoot}/group/groupBySlug/${producerId}`
      : `${apiRoot}/user/userBySlug/${producerId}`;

    if (isSimplifiedStore) {
      productURL = isGroup
        ? `${apiRoot}/product/clientGroupList/${producerId}`
        : `${apiRoot}/product/clientList/${producerId}`;

      storeURL = isGroup
        ? `${apiRoot}/group/clientList/${producerId}`
        : `${apiRoot}/user/clientList/${producerId}`;
    }

    const queryString = qs.stringify({
      saleChannel: initialSaleChannel,
      component: isSimplifiedStore
        ? constants.COMPONENT.FORM
        : constants.COMPONENT.STORE,
      storeFrontConfigSlug,
    });

    productURL = `${productURL}?${queryString}`;
    storeURL = `${storeURL}?${queryString}`;

    return [productURL, storeURL];
  };

  const [productURL, storeURL] = buildUrls({
    isGroup,
    producerId,
    initialSaleChannel,
    isSimplifiedStore,
    storeFrontConfigSlug,
  });

  const fetchData = useMemo(
    () =>
      async ({ didCancel = false }) => {
        dispatch({ type: "FETCH_INIT", isLoading: firstLoad });

        try {
          const [productResult, producerResult] = await Promise.all([
            httpClient.post(productURL, {
              codes,
              cartAmount,
              customer,
              cartProducts,
            }),
            httpClient(storeURL),
          ]);

          if (!didCancel) {
            dispatch({
              type: "FETCH_SUCCESS",
              payload: {
                producer: producerResult.data,
                products: productResult.data.products,
                validCodes: productResult.data.codes,
                couponLines: productResult.data.couponLines,
                invalidDiscounts:
                  productResult.data &&
                  productResult.data.invalidDiscounts &&
                  productResult.data.invalidDiscounts.length > 0
                    ? productResult.data.invalidDiscounts
                    : [],
              },
              codesEvaluated: Boolean(codes && codes.length > 0),
            });
            firstLoad = false;
          }
        } catch (error) {
          console.log(error);
          if (!didCancel) {
            dispatch({ type: "FETCH_FAILURE" });
          }
        }
      },
    [productURL, codes, cartAmount, customer, cartProducts, storeURL]
  );

  const reload = useCallback(() => fetchData({}), [fetchData]);

  useEffect(() => {
    let didCancel = false;
    fetchData({ didCancel });

    return () => {
      didCancel = true;
    };
  }, [
    producerId,
    apiRoot,
    initialSaleChannel,
    isGroup,
    codes,
    cartAmount,
    customer,
    fetchData,
    isSimplifiedStore,
    storeFrontConfigSlug,
  ]);

  return {
    state,
    codes,
    setCodes,
    setCartAmount,
    setCustomer,
    setCartProducts,
    setProducer,
    reload,
    setStoreFrontConfigSlug,
  };
};

export default useDataApi;
