import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Theme, ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";

// Components
import SimplifiedStore from "./components";

import { useBrandTheme } from "@kuupanda/commons";
// Contexts
import { ApiProvider } from "./context/ApiContext";
import { ConfigProvider } from "./context/ConfigContext";
import { PresentationProvider } from "./context/PresentationContext";
import ErrorPage from "./components/ErrorPage";
import { CustomThemeContext } from "./context/CustomTheme";
import mergeWith from "lodash/mergeWith";
import OrderPayment from "./components/OrderPayment";
import OrderPaymentComplete from "./components/OrderPayment/Complete";
import { Toaster } from "react-hot-toast";

const getFormSlug = () => {
  const matches = window.location.pathname.match(
    /(producteur|group|producer)\/(\d+)\/(particulier|part|pro)(\/boutique\/(\w+)?)?/
  );
  if (matches && matches.length > 0) {
    return matches
      .slice(1)
      .filter((match: string) => match)
      .join("-");
  }
  return null;
};
function App() {
  const { theme: brandTheme } = useBrandTheme();

  const slug = getFormSlug();
  const [customTheme, setCustomTheme] = useState<Partial<Theme>>({});

  const colorMode = useMemo(
    () => ({
      updateTheme: (customTheme: any) => {
        if (!slug) return;
        setCustomTheme(customTheme);
        localStorage.setItem(
          `${slug}-custom-theme`,
          JSON.stringify(customTheme)
        );
      },
    }),
    [slug]
  );

  useEffect(() => {
    if (slug) {
      const customTheme = localStorage.getItem(`${slug}-custom-theme`);
      if (customTheme) {
        setCustomTheme(JSON.parse(customTheme));
      }
    }
  }, [slug]);

  const theme = useMemo(
    () => mergeWith(brandTheme, customTheme),
    [brandTheme, customTheme]
  );

  const successColor = useMemo(() => theme.palette.success.main, [theme]);
  const errorColor = useMemo(() => theme.palette.error.main, [theme]);

  return (
    <HelmetProvider>
      <PresentationProvider>
        <CustomThemeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <ApiProvider>
              <ConfigProvider>
                <Router>
                  <Routes>
                    <Route
                      path=":storeType/:producerId/:saleChannelRaw/*"
                      element={<SimplifiedStore />}
                    />

                    <Route
                      path=":storeType/:producerId/:saleChannelRaw/boutique/:storeFrontSlug/*"
                      element={<SimplifiedStore />}
                    />

                    <Route
                      path="/order-payment/:orderId"
                      element={<OrderPayment />}
                    />

                    <Route
                      path="/order-payment/:orderId/complete"
                      element={<OrderPaymentComplete />}
                    />

                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </Router>
              </ConfigProvider>
            </ApiProvider>

            <Toaster
              toastOptions={{
                style: {
                  maxWidth: 800,
                  border: "2px solid #eff0f7",
                  boxShadow: "none",
                },
                success: {
                  iconTheme: {
                    primary: successColor,
                    secondary: "#ffffff",
                  },
                },
                error: {
                  iconTheme: {
                    primary: errorColor,
                    secondary: "#ffffff",
                  },
                },
                duration: 6000,
              }}
            />
          </ThemeProvider>
        </CustomThemeContext.Provider>
      </PresentationProvider>
    </HelmetProvider>
  );
}

export default App;
