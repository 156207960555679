import React, { useMemo } from "react";
import { BaseSelect } from "@kuupanda/commons";
import { getProductName, isComboMultiple } from "../helpers/product";

export const ComboSelection: React.FC<{
  product: any;
  onChange: (value: string) => void;
  value: string | string[];
  disabled: boolean;
}> = ({ value, onChange, product, disabled }) => {
  const { childProducts } = product;

  const isMultiple = isComboMultiple(product);

  const options = useMemo(
    () =>
      childProducts.map((childProduct: any) => ({
        value: childProduct.id,
        label: `${getProductName(childProduct)} x ${childProduct.quantity}`,
      })),
    [childProducts]
  );

  const renderValue = (selected: any) => {
    if (isMultiple) {
      return selected
        ?.map((key: string) => options.find((c: any) => c.value === key)?.label)
        .join(", ");
    }

    return options.find((c: any) => c.value === selected)?.label;
  };

  return (
    <BaseSelect
      multiple={isMultiple}
      name="comboOptions"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      options={options}
      renderValue={renderValue}
      disabled={disabled}
    />
  );
};
