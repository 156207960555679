import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAllContactPointFromLocalStorage,
  getClientAddressFromLocalStorage,
  getCustomerInfoFromLocalStorage,
  storeContactPointInLocalStorage,
  storeCustomerInfoInLocalStorage,
  storeClientAddressInLocalStorage,
} from "../helpers/LocalStorageRepository";
import { CommonTypes } from "@kuupanda/kuu-contract";
import constants from "../constants";

export const useFormInfo = (
  vendorId: string,
  contactPoints: any[],
  saleChannel: string
) => {
  const [loaded, setLoaded] = useState(false);
  const [formInfo, setFormInfo] = useState<{ [key: string]: any }>({
    customerInfo: {},
    shipmentInfo: null,
    contactPoint: null,
    clientAddress: {},
    deliveryRate: 0,
    orderDueTo: null,
    billingAddress: undefined,
    deliveryType: null,
  });

  const restoreInfo = useCallback(() => {
    let savedInfo: { [key: string]: any } = {};

    const address = getClientAddressFromLocalStorage(vendorId);
    if (address) {
      savedInfo.clientAddress = address;
    }

    const contactPoint = getAllContactPointFromLocalStorage();

    if (contactPoint) {
      savedInfo.contactPoint = contactPoint[vendorId];
    }

    const customerInfo = getCustomerInfoFromLocalStorage(vendorId);

    if (customerInfo) {
      if (saleChannel === constants.SALE_CHANNEL.STANDARD) {
        delete customerInfo.company;
      }
      savedInfo.customerInfo = customerInfo;
    }

    setFormInfo((prevFromInfo: any) => ({ ...prevFromInfo, ...savedInfo }));
    setLoaded(true);
  }, [vendorId, saleChannel]);

  useEffect(() => {
    restoreInfo();
  }, [restoreInfo]);

  const saveInfo = useCallback(() => {
    if (!loaded) return;

    for (const key of Object.keys(formInfo)) {
      if (formInfo[key]) {
        if (key === "contactPoint") {
          storeContactPointInLocalStorage(formInfo[key], vendorId);
        }

        if (key === "customerInfo") {
          storeCustomerInfoInLocalStorage(formInfo[key], vendorId);
        }

        if (key === "clientAddress") {
          storeClientAddressInLocalStorage(formInfo[key], vendorId);
        }
      }
    }
  }, [vendorId, formInfo, loaded]);

  useEffect(() => {
    saveInfo();
  }, [saveInfo]);

  const isFormInvalid = useMemo(() => {
    return (
      Object.keys(formInfo.customerInfo).length === 0 ||
      (Object.keys(formInfo.clientAddress).length === 0 &&
        formInfo.deliveryType !== CommonTypes.ContactPointType.TAKE_AWAY) ||
      !formInfo.contactPoint
    );
  }, [
    formInfo.clientAddress,
    formInfo.contactPoint,
    formInfo.customerInfo,
    formInfo.deliveryType,
  ]);

  return { formInfo, setFormInfo, isFormInvalid, loadedRecovery: loaded };
};
