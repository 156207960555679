import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SimplifiedOrderSummary from "./SimplifiedOrderSummary";
import PaymentForm from "../common/Payment/PaymentForm";
import { getVariableWeightProducts } from "../helpers/cart";

const Container = styled("div")`
  background: white;
  border-radius: 8px;
  width: 100%;
`;

const Content = styled("div")(({ theme }) => ({
  padding: "16px 16px",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
  },
}));
const Spacer = styled("div")`
  height: 24px;
`;

const Info = styled("div")`
  margin-top: 20px;
`;

const VariableWeightNotice = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("md")]: {
    justifyContent: "flex-start",
  },
}));

const SimplifiedPaymentForm: React.FC<{
  cart: any;
  onChangeCart: any;
  deliveryRate: any;
  applyCoupons: any;
  couponLines: any;
  saleChannel: any;
  paymentMethods: any;
  onChangePaymentType: any;
  validCodes: any[];
  invalidDiscounts: any[];
  paymentMethod: any;
  codes: any;
  codeEvaluationStatus: any;
  initialCodes: string[];
  handleChangeBillingAddressInfo: any;
  billingAddress: any;
  iban?: string;
  store: any;
  onVariableWeightClick: () => void;
  onValidateCart: any;
  variableWeightPercent: number;
  excludeDeliveryVAT?: boolean;
}> = ({
  cart,
  onChangeCart,
  deliveryRate,
  applyCoupons,
  couponLines,
  saleChannel,
  paymentMethods,
  onChangePaymentType,
  invalidDiscounts,
  paymentMethod,
  codeEvaluationStatus,
  initialCodes,
  handleChangeBillingAddressInfo,
  billingAddress,
  iban,
  store,
  onVariableWeightClick,
  onValidateCart,
  variableWeightPercent,
  excludeDeliveryVAT,
}) => {
  const variableWeightProducts = useMemo(
    () => getVariableWeightProducts(cart),
    [cart]
  );

  const hasVariableWeightProducts = useMemo(
    () => variableWeightProducts.length > 0,
    [variableWeightProducts]
  );

  return (
    <Container id="payment">
      <Content>
        <Typography fontSize={22}>Récapitulatif de la commande</Typography>

        <SimplifiedOrderSummary
          cart={cart}
          deliveryRate={deliveryRate}
          saleChannel={saleChannel}
          onChangeCart={onChangeCart}
          applyCoupons={applyCoupons}
          couponLines={couponLines}
          invalidDiscounts={invalidDiscounts}
          codeEvaluationStatus={codeEvaluationStatus}
          initialCodes={initialCodes}
          promoEnabled
          variableWeightProducts={variableWeightProducts}
          paymentMethod={paymentMethod}
          onVariableWeightClick={onVariableWeightClick}
          onValidateCart={onValidateCart}
          variableWeightPercent={variableWeightPercent}
          excludeDeliveryVAT={excludeDeliveryVAT}
        />

        <Spacer />

        {hasVariableWeightProducts && (
          <VariableWeightNotice>
            <Typography variant={"caption"}>
              Montant final susceptible d’être ajusté par le producteur
            </Typography>
          </VariableWeightNotice>
        )}
        <Spacer />

        <Info>
          <PaymentForm
            paymentMethod={paymentMethod}
            paymentMethods={paymentMethods}
            handlePaymentMethodChange={onChangePaymentType}
            handleChangeBillingAddressInfo={handleChangeBillingAddressInfo}
            saleChannel={saleChannel}
            billingAddress={billingAddress}
            iban={iban}
            store={store}
            variableWeightProducts={variableWeightProducts}
            onVariableWeightClick={onVariableWeightClick}
            variableWeightPercent={variableWeightPercent}
          />
        </Info>
      </Content>
    </Container>
  );
};

export default SimplifiedPaymentForm;
