import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  brandConfigSetup,
  BrandConfigProvider,
  PageLoader,
  createAppTheme,
} from "@kuupanda/commons";
import * as Sentry from "@sentry/react";

const calculateInitialDomain = () => {
  const host = window.location?.hostname;

  if (!host) {
    return "commande.kuupanda.com";
  }
  if (["granvillage.com"].includes(host)) {
    return "granvillage.com";
  }

  return "commande.kuupanda.com";
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV,
});

brandConfigSetup({
  appName: "form",
  defaultHostName: calculateInitialDomain(),
  brandConfigUrlBase: process.env.REACT_APP_BRAND_CONFIG_URL as string,
  createMuiTheme: createAppTheme,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrandConfigProvider loading={<PageLoader />}>
      <App />
    </BrandConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
