import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePresentation } from "../context/PresentationContext";
import { useLocation, useParams } from "react-router-dom";
import queryString from "qs";
import useStoreInfo from "../hooks/useStoreInfo";
import constants from "../constants";
import SimplifiedStore from "./SimplifiedStore";
import { useApiConfig } from "../context/ApiContext";
import httpClient from "../helpers/httpClient";
import { PageLoader } from "@kuupanda/commons";
import StoreError from "./StoreError";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { reCaptchaWrapper } from "../helpers/reCaptcha";
import { useCustomTheme } from "../context/CustomTheme";
import EmbeddedStore from "./Embedded/EmbeddedStore";

const getProductViewMode = (
  embedded: boolean,
  productViewMode: string,
  defaultView?: CommonTypes.ProductViewMode
): CommonTypes.ProductViewMode => {
  if (
    productViewMode &&
    Object.keys(CommonTypes.ProductViewMode).includes(productViewMode)
  ) {
    return productViewMode === "CARD"
      ? CommonTypes.ProductViewMode.CARD
      : CommonTypes.ProductViewMode.LIST;
  }

  return defaultView
    ? defaultView
    : embedded
    ? CommonTypes.ProductViewMode.CARD
    : CommonTypes.ProductViewMode.LIST;
};

const Store: React.FC = () => {
  const [loaded, setLoaded] = useState(false);
  const { isEmbedded, setIsEmbedded, setPresentationOptions } =
    usePresentation();
  const { updateTheme } = useCustomTheme();

  let { saleChannelRaw, producerId, storeType, storeFrontSlug } = useParams<{
    saleChannelRaw: string;
    producerId: string;
    storeType: string;
    storeFrontSlug?: string;
  }>() as {
    saleChannelRaw: string;
    producerId: string;
    storeType: string;
    storeFrontSlug?: string;
  };
  const location = useLocation();

  const queryParams = useMemo(
    () =>
      queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      }),
    [location.search]
  );

  const saleChannel =
    saleChannelRaw === "pro"
      ? constants.SALE_CHANNEL.PROFESSIONAL
      : constants.SALE_CHANNEL.STANDARD;

  const initialCodes = useMemo(
    () => (queryParams.codes as string[]) || [],
    [queryParams.codes]
  );

  const isGroup = storeType === "group";

  const { apiRoot }: any = useApiConfig();

  const {
    store,
    isLoading,
    products,
    producer,
    categories,
    validCodes,
    couponLines,
    setCodes,
    setCartAmount,
    setCustomer,
    setCartProducts,
    codesEvaluated,
    codes,
    isError,
    invalidDiscounts,
  } = useStoreInfo(
    producerId,
    apiRoot,
    saleChannel,
    isGroup,
    "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/user/farm/48db9a161bdd1e9b1e03063bb1a6fd68cd2cd7fd92fa433d05017064ef32ac2f.png",
    constants.COMPONENT.FORM,
    initialCodes,
    0,
    true,
    storeFrontSlug
  );

  useEffect(() => {
    if (isLoading) return;
    let embedded = false;
    if (queryParams.embedded !== undefined) {
      embedded = queryParams.embedded === "true";
      setIsEmbedded(embedded);
    }

    const options = {
      hideCover: !!(queryParams.hideCover && queryParams.hideCover === "true"),
      hideInfo: !!(queryParams.hideInfo && queryParams.hideInfo === "true"),
      productViewMode: getProductViewMode(
        embedded,
        queryParams.productViewMode as string,
        store.productViewMode
      ),
    };

    setPresentationOptions(options);

    if (store.palette) {
      updateTheme({ palette: store.palette });
    }

    setLoaded(true);
  }, [
    queryParams,
    setIsEmbedded,
    setPresentationOptions,
    saleChannel,
    store.productViewMode,
    isLoading,
    store.palette,
    updateTheme,
  ]);

  const submitForm = useCallback(
    async (orderDTO: any) => {
      const submitURL = isGroup
        ? `${apiRoot}/group-order/form/createGroupOrder`
        : `${apiRoot}/order/form/createOrder`;

      return await reCaptchaWrapper(async function (recaptchaToken: string) {
        return await httpClient.post(submitURL, {
          ...orderDTO,
          recaptchaToken,
        });
      });
    },
    [apiRoot, isGroup]
  );

  if (isLoading || !loaded) {
    return <PageLoader />;
  }

  if (isError) {
    return <StoreError />;
  }

  if (isEmbedded) {
    return (
      <EmbeddedStore
        producerId={producerId}
        isLoading={isLoading}
        productsByCategory={products}
        categories={categories}
        store={store}
        contactPoints={(producer && producer.contactPoints) || []}
        saleChannel={saleChannel}
        onSubmit={submitForm}
        isGroup={isGroup}
        validCodes={validCodes}
        couponLines={couponLines}
        setCartAmount={setCartAmount}
        setCustomer={setCustomer}
        setCartProducts={setCartProducts}
        setCodes={setCodes}
        invalidDiscounts={invalidDiscounts}
        initialCodes={initialCodes}
        codesEvaluated={codesEvaluated}
        codes={codes}
        embedded={isEmbedded}
        producer={producer}
        storeFrontSlug={storeFrontSlug}
      />
    );
  }

  return (
    <SimplifiedStore
      producerId={producerId}
      isLoading={isLoading}
      productsByCategory={products}
      categories={categories}
      store={store}
      contactPoints={(producer && producer.contactPoints) || []}
      saleChannel={saleChannel}
      onSubmit={submitForm}
      isGroup={isGroup}
      validCodes={validCodes}
      couponLines={couponLines}
      setCartAmount={setCartAmount}
      setCustomer={setCustomer}
      setCartProducts={setCartProducts}
      setCodes={setCodes}
      invalidDiscounts={invalidDiscounts}
      initialCodes={initialCodes}
      codesEvaluated={codesEvaluated}
      codes={codes}
      embedded={isEmbedded}
      producer={producer}
      storeFrontSlug={storeFrontSlug}
    />
  );
};

export default Store;
