import { forEach, isEmpty, mapValues, pick } from "lodash";
import { ALL_CATEGORIES } from "../constants";
import constants from "../constants";
import useDataApi from "./useDataApi";
import { useMemo } from "react";
import { getDiscountErrors } from "./discountErrors";

const applyDiscountsToProducts = (
  products: any,
  productDiscounts: any[],
  categoryDiscounts: any[],
  category: string
) => {
  return products.map((product: any) => {
    // Try to find a matching discount for the product or its category.
    const productDiscount = productDiscounts.find((pd) =>
      pd.discount.products.includes(product.id)
    );
    const categoryDiscount = categoryDiscounts.find((cd) =>
      cd.discount.categories.includes(category)
    );
    const discount = productDiscount || categoryDiscount;

    // Apply the found discount to the product, if any.
    return {
      ...product,
      category: category,
      ...(discount && { discount: discount.discount }),
    };
  });
};

const buildProducts = (
  products: any,
  discounts: any[] = [],
  saleChannel: string
) => {
  const results: { category: any; count: any; products: any }[] = [];

  const productDiscounts = discounts
    .filter((it) => it.discount.products && it.discount.products.length > 0)
    .filter(
      (it) =>
        !it.errors.includes("ACTIVE") &&
        !it.errors.includes("CODES") &&
        !it.errors.includes("SALE_CHANNEL") &&
        !it.errors.includes("STOREFRONT")
    );

  const categoryDiscounts = discounts
    .filter((it) => it.discount.categories && it.discount.categories.length > 0)
    .filter(
      (it) =>
        !it.errors.includes("ACTIVE") &&
        !it.errors.includes("CODES") &&
        !it.errors.includes("SALE_CHANNEL") &&
        !it.errors.includes("STOREFRONT")
    );

  for (const product of products) {
    results.push({
      category: product.category,
      count: product.products.length,
      products: applyDiscountsToProducts(
        product.products,
        productDiscounts,
        categoryDiscounts,
        product.category
      ),
    });
  }

  return results;
};

const formatMarker = (
  id: string,
  userID: string,
  storeName: string,
  name: string,
  lat: string,
  lng: string,
  address: any,
  comment: string,
  schedule: any
) => {
  return {
    id,
    userID,
    name,
    storeName,
    address,
    coord: {
      lat,
      lng,
    },
    comment,
    schedule,
  };
};

const getBannerUrl = (bannerUrl: string, storePictures: any) => {
  return (
    (storePictures && storePictures.length > 0 && storePictures[0].url) ||
    bannerUrl
  );
};

const getStore = (
  store: any,
  bannerUrl: string,
  isGroup: boolean,
  saleChannel: string,
  component: string
) => {
  const {
    storefront,
    owner,
    address,
    isRetail,
    hideNameOnForm,
    hideStockOnForm,
  } = store;

  return {
    ...pick(owner, [
      "email",
      "name",
      "lastName",
      "phone",
      "excludeDeliveryVAT",
    ]),
    variableWeightPercent: owner?.variableWeightPercent || 0.1,
    id: store.id,
    bannerUrl: getBannerUrl(bannerUrl, storefront.pictures),
    profileUrl:
      owner.profilePicture &&
      owner.profilePicture[0] &&
      owner.profilePicture[0].url,
    clientId: store.clientId,
    storeName: storefront.name,
    fullName: `${owner.name} ${owner.lastName}`,
    city: address.locality,
    address: address.text,
    presentation: storefront.presentation,
    clientClosedStore: storefront.clientClosedStore,
    closedStoreMessage: storefront.closedStoreMessage,
    productionMethod: storefront.productionMethod,
    paymentMethods: store.paymentMethods || [
      { type: constants.PAYMENT_METHOD.CREDIT_CARD },
      { type: constants.PAYMENT_METHOD.CASH },
    ],
    storeSlug: store.clientSlug,
    isGroup,
    saleChannel,
    component,
    closedStoreDate: storefront.closedStoreDate,
    isRetail,
    schedule: storefront.schedule,
    productViewMode: storefront.productViewMode,
    isAgency: owner.metadata && owner.metadata.isAgency,
    isAssociation: owner.metadata && owner.metadata.isAssociation,
    labels: owner.labels,
    customCategories: storefront.customCategories,
    palette: storefront.palette,
    hideLocationOnForm: storefront.hideLocationOnForm,
    hideNameOnForm,
    hideStockOnForm,
  };
};

const getContactPoints = (producer: any) => {
  const cPoints = producer.contactPoints;
  const markers: any[] = [];
  cPoints.forEach((cPoint: any) => {
    if (cPoint.type !== "TAKE_AWAY") return;
    const schedule = getSchedule(cPoint.schedule);
    const marker: { [key: string]: any } = formatMarker(
      cPoint._id,
      producer.clientId,
      producer.storeName,
      cPoint.name,
      cPoint.address.lat,
      cPoint.address.lng,
      cPoint.address.text,
      cPoint.comment,
      schedule
    );
    // if at the producer of this contact point has at least one delivery
    marker.doesDelivery = producer.doesDelivery;
    marker.activityPeriod = cPoint.activityPeriod;
    marker.isDelivery = cPoint.type === "DELIVERY";
    markers.push(marker);
  });

  return markers;
};

const getSchedule = (schedule: any) => {
  const out = mapValues(schedule, "values");
  forEach(out, (v, k, obj) => {
    if (isEmpty(v)) delete obj[k];
  });

  return out;
}; // removes the unused `values` prop

const getDeliveryPoints = (arr: any) => {
  const deliveryContactPoints = [
    ...arr.filter((cPoint: any) => cPoint.type === "CHRONOPOST"),
    ...arr.filter((cPoint: any) => cPoint.type === "DELIVERY"),
  ];
  // so it's easier during the search to know if user is in delivery range
  return deliveryContactPoints.map((contactPoint) => {
    let maxDist = 0;
    contactPoint.deliveryRates.forEach((deliveryRate: any) => {
      maxDist = Math.max(deliveryRate.to || 0, maxDist);
    });

    return {
      id: contactPoint._id,
      name: contactPoint.name,
      schedule: getSchedule(contactPoint.schedule),
      coord: {
        lat: contactPoint.address.lat,
        lng: contactPoint.address.lng,
      },
      address: contactPoint.address.text,
      radius: maxDist,
      comment: contactPoint.comment,
      type: contactPoint.type,
      minimumDeliveryAmount: contactPoint.minimumDeliveryAmount,
      freeFeeOrderAmount: contactPoint.freeFeeOrderAmount,
    };
  });
};

const getCategories = ({
  products,
  customCategories,
}: {
  products: any;
  customCategories: Record<string, string>;
}) => {
  return products.reduce((categories: any, product: any) => {
    const category = ALL_CATEGORIES.find(
      (category) => category.value === product.category
    );
    if (category) {
      categories = [...categories, category];
    } else {
      categories = [
        ...categories,
        { value: product.category, label: customCategories[product.category] },
      ];
    }

    return categories;
  }, []);
};

const useStoreInfo = (
  initialProducerId: string,
  apiRoot: any,
  initialSaleChannel: string,
  isGroup: boolean,
  defaultBannerURL: string,
  component: string,
  initialCodes: string[],
  initialCartAmount: number,
  isSimplifiedStore: boolean = false,
  initialStoreFrontSlug?: string
) => {
  const {
    state: {
      data: {
        producer,
        products: initialProducts,
        validCodes,
        couponLines,
        invalidDiscounts,
      },
      isLoading,
      codesEvaluated,
      isError,
    },
    codes,
    setCodes,
    setCartAmount,
    setCustomer,
    setCartProducts,
  } = useDataApi(
    initialProducerId,
    {
      producer: undefined,
      products: [],
      invalidDiscounts: [],
    },
    apiRoot,
    initialSaleChannel,
    isGroup,
    initialCodes,
    initialCartAmount,
    isSimplifiedStore,
    initialStoreFrontSlug
  );

  const deliveryPoints = useMemo(
    () => (producer && getDeliveryPoints(producer.contactPoints)) || [],
    [producer]
  );

  const contactPoints = useMemo(
    () => (producer && getContactPoints(producer)) || [],
    [producer]
  );

  const store = useMemo(
    () =>
      (producer &&
        getStore(
          producer,
          defaultBannerURL,
          isGroup,
          initialSaleChannel,
          component
        )) ||
      {},
    [component, defaultBannerURL, initialSaleChannel, isGroup, producer]
  );

  const products = useMemo(
    () => buildProducts(initialProducts, invalidDiscounts, initialSaleChannel),
    [initialProducts, initialSaleChannel, invalidDiscounts]
  );
  const categories = useMemo(
    () =>
      getCategories({
        products,
        customCategories: store.customCategories,
      }),
    [products, store.customCategories]
  );

  const discountErrors = useMemo(
    () => getDiscountErrors(invalidDiscounts),
    [invalidDiscounts]
  );

  return {
    deliveryPoints,
    contactPoints,
    store,
    isLoading,
    products,
    producer,
    categories,
    validCodes,
    couponLines,
    setCodes,
    setCartAmount,
    setCustomer,
    setCartProducts,
    codesEvaluated,
    codes,
    isError,
    invalidDiscounts: discountErrors,
  };
};

export default useStoreInfo;
