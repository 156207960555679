import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

import { Button, BaseInput, Alert } from "@kuupanda/commons";
import Link from "@mui/material/Link";

const Container = styled("div")`
  background: white;
  border-radius: 8px;
  width: 100%;
`;

const Content = styled("div")(({ theme }) => ({
  padding: "16px 16px",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
  },
}));

const SubmitContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
`;

const Submit = styled("div")`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SimplifiedOrderSubmit: React.FC<{
  comment?: string;
  handleCommentChange: any;
  onSubmit: any;
  disabled?: boolean;
  isDeliveryInfoValid: boolean;
  isCustomerInfoValid: boolean;
  invalidRate: boolean;
  submitting: boolean;
}> = ({
  comment,
  handleCommentChange,
  onSubmit,
  disabled,
  isDeliveryInfoValid,
  isCustomerInfoValid,
  invalidRate,
  submitting,
}) => {
  return (
    <Container>
      <Content>
        <Typography variant="caption" color="textSecondary" component="div">
          <Box fontWeight={500}>Laissez un commentaire</Box>
        </Typography>
        <BaseInput
          multiline
          value={comment}
          fullWidth
          onChange={handleCommentChange}
          rows={5}
        />

        {!isCustomerInfoValid && (
          <Alert
            variant="error"
            message={"Veuillez vérifier vos informations client"}
          />
        )}

        {(!isDeliveryInfoValid || invalidRate) && (
          <Alert
            variant="error"
            message={
              "Veuillez vérifier les informations relatives à votre livraison"
            }
          />
        )}

        <SubmitContainer>
          <Submit>
            <Typography variant="caption" component="div">
              <Box fontWeight={500}>
                En validant la commande j’accepte les{" "}
                <Link
                  href="https://www.kuupanda.com/cgv-store"
                  color="primary.dark"
                  target="_blank"
                >
                  conditions générales de vente
                </Link>
              </Box>
            </Typography>
            <Button
              color="primary"
              onClick={onSubmit}
              disabled={disabled}
              endIcon={submitting && <CircularProgress size={20} />}
            >
              Valider la commande
            </Button>
          </Submit>
        </SubmitContainer>
      </Content>
    </Container>
  );
};

export default SimplifiedOrderSubmit;
