import { CommonTypes } from "@kuupanda/kuu-contract";
import React, { useContext, useState } from "react";

type PresentationOptions = {
  hideCover: boolean;
  hideInfo: boolean;
  productViewMode: CommonTypes.ProductViewMode;
};

type PresentationContextType = {
  isEmbedded: boolean;
  setIsEmbedded: (b: boolean) => void;
  setPresentationOptions: (option: PresentationOptions) => void;
  presentationOptions: PresentationOptions;
};

const defaultValue = {} as PresentationContextType;

const PresentationContext =
  React.createContext<PresentationContextType>(defaultValue);

const PresentationProvider = ({ children }: any) => {
  const [isEmbedded, setIsEmbedded] = useState(false);
  const [presentationOptions, setPresentationOptions] = useState({
    hideCover: false,
    hideInfo: false,
    productViewMode: CommonTypes.ProductViewMode.LIST,
  });

  return (
    <PresentationContext.Provider
      value={{
        isEmbedded,
        setIsEmbedded,
        presentationOptions,
        setPresentationOptions,
      }}
    >
      {children}
    </PresentationContext.Provider>
  );
};

export function usePresentation() {
  const context = useContext(PresentationContext);
  if (context == null) {
    throw new Error(
      "You must call usePresentation only when inside a PresentationProvider."
    );
  }

  return context;
}

const PresentationConsumer = PresentationContext.Consumer;

export { PresentationProvider, PresentationContext, PresentationConsumer };
