import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { NumberFormatCustom } from "@kuupanda/commons";

const MinAmount: React.FC<{
  cartAmount: number;
  minAmount: number;
  discountAmount: number;
  type: string;
}> = ({ cartAmount, minAmount, discountAmount, type }) => {
  const difference = minAmount - cartAmount;
  const progress = Math.min(100, Math.ceil((cartAmount / minAmount) * 100));

  if (progress === 100) {
    return null;
  }

  const amount = type === "PERCENTAGE" ? discountAmount * 100 : discountAmount;

  const isPercent = type === "PERCENTAGE";

  return (
    <Box>
      <Typography fontWeight={500} component={"div"} fontSize={14}>
        Plus que{" "}
        <NumberFormatCustom
          displayType={"text"}
          value={difference}
          suffix={" €"}
        />{" "}
        TTC (hors livraison) pour bénéficier d’une remise{" "}
        <NumberFormatCustom
          displayType={"text"}
          value={amount}
          suffix={isPercent ? " %" : " €"}
        />
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box>
          <Typography
            color={"primary"}
            fontWeight={700}
            component={"div"}
            fontSize={14}
          >
            <NumberFormatCustom
              displayType={"text"}
              value={cartAmount}
              suffix={" "}
            />
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            color="primary"
            value={progress}
            variant={"determinate"}
          />
        </Box>
        <Box>
          <Typography
            color={"primary"}
            fontWeight={700}
            component={"div"}
            fontSize={14}
          >
            <NumberFormatCustom
              displayType={"text"}
              value={minAmount}
              suffix={" "}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MinAmount;
