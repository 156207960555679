export const productTagsMapping: { [key: string]: string } = {
  AB: "AB",
  LABEL_ROUGE: "Label Rouge",
  AOC: "AOC",
  AOP: "AOP",
  IGP: "IGP",
  STG: "STG",
  BIO_COHERENCE: "Bio Cohérence",
  BIO_PARTENAIRE: "Bio Partenaire",
  DEMETER: "Demeter",
  ECOCERT: "Ecocert et Ecocert Équitable",
  NATURE_ET_PROGRES: "Nature & Progrès",
  FAIR_TRADE: "FairTrade",
  JEUNES_AGRICULTEURS: "Jeunes agriculteurs",
  SUD_FRANCE: "Sud de France",
  HVE: "Haute valeur environnementale",
  ONE_HUNDRED_PERCENT_REUNION: "100% Réunion",
};
