import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { format, parseISO } from "date-fns";
import fr from "date-fns/locale/fr";
import { setModalIframePosition } from "../helpers/modal";
import { usePresentation } from "../context/PresentationContext";

const Container = styled("div")`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 26, 54, 0.2);
  z-index: 4;
`;

const Content = styled("div")(({ theme }) => ({
  position: "fixed",
  top: "25%",
  left: "50%",
  background: "white",
  transform: "translate(-50%, -25%)",
  zIndex: "5",
  padding: "24px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "750px",
  [theme.breakpoints.down("md")]: {
    width: "unset",
    left: "10px",
    right: "10px",
    transform: "translateY(-50%)",
  },
}));

const Message = styled("div")(
  ({ theme }) => `
  background: ${theme.palette.primary.main};
  padding: 12px;
  border-radius: 1px 10.2375px 10.2375px 10.2375px;
  color: white;
`
);

const StoreClosed: React.FC<{ store: any }> = ({ store }) => {
  const { isEmbedded } = usePresentation();

  useEffect(() => {
    setModalIframePosition(isEmbedded, false, "kuu-form-closed", 150);
  }, [isEmbedded]);

  return (
    <>
      <Content id="kuu-form-closed">
        <Typography component="div">
          <Box fontWeight={700} fontSize={20}>
            Ce formulaire de commande est momentanément fermé
          </Box>
        </Typography>

        <Typography component="div" color="textSecondary">
          <Box fontSize={16}>{store.storeName} vous a laissé un message :</Box>
        </Typography>

        {store.closedStoreDate && (
          <Typography component="div" color="textSecondary">
            <Box
              fontSize={14}
              fontWeight={500}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {format(parseISO(store.closedStoreDate), "'le 'd MMM y", {
                locale: fr,
              })}
            </Box>
          </Typography>
        )}

        <Message>
          <Typography component="div" color="inherit">
            <Box fontSize={16}>{store.closedStoreMessage}</Box>
          </Typography>
        </Message>
      </Content>

      <Container />
    </>
  );
};

export default StoreClosed;
