import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import constants from "../constants";
import { Typography } from "@mui/material";
import { NumberFormatCustom } from "@kuupanda/commons";
import {
  isCpAgency,
  isCpExternalShipping,
  isCpMondialRelay,
  isCpRelay,
  isCpShop2Shop,
  isCpUps,
} from "../helpers/delivery";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { differenceInDays, format } from "date-fns";

const getFormattedFrequency = (
  frequency: string,
  type: CommonTypes.ContactPointType
) => {
  let formattedFrequency;

  const delayedShipping =
    isCpShop2Shop(type) || isCpMondialRelay(type) || isCpUps(type);

  switch (frequency) {
    case constants.DELIVERY_FREQUENCY.EVERYDAY:
      formattedFrequency = !delayedShipping
        ? `1 et 3 jours ouvrés`
        : `2 et 4 jours ouvrés`;
      break;
    case constants.DELIVERY_FREQUENCY.EVERY_TWO_DAYS:
      formattedFrequency = !delayedShipping
        ? `2 et 4 jours ouvrés`
        : `3 et 5 jours ouvrés`;
      break;
    case constants.DELIVERY_FREQUENCY.TWICE_A_WEEK:
      formattedFrequency = !delayedShipping
        ? `3 et 5 jours ouvrés`
        : `4 et 6 jours ouvrés`;
      break;
    case constants.DELIVERY_FREQUENCY.ONCE_A_WEEK:
      formattedFrequency = !delayedShipping
        ? `5 to 7 jours ouvrés`
        : `6 et 8 jours ouvrés`;
      break;
    case constants.DELIVERY_FREQUENCY.THREE_WEEKS_AFTER:
      formattedFrequency = `3 semaines à validation de la commande`;
      break;
    default:
      formattedFrequency = ``;
  }

  return formattedFrequency;
};

const Container = styled("div")(
  ({ theme }) => `
  color: ${theme.palette.primary.dark};
  font-weight: 500;
`
);

const getDescription = (
  isRelay: boolean,
  isAgency: boolean,
  hasDeliveryDelay: boolean
) => {
  if (hasDeliveryDelay) {
    return "Livraison à partir de :";
  }
  if (isAgency) {
    return "Date de livraison : ";
  }

  return isRelay ? "Le colis arrivera entre" : "Vous serez livré entre";
};

const checkHasDeliveryDelay = (availableDate: Date) => {
  const referenceDate = new Date();
  referenceDate.setHours(0, 0, 0, 0);
  const delay = differenceInDays(availableDate, referenceDate) + 1;

  return delay > 7;
};

function DeliveryDateFrequency({
  contactPoint,
  deliveryRate,
  relayPoint,
  availableDate,
}: {
  contactPoint: any;
  deliveryRate: any;
  relayPoint: any;
  availableDate: Date;
}) {
  const isRelay = isCpRelay(contactPoint.type);
  const isAgency = isCpAgency(contactPoint.type);
  const isExternalShipping = isCpExternalShipping(contactPoint.type);

  const hasDeliveryDelay = useMemo(
    () => checkHasDeliveryDelay(availableDate),
    [availableDate]
  );

  return (
    <Container>
      {relayPoint && isRelay && (
        <div>
          <Typography variant="caption">Le point relais</Typography>{" "}
          <Typography variant="caption" color="inherit">
            {relayPoint.name} - {relayPoint.house_number} {relayPoint.street},{" "}
            {relayPoint.city}
          </Typography>
        </div>
      )}

      <div>
        <Typography variant="caption">
          Le prix de livraison à cette adresse est de
        </Typography>{" "}
        <Typography variant="caption" color="inherit">
          <NumberFormatCustom value={deliveryRate} displayType="text" />
        </Typography>
      </div>
      {isExternalShipping && (
        <div>
          <Typography variant="caption">
            {getDescription(isRelay, isAgency, hasDeliveryDelay)}
          </Typography>{" "}
          <Typography variant="caption" color="inherit">
            {hasDeliveryDelay
              ? format(availableDate, "dd/MM")
              : getFormattedFrequency(
                  contactPoint.frequency,
                  contactPoint.type
                )}
          </Typography>
        </div>
      )}
    </Container>
  );
}

export default DeliveryDateFrequency;
