import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import LargeButton from "./LargeButton";
import { RiStoreLine, RiTruckLine } from "react-icons/ri";
import { isCpDelivery } from "../helpers/delivery";
import { CommonTypes } from "@kuupanda/kuu-contract";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: 10,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ContactPointTypeButton: React.FC<{
  handleOnSelect: any;
  hasDeliveryCPs: any;
  hasTakeAwayCPs: any;
  contactPoint: any;
}> = ({ handleOnSelect, hasDeliveryCPs, hasTakeAwayCPs, contactPoint }) => {
  const onClickTakeaway = useCallback(
    () => handleOnSelect(CommonTypes.ContactPointType.TAKE_AWAY),
    [handleOnSelect]
  );
  const onClickDelivery = useCallback(
    () => handleOnSelect(CommonTypes.ContactPointType.DELIVERY),
    [handleOnSelect]
  );

  const isDelivery = isCpDelivery(contactPoint.type);

  return (
    <ButtonContainer>
      {hasDeliveryCPs && (
        <LargeButton
          selected={isDelivery}
          title="Livraison"
          icon={<RiTruckLine />}
          onClick={onClickDelivery}
        />
      )}
      {hasTakeAwayCPs && (
        <LargeButton
          selected={
            contactPoint.type === CommonTypes.ContactPointType.TAKE_AWAY
          }
          title="Retrait sur place"
          icon={<RiStoreLine />}
          onClick={onClickTakeaway}
        />
      )}
    </ButtonContainer>
  );
};

export default ContactPointTypeButton;
