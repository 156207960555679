import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { PageLoader, Button } from "@kuupanda/commons";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiConfig } from "../context/ApiContext";
import { useHttpClient } from "../hooks/useHttpClient";
import queryString from "qs";
import { Box, Typography } from "@mui/material";
import { usePresentation } from "../context/PresentationContext";
import { getCartInfoFromLocalStorage } from "../helpers/LocalStorageRepository";

const Container = styled("div")`
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
`;

const PaymentComplete: React.FC<{
  base: string;
  handleOrderComplete: (order: any) => void;
  setCart: (cart: any) => void;
}> = ({ handleOrderComplete, base, setCart }) => {
  const {
    isEmbedded,
    presentationOptions: { hideCover, hideInfo },
  } = usePresentation();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [error, setError] = useState(false);

  const { apiRoot }: any = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const transactionId = queryParams.transactionId as string;

  const checkOrderCreated = useCallback(async () => {
    let order = null;
    for (let i = 0; i < 10; i++) {
      try {
        order = await httpClient.get(
          `/order-transaction/orders/${transactionId}`
        );

        break;
      } catch (e) {
        console.log("ORDER NOT CREATED");
      }

      //sleep for 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    if (order) {
      handleOrderComplete(order[0]);
    } else {
      handleOrderComplete(null);
    }
  }, [handleOrderComplete, httpClient, transactionId]);

  useEffect(() => {
    const checkOrder = async () => {
      try {
        //check transaction was successful
        const result = await httpClient.get(`/payment/status/${transactionId}`);

        if (result.status === "SUCCEEDED") {
          //wait for webhook
          await checkOrderCreated();
        } else {
          setError(true);
          console.log("FAILED");

          const cart = getCartInfoFromLocalStorage();
          setCart(cart);
        }
      } catch (e) {
        setError(true);

        const cart = getCartInfoFromLocalStorage();
        setCart(cart);
        console.log(e);
        console.log("FAILED", e);
      }
    };

    checkOrder();
  }, [
    apiRoot,
    checkOrderCreated,
    handleOrderComplete,
    httpClient,
    setCart,
    transactionId,
  ]);

  const onReturnToStore = useCallback(() => {
    const url = isEmbedded
      ? `${base}?embedded=true&hideCover=${hideCover}&hideInfo=${hideInfo}`
      : base;
    navigate(url);
  }, [base, isEmbedded, navigate, hideCover, hideInfo]);

  if (!error) {
    return <PageLoader />;
  }

  return (
    <Container style={{ position: isEmbedded ? "relative" : "fixed" }}>
      <Typography component="div" variant="subtitle1" color="textSecondary">
        <Box fontWeight={400} fontSize={20} textAlign="center">
          Échec du paiement, veuillez réessayer
        </Box>
      </Typography>

      <Button color="primary" onClick={onReturnToStore}>
        Réessayer
      </Button>
    </Container>
  );
};

export default PaymentComplete;
