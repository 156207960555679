import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { RiShoppingCartLine } from "react-icons/ri";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { RiCloseLine } from "react-icons/ri";
import { NumberFormatCustom, useIsMobile } from "@kuupanda/commons";
import { getProductQuantityTotal } from "../../helpers/misc";

const StyledButton = styled("div", {
  shouldForwardProp: (prop: any) => prop !== "isMobile",
})<{ isMobile: boolean }>(
  ({ theme, isMobile }) => `
  background-color: ${theme.palette.grey[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid ${theme.palette.grey["100"]};
  ${
    isMobile &&
    `
       height: 51px;
    width: 67px;
    padding: 0px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 0px 5px 12px rgba(50, 50, 71, 0.38);
    color: #ffffff;
    background-color: ${theme.palette.primary.main};
  `
  }
`
);

const CartIcon = styled(RiShoppingCartLine)(({ theme }) => ({
  fill: theme.palette.grey[700],
  [theme.breakpoints.down("md")]: {
    fill: "#ffffff",
  },
}));

const CloseIcon = styled(RiCloseLine)(({ theme }) => ({
  fill: theme.palette.grey[700],
  height: 20,
  width: 20,
  [theme.breakpoints.down("md")]: {
    fill: "#ffffff",
  },
}));

const CartItems: React.FC<{
  cart: any;
  onHover: (state: boolean) => void;
  open: boolean;
}> = ({ cart, onHover, open }) => {
  const isMobile = useIsMobile();

  const handleClick = () => {
    onHover(!open);
  };

  const handleMouseEnter = () => {
    if (isMobile) return;
    onHover(true);
  };

  const numItems = useMemo(
    () => getProductQuantityTotal(Object.values(cart)),
    [cart]
  );

  return (
    <StyledButton
      onClick={handleClick}
      isMobile={isMobile}
      onMouseEnter={handleMouseEnter}
    >
      {open && isMobile ? (
        <CloseIcon />
      ) : (
        <>
          <CartIcon />
          <Typography component="div" color={"inherit"}>
            <Box fontWeight={500} fontSize={16}>
              <NumberFormatCustom
                value={numItems}
                displayType={"text"}
                suffix={" "}
                decimalScale={0}
              />
            </Box>
          </Typography>
        </>
      )}
    </StyledButton>
  );
};

export default CartItems;
