import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { getFormattedDate, getSlotsForDate } from "../helpers/delivery";
import { Box, Typography } from "@mui/material";
import { RiPencilLine } from "react-icons/ri";
import { BaseCalendar } from "@kuupanda/commons";

// Styleds
const DateFieldContainer = styled("div")(
  ({ theme }) => `
  background: ${theme.palette.grey[200]};
  border-radius: 8px;
  padding: 25px;
`
);

const SlotsContainer = styled("div")`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 5px;
`;

const Slot = styled("div", {
  shouldForwardProp: (prop: any) => prop !== "selected",
})<{ selected: boolean }>(
  ({ theme, selected }) => `
  color: ${selected ? "#ffffff" : theme.palette.others.a};
  background: ${
    selected ? theme.palette.secondary.main : theme.palette.grey[50]
  };
  border-radius: 8px;
  padding: 5px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 131%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
`
);

const ChooseDate = styled("div")(
  ({ theme }) => `
  display: flex;
  gap: 5px;
  font-weight: 400;
  line-height: 150%;
  align-items: center;
  color: ${theme.palette.primary.dark};
  cursor: pointer;
  font-size: 14px;
`
);

const CalendarContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: "9999",
  marginTop: "10px",
  backgroundColor: "white",
  width: "fit-content",
  border: `2px solid ${theme.palette.others.d}`,
}));

type DateFieldProps = {
  contactPoint: any;
  selectedDate: Date;
  availableDates: any[];
  onChangeDate: any;
};

const calcAvailableDates = (
  startDate: Date,
  endDate: Date,
  availableDates: Date[]
) => {
  if (startDate > endDate) return [];
  const date: Date = startDate;
  const dates = [];

  while (date < endDate) {
    date.getDate();
    const exists = availableDates.find(
      (availableDate: Date) =>
        availableDate.toDateString() === date.toDateString()
    );

    if (!exists) {
      dates.push(new Date(date));
    }

    date.setDate(date.getDate() + 1);
  }

  return dates;
};

function DateField({
  contactPoint,
  selectedDate,
  availableDates,
  onChangeDate,
}: DateFieldProps) {
  const [open, setOpen] = useState(false);

  const formattedDate = getFormattedDate(selectedDate);

  const onChange = (date: Date) => {
    onChangeDate(date);
    setOpen(false);
  };

  const setTimeSlot = (slot: Date) => {
    const hours = slot.getHours();
    const minutes = slot.getMinutes();

    //clone the date
    let newDate = new Date(selectedDate.valueOf());
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    onChangeDate(newDate);
  };

  const isTimeEqual = (time: Date, time2: Date) => {
    if (!time || !time2) {
      return false;
    }

    return (
      time.getHours() === time2.getHours() &&
      time.getMinutes() === time2.getMinutes()
    );
  };

  const slots = useMemo(
    () =>
      getSlotsForDate({
        date: selectedDate,
        contactPoint,
      }),
    [contactPoint, selectedDate]
  );

  const minDate = useMemo(
    () => new Date(Math.min(...availableDates.map((d) => d.getTime()))),
    [availableDates]
  );

  const maxDate = useMemo(
    () => new Date(Math.max(...availableDates.map((d) => d.getTime()))),
    [availableDates]
  );
  const disabledDates = useMemo(
    () => calcAvailableDates(minDate, maxDate, availableDates),
    [availableDates, maxDate, minDate]
  );

  return (
    <DateFieldContainer>
      <Typography component="div">
        <Box fontWeight={500} fontSize={14}>
          {formattedDate}
        </Box>
      </Typography>

      <ChooseDate onClick={() => setOpen((open: boolean) => !open)}>
        <RiPencilLine />
        <Typography color="inherit" variant={"caption"}>
          Choisir une autre date
        </Typography>
      </ChooseDate>

      {open && (
        <CalendarContainer>
          <BaseCalendar
            value={selectedDate}
            minDate={availableDates && availableDates[0]}
            maxDate={maxDate}
            disabledDates={disabledDates}
            onChange={onChange}
          />
        </CalendarContainer>
      )}

      <SlotsContainer>
        {slots.map((slot: any, index: number) => {
          return (
            <Slot
              key={index}
              onClick={() => setTimeSlot(slot.value)}
              selected={isTimeEqual(slot.value, selectedDate)}
            >
              {slot.label}
            </Slot>
          );
        })}
      </SlotsContainer>
    </DateFieldContainer>
  );
}

export default DateField;
