import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BaseInput, Button } from "@kuupanda/commons";
import { isValidEmail } from "../helpers/customer";

export const EmailModal: React.FC<{
  open: boolean;
  onClose: any;
  onSubmit: any;
}> = ({ open, onClose, onSubmit }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  const [submitting, setIsSubmitting] = useState<boolean>();
  const [email, setEmail] = useState<string>("");

  const onClick = () => {
    setIsSubmitting(true);

    onSubmit(email);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={"paper"}
      fullScreen={fullScreen}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        id: "kuu-stock-modal",
      }}
      maxWidth={"xs"}
      fullWidth
    >
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            textAlign: "center",
          }}
        >
          <Typography variant={"h4"}>Renseigner mon adresse email</Typography>

          <BaseInput
            name="email"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={onClick}
            disabled={submitting || email.length === 0 || !isValidEmail(email)}
            color={"primary"}
          >
            Enregister
          </Button>
          <Button onClick={onClose}>Annuler</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
