import React, { useCallback, useContext, useEffect, useState } from "react";

type ConfigOptions = {
  env: string;
};

type ConfigContextType = {
  setConfig: (option: ConfigOptions) => void;
  config: ConfigOptions;
};

const defaultValue = {} as ConfigContextType;

const ConfigContext = React.createContext<ConfigContextType>(defaultValue);

const ConfigProvider = ({ children }: { children: any }) => {
  const [config, setConfig] = useState<ConfigOptions>({
    env: process.env.NODE_ENV as string,
  });
  const [loading, setLoading] = useState(true);
  const fetchConfig = useCallback(async () => {
    try {
      // setConfig({
      //   theme: createTheme({
      //     palette: {
      //       primary: {
      //         main: "#000000",
      //       },
      //     },
      //   }),
      // });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  if (loading) {
    return null;
  }

  return (
    <ConfigContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig() {
  const configuration = useContext(ConfigContext);
  if (configuration == null) {
    throw new Error(
      "You must call useConfig only when inside a ConfigProvider."
    );
  }

  return configuration;
}

const ConfigConsumer = ConfigContext.Consumer;

export { ConfigProvider, ConfigContext, ConfigConsumer };
