import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import { getNextOpeningDate } from "../helpers/store";

const Container = styled("div")`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 26, 54, 0.2);
  z-index: 4;
`;

const Content = styled("div")(({ theme }) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  background: "white",
  transform: "translate(-50%, -50%)",
  zIndex: "5",
  padding: "24px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  [theme.breakpoints.down("md")]: {
    left: "10px",
    right: "10px",
    transform: "translateY(-50%)",
  },
}));

const Message = styled("div")(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: "12px",
  borderRadius: "1px 10.2375px 10.2375px 10.2375px",
  color: "white",
}));

const StoreClosedDay: React.FC<{ store: any }> = ({ store }) => {
  const nextOpeningDate = getNextOpeningDate(new Date(), store.schedule);
  return (
    <>
      <Content>
        <Typography component="div">
          <Box fontWeight={700} fontSize={20}>
            Ce formulaire de commande est momentanément fermé
          </Box>
        </Typography>

        <Message>
          <Typography component="div" color="inherit">
            <Box fontSize={16}>
              La boutique est fermée mais revenez{" "}
              {format(nextOpeningDate, "EEEE 'à partir de 'HH'h'mm", {
                locale: fr,
              })}
            </Box>
          </Typography>
        </Message>
      </Content>

      <Container />
    </>
  );
};

export default StoreClosedDay;
