import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Button } from "@kuupanda/commons";
import { ReactComponent as SuccessImage } from "../assets/Images/success.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { usePresentation } from "../context/PresentationContext";

const Container = styled("div")`
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
`;

const OrderSuccess: React.FC<{ base: string }> = ({ base }) => {
  const { isEmbedded } = usePresentation();
  const navigate = useNavigate();
  const location = useLocation();

  const orderNumber = location?.state?.order?.orderNumber;

  return (
    <Container style={{ position: isEmbedded ? "relative" : "fixed" }}>
      <SuccessImage />

      <Typography component="div">
        <Box fontWeight={700} fontSize={28} sx={{ textAlign: "center" }}>
          {orderNumber
            ? `Merci pour votre commande ${orderNumber}!`
            : "Merci pour votre commande !"}
        </Box>
      </Typography>

      <Typography component="div" variant="subtitle1" color="textSecondary">
        <Box fontWeight={400} fontSize={20} textAlign="center">
          Vous recevrez dans les prochaines minutes un email de confirmation.
        </Box>
      </Typography>

      <Button color="primary" onClick={() => navigate(base)}>
        Passer une nouvelle commande
      </Button>
    </Container>
  );
};

export default OrderSuccess;
