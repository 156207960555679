import React, { useCallback, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import ResponsivePicture from "./ResponsivePicture";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import "keen-slider/keen-slider.min.css";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { useIsMobile } from "@kuupanda/commons";

const ArrowBack = styled(RiArrowLeftSLine)(
  ({ theme }) => `
  fill: ${theme.palette.grey["800"]};
`
);

const ArrowForward = styled(RiArrowRightSLine)(
  ({ theme }) => `
  fill: ${theme.palette.grey["800"]};

`
);

const Slider = styled("div")({
  width: "100% !important" as any,
  height: "100%  !important" as any,
  position: "absolute !important" as any,
  top: "0 !important" as any,
});

const Slide = styled("div")({});

const Dots = styled("div")`
  display: flex;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  left: auto;
`;

const Dot = styled("button", {
  shouldForwardProp: (prop: string) => prop !== "active",
})<{ active: boolean }>(
  ({ theme, active }) => `
      border: none;
  border-radius: 70%;
  margin: 0 5px;
  padding: 3px;
  height: 8px;
  width: 8px;
  cursor: pointer;
  :focus {
    outline: none;
  }
    background: ${active ? "#FFFFFF" : theme.palette.grey[600]};
`
);

export function SinglePicture({
  picture,
  height,
  allowZoom,
}: {
  picture: any;
  height: number;
  allowZoom?: boolean;
}) {
  return (
    <ResponsivePicture
      alt={`product`}
      height={height}
      baseImgUrl={picture.url}
      allowZoom={allowZoom}
    />
  );
}

function MediaSlider({
  pictures,
  height,
  allowZoom,
}: {
  pictures: any[];
  height: number;
  allowZoom?: boolean;
}) {
  const isMobile = useIsMobile();

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: "center",
    },
  });

  const handlePrev = useCallback(
    (e: any) => e.stopPropagation() || instanceRef.current?.prev(),
    [instanceRef]
  );

  const handleNext = useCallback(
    (e: any) => {
      e.stopPropagation() || instanceRef.current?.next();
    },
    [instanceRef]
  );

  return (
    <>
      <div>
        <Slider ref={sliderRef} className="keen-slider">
          {pictures.map((picture, index) => {
            return (
              <Slide className="keen-slider__slide" key={index}>
                <SinglePicture
                  picture={picture}
                  height={height}
                  allowZoom={allowZoom}
                />
              </Slide>
            );
          })}
        </Slider>
        <div></div>
        {loaded && instanceRef.current && !isMobile && pictures.length > 1 && (
          <>
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                webkitTransform: "translateY(-50%)",
                zIndex: "99999",
                left: "5px",
              }}
              onClick={handlePrev}
              disabled={currentSlide === 0}
            >
              <ArrowBack />
            </IconButton>

            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                webkitTransform: "translateY(-50%)",
                zIndex: "99999",
                left: "auto",
                right: "5px",
              }}
              onClick={handleNext}
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            >
              <ArrowForward />
            </IconButton>
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <Dots>
          {Array(instanceRef.current.track.details.slides.length)
            .fill(null)
            .map((_, idx) => {
              return (
                <Dot
                  key={idx}
                  active={currentSlide === idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                />
              );
            })}
        </Dots>
      )}
    </>
  );
}

export default MediaSlider;
