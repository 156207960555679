import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { RiArrowUpDownFill } from "react-icons/ri";
import Typography from "@mui/material/Typography";
import { checkColorIsLightOrDark } from "../../helpers/misc";
import { Link, Box } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  background: theme.palette.secondary.light,
  borderRadius: "8px",
  padding: "4px",
  display: "flex",
  alignItems: "center",
  gap: 8,
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    gap: 0,
    flexDirection: "column",
  },
}));

const Arrow = styled(RiArrowUpDownFill)<{ $mode: string }>(
  ({ $mode, theme }) => ({
    fill: $mode === "light" ? theme.palette.grey[700] : "#ffffff",
  })
);

const Title = styled(Typography)<{ $mode: string }>(({ theme, $mode }) => ({
  color: $mode === "light" ? theme.palette.text.primary : "#ffffff",
  fontSize: 12,
}));

const LearnMore = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  cursor: "pointer",
  textDecoration: "underline",
  fontSize: 12,
}));

const VariableWeight: React.FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => {
  const theme = useTheme();
  const mode = checkColorIsLightOrDark(theme.palette.secondary.light);

  return (
    <Container className={className}>
      <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <Arrow $mode={mode} />
        <Title variant={"caption"} $mode={mode}>
          Poids variable
        </Title>
      </Box>
      <LearnMore onClick={onClick}>En savoir plus</LearnMore>
    </Container>
  );
};

export default VariableWeight;
