export async function reCaptchaWrapper(
  callback: (recaptchaToken: string) => Promise<any>
): Promise<unknown> {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      reject(new Error("reCaptchaWrapper not initialized"));
    }
    window.grecaptcha.ready(async function () {
      const recaptchaToken = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        {
          action: "submit",
        }
      );

      try {
        const result = await callback(recaptchaToken);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  });
}
