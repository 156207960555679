export function setModalIframePosition(
  isEmbedded: boolean,
  isModalFullScreen: boolean,
  modalId: string,
  offset: number = 0
) {
  if (!isEmbedded || !window.parentIFrame) {
    return;
  }
  window.parentIFrame.getPageInfo((obj: any) => {
    try {
      const modalPaper = window.document.getElementById(modalId);

      if (modalPaper) {
        modalPaper.style.position = "fixed";
        if (!isModalFullScreen) {
          const modalHeight = modalPaper.getBoundingClientRect().height;
          const modalWidth = modalPaper.getBoundingClientRect().width;
          const topValue =
            obj.scrollTop -
            obj.offsetTop +
            obj.windowHeight / 2 -
            modalHeight / 2 -
            offset;
          modalPaper.style.right = `calc(50% - ${modalWidth / 2}px)`;
          modalPaper.style.top = `${topValue}px`;
          console.log(obj);
        } else {
          const topValue = obj.scrollTop - obj.offsetTop - 8;
          modalPaper.style.margin = "0px";
          modalPaper.style.height = `${obj.windowHeight}px`;
          modalPaper.style.top = `${topValue}px`;
          window.parentIFrame.sendMessage("DISABLE_OVERFLOW");
        }
      }
    } catch (e) {
      console.debug(e);
    }
  });
}

export function closeModalIframe(
  isEmbedded: boolean,
  isMobile: boolean,
  modalId: string
) {
  const element = window.document.getElementById(modalId);
  if (!isEmbedded || !window.parentIFrame || !element) {
    return;
  }
  window.parentIFrame.getPageInfo(false);
  if (isMobile) {
    window.parentIFrame.sendMessage("ENABLE_OVERFLOW");
  }
}
