import React from "react";
import { styled } from "@mui/material/styles";
import ResponsivePicture from "../common/ResponsivePicture";
import { useIsMobile } from "@kuupanda/commons";
import { usePresentation } from "../context/PresentationContext";

const Container = styled("div")(({ theme }) => ({
  background: "white",
  borderRadius: "8px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginTop: "24px",
  },
}));

const FarmPicture = styled(ResponsivePicture)(({ theme, hideInfo }) => ({
  borderRadius: "6px 6px 0 0",
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));

const SimplifiedHeader: React.FC<{
  name: string;
  bannerUrl?: string;
}> = ({
  name,
  bannerUrl = "https://s3.eu-west-3.amazonaws.com/kuu.imgs/original/user/farm/48db9a161bdd1e9b1e03063bb1a6fd68cd2cd7fd92fa433d05017064ef32ac2f.png",
}) => {
  const isMobile = useIsMobile();

  const {
    presentationOptions: { hideCover },
  } = usePresentation();

  if (hideCover) {
    return null;
  }

  return (
    <Container>
      <FarmPicture
        height={isMobile ? 100 : 250}
        sizeQuery="(min-width: 750px) 60vw, (max-width: 749px) 95vw"
        baseImgUrl={bannerUrl}
        fitType="cover"
        containerStyle={{}}
        alt={name}
        className=""
      />
    </Container>
  );
};

export default SimplifiedHeader;
