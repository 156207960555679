import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import constants from "../../constants";
import BasicPaymentMethod from "./BasicPaymentMethod";
import PaymentMethodOptions from "../PaymentMethodOptions";
import { BaseCheckbox } from "@kuupanda/commons";
import BillingInput from "../BillingInput";
import VariableWeightHelper from "../VariableWeight/VariableWeightHelper";
import cbLogo from "../../assets/Images/cb.png";
import visaLogo from "../../assets/Images/visa.png";
import masterCardLogo from "../../assets/Images/mastercard.png";

const PaymentMethodContainer = styled("div")`
  margin-top: 20px;
`;

function PaymentForm({
  paymentMethods,
  handlePaymentMethodChange,
  paymentMethod,
  handleChangeBillingAddressInfo,
  saleChannel,
  billingAddress,
  iban,
  store,
  variableWeightProducts,
  onVariableWeightClick,
  variableWeightPercent,
}: {
  paymentMethods: any[];
  handlePaymentMethodChange: any;
  paymentMethod: any;
  handleChangeBillingAddressInfo: any;
  saleChannel: any;
  billingAddress: any;
  iban?: string;
  store: any;
  variableWeightProducts: any[];
  onVariableWeightClick: () => void;
  variableWeightPercent: number;
}) {
  const [differentBillingAddress, setDifferentBillingAddress] = useState(false);

  const toggleBillingAddress = (value: boolean) => {
    setDifferentBillingAddress(value);
    handleChangeBillingAddressInfo({});
  };

  return (
    <Grid item xs={12}>
      <Typography fontSize={22} gutterBottom>
        Moyen de paiement
      </Typography>
      <PaymentMethodOptions
        paymentMethod={paymentMethod}
        paymentMethods={paymentMethods}
        onChangePaymentMethod={handlePaymentMethodChange}
      />
      <PaymentMethodContainer>
        {variableWeightProducts.length > 0 && (
          <VariableWeightHelper
            products={variableWeightProducts}
            onClick={onVariableWeightClick}
            paymentMethod={paymentMethod}
            variableWeightPercent={variableWeightPercent}
          />
        )}
        {paymentMethod === constants.PAYMENT_METHOD.CREDIT_CARD && (
          <div>
            <Typography component="div" color={"textSecondary"}>
              <Box fontWeight={500} fontSize={14}>
                Paiement sécurisé avec les méthodes suivantes :
              </Box>
            </Typography>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={cbLogo}
                alt="cbLogo"
                style={{ width: "45px", marginRight: "5px" }}
              />
              <img
                src={visaLogo}
                alt="visaLogo"
                style={{ width: "60px", marginRight: "5px" }}
              />
              <img
                src={masterCardLogo}
                alt="masterCardLogo"
                style={{ width: "55px" }}
              />
            </div>
          </div>
        )}
        {paymentMethod !== constants.PAYMENT_METHOD.CREDIT_CARD && (
          <BasicPaymentMethod paymentMethod={paymentMethod} iban={iban} />
        )}
      </PaymentMethodContainer>

      {!store.isAgency && (
        <BaseCheckbox
          checked={differentBillingAddress}
          label="Mon adresse de facturation est différente de mon adresse de livraison"
          onChange={(e) => toggleBillingAddress(e.target.checked)}
        />
      )}

      {differentBillingAddress && (
        <BillingInput
          value={billingAddress}
          onChangeField={handleChangeBillingAddressInfo}
          saleChannel={saleChannel}
        />
      )}
    </Grid>
  );
}

export default PaymentForm;
