import { ButtonOnlyIcon } from "@kuupanda/commons";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRef, useState } from "react";
import { RiArrowDownLine, RiArrowUpLine } from "react-icons/ri";
import useHasOverflowed from "../hooks/useHasOverflowed";

const Container = styled("div")(({ theme }) => ({
  background: theme.palette.grey[200],
  borderRadius: 8,
  padding: 10,
  color: theme.palette.others.a,
  marginTop: 16,
  fontSize: 14,
}));

const DescriptionBox = styled("div")<{ $show: boolean }>(({ $show }) => ({
  maxHeight: $show ? "100%" : 300,
  overflowY: "hidden",
}));

const ShowMore = styled(ButtonOnlyIcon)(({ theme }) => ({
  borderRadius: "50%",
  height: 30,
  width: 30,
  svg: {
    height: 24,
    width: 24,
  },
}));

const Description: React.FC<{ store: any }> = ({ store }) => {
  const ref = useRef<any>();
  const isOverflow = useHasOverflowed(ref);

  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow((prevState) => !prevState);
  };

  return (
    <Container>
      <DescriptionBox
        $show={show}
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: store.presentation,
        }}
      />

      {isOverflow && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ShowMore color={"secondary"} onClick={toggleShow}>
            {show ? <RiArrowUpLine /> : <RiArrowDownLine />}
          </ShowMore>
        </Box>
      )}
    </Container>
  );
};

export default Description;
