import React from "react";
import { styled } from "@mui/material/styles";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const sizes = [
  50, 75, 100, 150, 190, 300, 360, 420, 480, 570, 640, 768, 960, 1152, 1280,
  1536, 2048, 2304, 2560, 3072, 3840,
];

const formats: { [key: string]: { extension: string; contentType: string } } = {
  WEBP: {
    extension: "webp",
    contentType: "image/webp",
  },
  JPEG: {
    extension: "jpeg",
    contentType: "image/jpeg",
  },
};

const StyledImg = styled("img", {
  shouldForwardProp: (prop: any) => prop !== "fit",
})<{ fit: string }>(
  ({ fit }) => `
      object-fit: ${fit};
  height: auto;
  width: 100%;
  border-radius: 2px 2px 0 0;
`
);

function buildSrcSet(
  protocol: string,
  host: string,
  originalId: string,
  originalPath: string,
  size: number,
  format: string
) {
  const prefixedPath = originalPath.replace("original", "generated/original");
  return `${protocol}//${host}/${prefixedPath}/${originalId}-${size}.${formats[format].extension} ${size}w`;
}

function parseURL(baseImgUrl: string) {
  const url = new URL(baseImgUrl);
  const originalFilename = url.pathname.substring(
    url.pathname.lastIndexOf("/") + 1
  );
  const originalId = originalFilename.substring(
    0,
    originalFilename.lastIndexOf(".")
  );
  const originalPath = url.pathname
    .slice(1)
    .replace(originalFilename, "")
    .slice(0, -1);

  return { protocol: url.protocol, host: url.host, originalPath, originalId };
}
const ZoomWrapper: React.FC<{
  children: any;
  allowZoom: boolean;
}> = ({ children, allowZoom }) => {
  return allowZoom ? <Zoom>{children}</Zoom> : children;
};

const ResponsivePicture: React.FC<{
  className?: string;
  alt?: string;
  containerStyle?: any;
  baseImgUrl: string;
  sizeQuery?: string;
  height: number | string;
  fitType?: string;
  allowZoom?: boolean;
}> = ({
  className,
  height = "100%",
  containerStyle,
  baseImgUrl,
  sizeQuery = "100vw",
  alt,
  fitType = "contain",
  allowZoom = false,
  ...props
}) => {
  const { protocol, host, originalId, originalPath } = parseURL(baseImgUrl);
  const pictureStyle = {
    height,
    display: "flex",
    width: "100%",
    ...containerStyle,
  };

  return (
    <ZoomWrapper allowZoom={allowZoom}>
      <picture style={pictureStyle}>
        {" "}
        {/* So this works with styled components */}
        {Object.keys(formats).map((format) => (
          <source
            sizes={sizeQuery}
            type={formats[format].contentType}
            key={format}
            srcSet={sizes
              .map((size) =>
                buildSrcSet(
                  protocol,
                  host,
                  originalId,
                  originalPath,
                  size,
                  format
                )
              )
              .reduce((acc, current) => `${acc}, ${current}`)}
          />
        ))}
        <StyledImg
          fit={fitType}
          src={baseImgUrl}
          alt={alt}
          {...props}
          className={className}
        />
      </picture>
    </ZoomWrapper>
  );
};

export default ResponsivePicture;
