import React from "react";
import { styled } from "@mui/material/styles";

function Map({ lat, lng }: { lat: string; lng: string }) {
  const mapType = "roadmap";
  const mapZoom = 15;
  const query = `${lat},${lng}`;

  return (
    <div style={{ height: `100%` }}>
      <iframe
        title="contactPointLocation"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: "0" }}
        src={`https://www.google.com/maps/embed/v1/place?zoom=${mapZoom}&maptype=${mapType}&q=${query}&key=AIzaSyBvpOINUfTU3iGWSNwsjBwiBz9KlSQJPVI`}
      />
    </div>
  );
}

const MapContainer = styled("div")`
  width: 100%;
`;

const ContactPointMap: React.FC<{ contactPoint: any }> = ({ contactPoint }) => {
  return (
    <MapContainer className="contact-point-map">
      <Map lat={contactPoint.address.lat} lng={contactPoint.address.lng} />
    </MapContainer>
  );
};

export default ContactPointMap;
