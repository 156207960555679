import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PageLoader, useIsMobile } from "@kuupanda/commons";
import SelectRelayPoint from "./SelectRelayPoint";
import { useHttpClient } from "../hooks/useHttpClient";
import { useApiConfig } from "../context/ApiContext";
import { setModalIframePosition } from "../helpers/modal";
import { calculateProviderFromType, isCpShop2Shop } from "../helpers/delivery";
import { CommonTypes } from "@kuupanda/kuu-contract";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function CustomDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const getRelayPointEndpoint = (type: CommonTypes.ShipmentProvider) => {
  switch (type) {
    case CommonTypes.ShipmentProvider.MONDIAL_RELAY:
      return `/shipping/mondial-relay/relay-points`;
    default:
      return `/shipping/chronopost/relay-points`;
  }
};
const ShippingRelayPoints: React.FC<{
  address: any;
  handleClose: any;
  onSelect: any;
  currentRelay: any;
  isEmbedded: boolean;
  type: CommonTypes.ContactPointType;
}> = ({ address, handleClose, onSelect, currentRelay, isEmbedded, type }) => {
  const isMobile = useIsMobile();
  const [relayPoints, setRelayPoints] = useState([]);
  const [loading, setLoading] = useState(true);

  const { apiRoot }: any = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const provider = calculateProviderFromType(type);

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (address) {
        const url = getRelayPointEndpoint(provider);
        try {
          const response = await httpClient.post(url, {
            longitude: address.lng,
            latitude: address.lat,
            address: address.route,
            postalCode: address.postal_code,
            city: address.locality,
            country: "FR",
            provider: provider,
          });

          //shop 2 shop only accepts type P and C
          const result = isCpShop2Shop(type)
            ? response.relayPoints.filter((rp: any) =>
                ["P", "C"].includes(rp.shop_type)
              )
            : response.relayPoints;
          setRelayPoints(result);
        } catch (error) {}
      }
      setLoading(false);
    })();
  }, [httpClient, address, type, provider]);

  const handleSelectedRelayPoint = useCallback(
    (relayPoint: any) => {
      if (relayPoint) {
        onSelect(relayPoint);
      }
    },
    [onSelect]
  );

  if (loading) {
    return <PageLoader />;
  }

  if (!relayPoints) {
    return null;
  }

  return (
    <CustomDialog
      onClose={handleClose}
      aria-labelledby="kuu-relay-point-modal-title"
      open={true}
      fullScreen={isMobile}
      PaperProps={{
        id: "kuu-relay-point-modal",
      }}
      scroll={"paper"}
      ref={() =>
        setModalIframePosition(isEmbedded, isMobile, "kuu-relay-point-modal")
      }
    >
      <CustomDialogTitle id="kuu-relay-point-modal-title" onClose={handleClose}>
        Choisissez le point de retrait souhaité
      </CustomDialogTitle>
      <DialogContent dividers>
        <SelectRelayPoint
          relayPoints={relayPoints}
          handleSelectedRelayPoint={handleSelectedRelayPoint}
          address={address}
          relayPoint={currentRelay}
          handleClose={handleClose}
        />
      </DialogContent>
    </CustomDialog>
  );
};

export default ShippingRelayPoints;
