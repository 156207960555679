import React from "react";
import { Helmet } from "react-helmet-async";

const Meta: React.FC<{
  title: string;
  description: string;
  keywords: string;
  ogTitle: string;
  ogURL: string;
  ogDescription: string;
  ogImage: string;
}> = ({
  title = "KuuPanda",
  description = "La qualité de la proximité - Réinventons notre économie locale",
  keywords = "Producteurs, produits, locaux, circuit-court, vente directe, producteurs locaux, produits locaux.",
  ogTitle = "KuuPanda",
  ogURL = "https://kuupanda.com",
  ogDescription,
  ogImage,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:url" content={ogURL} />
      <meta property="og:image" content={ogImage} />
    </Helmet>
  );
};

export default Meta;
