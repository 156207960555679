import { useMemo } from "react";
import httpClient from "../helpers/httpClient";

function getFullUrl(baseUrl: string, path: string) {
  return `${baseUrl}${path}`;
}

export function useHttpClient(
  baseUrl: string,
  { accessToken }: { accessToken?: string } = {}
) {
  return useMemo(() => {
    const headers: { [key: string]: any } = {};
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    return {
      get: async (path: string) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.get(url, {
          headers,
        });

        return res.data;
      },
      post: async (path: string, body: any) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.post(url, body, {
          headers,
        });

        return res.data;
      },
      patch: async (path: string, body: any) => {
        const url = getFullUrl(baseUrl, path);
        const res = await httpClient.patch(url, body, {
          headers,
        });

        return res.data;
      },
    };
  }, [baseUrl, accessToken]);
}
