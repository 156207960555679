import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useIsMobile } from "@kuupanda/commons";

const TabContainer = styled("div")(({ theme }) => ({
  background: theme.palette.grey[200],
  border: `2px solid ${theme.palette.grey["100"]}`,
  width: "100%",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    overflowX: "scroll",
  },
}));

const ProgressOuter = styled("div")(
  ({ theme }) => `
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${theme.palette.grey[200]};
  height: 4px;
  overflow: hidden;
  position: relative;
`
);

const ProgressInner = styled("div", {
  shouldForwardProp: (prop: string) => prop !== "progress",
})<{ progress: number }>(
  ({ theme, progress }) => `
    
  transform: translateX(-${progress}%);
  transition: transform 0.4s linear;
  background-color: ${theme.palette.secondary.main};
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transform-origin: left;
`
);

const ItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  padding: "10px",
  [theme.breakpoints.down("sm")]: {
    width: "max-content",
    gap: "40px",
  },
}));

const Item = styled("a", {
  shouldForwardProp: (prop: string) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: "flex",
  gap: "5px",
  alignItems: "center",
  color: selected ? theme.palette.others.a : theme.palette.grey[800],
  cursor: "pointer",
  textDecoration: "none",
  [theme.breakpoints.down("md")]: selected && {
    borderBottom: `5px ${theme.palette.primary.main} solid`,
  },
}));

const Navigation: React.FC<{
  selected: string;
  onChange: (key: string) => void;
  items: {
    key: string;
    name: string;
    icon: (selected: boolean) => React.ReactNode;
  }[];
  scrollRef?: any;
}> = ({ selected, onChange, items, scrollRef }) => {
  const isMobile = useIsMobile();
  const [hover, setHover] = useState(true);
  const selectedIndex = items.findIndex((item) => item.key === selected);
  const progress = (100 / items.length) * (selectedIndex + 1);

  const timerRef = React.useRef<any>(null);

  const onSelect = useCallback(
    (key: string) => {
      onChange(key);

      setHover(true);

      if (timerRef.current != null) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        setHover(false);
      }, 2000);
    },
    [onChange]
  );

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <TabContainer ref={scrollRef}>
      <ItemsContainer>
        {items.map((item) => (
          <div key={item.key}>
            <Item
              selected={item.key === selected}
              key={item.key}
              onClick={() => onSelect(item.key)}
              href={`#${item.key}`}
            >
              {item.icon(item.key === selected && hover)}
              <Typography color="inherit" component="div">
                <Box
                  fontWeight={item.key === selected ? 700 : 400}
                  fontSize={16}
                >
                  {item.name}
                </Box>
              </Typography>
            </Item>
          </div>
        ))}
      </ItemsContainer>

      {!isMobile && (
        <ProgressOuter>
          <ProgressInner progress={100 - progress} />
        </ProgressOuter>
      )}
    </TabContainer>
  );
};

export default Navigation;
