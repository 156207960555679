import React, { useMemo } from "react";

import {
  ButtonOnlyIcon,
  NumberFormatCustom,
  Tag,
  useIsMobile,
} from "@kuupanda/commons";
import { Box, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import ResponsivePicture from "../common/ResponsivePicture";
import QuantitySelector from "../common/QuantitySelector";
import {
  RiShoppingCart2Line,
  RiPriceTag3Line,
  RiRefreshLine,
} from "react-icons/ri";
import { CommonTypes } from "@kuupanda/kuu-contract";
import { productTagsMapping } from "../helpers/productLabels";
import {
  getProductName,
  isProductAvailable,
  getUnitName,
  isComboMultiple,
  isComboSingle,
} from "../helpers/product";
import VariableWeight from "../common/VariableWeight/VariableWeight";
import constants from "../constants";
import { format, parseISO } from "date-fns";

const Container = styled("div")(
  ({ theme }) => `
  border: 2px solid ${theme.palette.grey[100]};
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${theme.palette.primary.light};
  }
  padding: 10px;
`
);

const SmallTag = styled(Tag)({
  padding: "3px 6px",
  fontSize: 12,
  height: 14,
});

const StyledResponsiveProfilePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  @media (min-width: 600px) {
    width: 50px;
  }
  width: 50px;
`;

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: 8,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    alignItems: "center",
  },
}));

const PriceContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PriceBeforeDiscount = styled(Typography)(
  ({ theme }) => `
  text-decoration: line-through;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${theme.palette.grey[800]};
  line-height: 140%;
`
);

const Price = styled(Typography)(
  ({ theme }) => `
  font-weight: 500;
  font-size: 16px;
  color: ${theme.palette.others.g};
  line-height: 140%;
`
);

const PriceAfterDiscount = styled(Typography)(
  ({ theme }) => `
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${theme.palette.primary.dark};
`
);

const TagContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const VariableWeightList = styled(VariableWeight)({
  marginTop: 8,
  maxWidth: 225,
});

const VariableWeightListMobile = styled(VariableWeight)({
  marginTop: 8,
});

const ProductItemList: React.FC<{
  product: any;
  className?: string;
  onChangeCart: any;
  quantity: any;
  onProductClick: any;
  saleChannel: CommonTypes.SaleChannel;
  isGroup: boolean;
  onWeightClick: any;
  onValidateCart: any;
}> = ({
  product,
  className,
  onChangeCart,
  quantity,
  onProductClick,
  saleChannel,
  isGroup,
  onWeightClick,
  onValidateCart,
}) => {
  const isProfessional = saleChannel === CommonTypes.SaleChannel.PROFESSIONAL;
  const isMobile = useIsMobile();
  const {
    picture,
    price,
    baseUnit,
    priceBeforeReduction,
    priceReduction,
    discounts = [],
    highlight,
    sizes,
    producer,
    sellByVariableWeight,
    averageWeight,
    variableUnit,
    variableWeightPrice,
    priceByUnit,
    availableEndDate,
    discount,
  } = product;

  const availableDate = useMemo(() => {
    if (
      product.preparationType === constants.PREPARATION_TYPE.SPECIFIC_DATE &&
      product.availableDate
    ) {
      const availableDateTime = new Date(product.availableDate).getTime();

      if (Date.now() <= availableDateTime) {
        return product.availableDate;
      }
    }
  }, [product.availableDate, product.preparationType]);

  const isPastAvailableEnd = availableEndDate
    ? Date.now() >= new Date(availableEndDate).getTime()
    : false;

  const isAvailable = isProductAvailable(product);

  const onChange = (value: string) => {
    onChangeCart({
      product,
      quantity: value,
    });
  };

  const onValidate = (quantity: number) => {
    return onValidateCart({
      product,
      quantity: quantity,
    });
  };

  const totalDiscountPercent = useMemo(() => {
    const amount = discounts.reduce(
      (acc: number, discount: { amount: number }) => acc + discount.amount,
      0
    );

    return amount > 0 ? amount * 100 : 0;
  }, [discounts]);

  return (
    <Container className={className}>
      <Content>
        {!isMobile && (
          <Box onClick={() => onProductClick(product)}>
            <StyledResponsiveProfilePicture
              fitType="cover"
              containerStyle={{ width: "auto" }}
              height={50}
              sizeQuery="50px"
              baseImgUrl={picture.url}
            />
          </Box>
        )}
        <Box sx={{ flex: 1 }}>
          <TagContainer>
            {isGroup && producer.storeName && producer.storeName.length > 0 && (
              <SmallTag variant="default">{producer.storeName}</SmallTag>
            )}

            {discount && discount.minimumAmount > 0 && (
              <SmallTag variant="purple" startIcon={<RiPriceTag3Line />}>
                Promo{" "}
                <NumberFormatCustom
                  value={
                    discount.type === "PERCENTAGE"
                      ? discount.amount * 100
                      : discount.amount
                  }
                  displayType="text"
                  suffix={discount.type === "PERCENTAGE" ? " %" : " €"}
                />{" "}
                à partir de{" "}
                <NumberFormatCustom
                  value={discount.minimumAmount}
                  displayType="text"
                />
              </SmallTag>
            )}

            {totalDiscountPercent > 0 && (
              <SmallTag variant="purple" startIcon={<RiPriceTag3Line />}>
                PROMO -
                <NumberFormatCustom
                  value={totalDiscountPercent}
                  displayType="text"
                  suffix={" %"}
                />
              </SmallTag>
            )}

            {highlight === CommonTypes.ProductHighlightType.NEW && (
              <SmallTag variant="green">NOUVEAU !</SmallTag>
            )}

            {availableDate && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Disponible à partir du{" "}
                {format(parseISO(availableDate), "dd/MM")}
              </SmallTag>
            )}

            {availableEndDate && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Disponible jusqu’au{" "}
                {format(parseISO(availableEndDate), "dd/MM")}
              </SmallTag>
            )}

            {isComboSingle(product) && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Produit avec variation
              </SmallTag>
            )}

            {isComboMultiple(product) && (
              <SmallTag variant="default" startIcon={<RiRefreshLine />}>
                Panier avec options
              </SmallTag>
            )}

            {product.tags &&
              product.tags.length > 0 &&
              product.tags.map((tag: string, index: number) => (
                <SmallTag key={index} variant="default">
                  {productTagsMapping[tag]}
                </SmallTag>
              ))}

            {!isAvailable && (
              <SmallTag variant={"red"}>Rupture de stock</SmallTag>
            )}
          </TagContainer>

          <Typography component="div" onClick={() => onProductClick(product)}>
            <Box fontWeight={700} fontSize={16}>
              {getProductName(product)}
            </Box>
          </Typography>

          <PriceContainer>
            {priceReduction > 0 ? (
              <PriceBeforeDiscount>
                <NumberFormatCustom
                  value={priceBeforeReduction}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " €"}
                />
              </PriceBeforeDiscount>
            ) : (
              <Price>
                <NumberFormatCustom
                  value={price}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " €"}
                />
              </Price>
            )}

            {priceReduction > 0 && (
              <PriceAfterDiscount>
                <NumberFormatCustom
                  value={price}
                  displayType="text"
                  suffix={isProfessional ? " € HT" : " €"}
                />
              </PriceAfterDiscount>
            )}

            {!isMobile && (
              <Box>
                {baseUnit === "unit" &&
                  averageWeight &&
                  variableUnit &&
                  variableWeightPrice && (
                    <Typography variant="overline">
                      <NumberFormatCustom
                        value={priceByUnit}
                        displayType="text"
                        suffix={isProfessional ? " € HT" : " €"}
                      />
                    </Typography>
                  )}

                <Typography color="textSecondary" variant="overline">
                  / {getUnitName(product)}
                </Typography>
              </Box>
            )}
          </PriceContainer>

          {isMobile && (
            <Box>
              {baseUnit === "unit" &&
                averageWeight &&
                variableUnit &&
                variableWeightPrice && (
                  <Typography variant="overline">
                    <NumberFormatCustom
                      value={priceByUnit}
                      displayType="text"
                      suffix={isProfessional ? " € HT" : " €"}
                    />
                  </Typography>
                )}

              <Typography color="textSecondary" variant="overline">
                / {getUnitName(product)}
              </Typography>
            </Box>
          )}
          {!isMobile && sellByVariableWeight && (
            <VariableWeightList onClick={onWeightClick} />
          )}
        </Box>

        {(sizes && sizes.length > 0) ||
        isComboSingle(product) ||
        isComboMultiple(product) ? (
          <ButtonOnlyIcon onClick={() => onProductClick(product)}>
            <RiShoppingCart2Line />
          </ButtonOnlyIcon>
        ) : (
          <Box>
            <QuantitySelector
              value={quantity}
              onChange={onChange}
              decimalScale={baseUnit === "unit" ? 0 : 2}
              disabled={!isAvailable || isPastAvailableEnd}
              onValidate={onValidate}
            />
          </Box>
        )}
      </Content>
      {isMobile && sellByVariableWeight && (
        <VariableWeightListMobile onClick={onWeightClick} />
      )}
    </Container>
  );
};

export default ProductItemList;
