import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ContactPointMain from "./ContactPointMain";

const Container = styled("div")`
  background: white;
  border-radius: 8px;
  width: 100%;
`;

const Content = styled("div")(({ theme }) => ({
  padding: "16px 16px",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
  },
}));

const SimplifiedDeliveryForm: React.FC<{
  contactPoints: any;
  producerId: any;
  storeName: any;
  cartSubTotal: any;
  preparationTime: any;
  availableDate: any;
  isSimplifiedStore: boolean;
  cart: any;
  contactPoint: any;
  clientAddress: any;
  handleFormInfoChange: any;
  handleChangeAddressInfo: any;
  isDeliveryInfoValid: boolean;
  invalidRate: boolean;
  selectedRate?: number;
  selectedDate?: Date;
  checkoutRef?: any;
}> = ({
  contactPoints,
  producerId,
  storeName,
  cartSubTotal,
  preparationTime,
  availableDate,
  isSimplifiedStore,
  cart,
  clientAddress,
  contactPoint,
  handleFormInfoChange,
  handleChangeAddressInfo,
  isDeliveryInfoValid,
  selectedRate,
  invalidRate,
  selectedDate,
  checkoutRef,
}) => {
  return (
    <Container id="delivery" ref={checkoutRef}>
      <Content>
        <Typography fontSize={22} gutterBottom>
          Comment récupérer vos produits ?
        </Typography>

        <ContactPointMain
          availableDate={availableDate}
          contactPoints={contactPoints}
          vendorId={producerId}
          vendorIdx={1}
          vendorName={storeName}
          showVendorName={false}
          totalAmount={cartSubTotal}
          clientAddress={clientAddress}
          preparationTime={preparationTime}
          isSimplifiedStore={isSimplifiedStore}
          cart={cart}
          contactPoint={contactPoint}
          handleFormInfoChange={handleFormInfoChange}
          handleChangeAddressInfo={handleChangeAddressInfo}
          isDeliveryInfoValid={isDeliveryInfoValid}
          invalidRate={invalidRate}
          selectedRate={selectedRate}
          selectedDate={selectedDate}
        />
      </Content>
    </Container>
  );
};

export default SimplifiedDeliveryForm;
