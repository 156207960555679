import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import constants from "../constants";
import LargeButton from "./LargeButton";
import { RiBankCard2Line, RiMoneyEuroCircleLine } from "react-icons/ri";
import { FaMoneyCheck } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";

function getPaymentMethodTitle(paymentMethod: string) {
  switch (paymentMethod) {
    case constants.PAYMENT_METHOD.CREDIT_CARD:
      return "Paiement en ligne";
    case constants.PAYMENT_METHOD.TRANSFER:
      return "Virement";
    case constants.PAYMENT_METHOD.CHEQUE:
      return "Chèque";
    case constants.PAYMENT_METHOD.CASH:
      return "Espèces";
    case constants.PAYMENT_METHOD.CREDIT_CARD_ON_SPOT:
      return "CB sur place";
    default:
      return "";
  }
}

function getPaymentMethodIcon(paymentMethod: string) {
  switch (paymentMethod) {
    case constants.PAYMENT_METHOD.CREDIT_CARD:
      return <RiBankCard2Line />;
    case constants.PAYMENT_METHOD.TRANSFER:
      return <BsBank2 />;
    case constants.PAYMENT_METHOD.CHEQUE:
      return <FaMoneyCheck />;
    case constants.PAYMENT_METHOD.CASH:
      return <RiMoneyEuroCircleLine />;
    case constants.PAYMENT_METHOD.CREDIT_CARD_ON_SPOT:
      return <RiBankCard2Line />;
    default:
      return "";
  }
}

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

function PaymentMethodOptions({
  paymentMethod,
  paymentMethods,
  onChangePaymentMethod,
}: {
  paymentMethod: string;
  paymentMethods: any;
  onChangePaymentMethod: any;
}) {
  const handlePaymentMethodChange = useCallback(
    (event: any, newPaymentMethod: any) => {
      event.preventDefault();
      if (!newPaymentMethod || newPaymentMethod.type === paymentMethod) {
        return;
      }
      onChangePaymentMethod(newPaymentMethod.type);
    },
    [onChangePaymentMethod, paymentMethod]
  );

  return (
    <ButtonContainer>
      {paymentMethods.map((it: any) => (
        <LargeButton
          key={it.type}
          selected={it.type === paymentMethod}
          title={getPaymentMethodTitle(it.type)}
          icon={getPaymentMethodIcon(it.type)}
          onClick={(event: any) => handlePaymentMethodChange(event, it)}
        />
      ))}
    </ButtonContainer>
  );
}

PaymentMethodOptions.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
      options: PropTypes.object,
    })
  ),
  paymentMethod: PropTypes.oneOf(Object.values(constants.PAYMENT_METHOD)),
};

PaymentMethodOptions.defaultProps = {
  paymentMethods: [
    { type: constants.PAYMENT_METHOD.CREDIT_CARD },
    { type: constants.PAYMENT_METHOD.CASH },
  ],
};

export default PaymentMethodOptions;
