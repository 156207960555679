export function getProductsForProducer(
  cart: any,
  producerId: string,
  isSimplifiedStore: boolean
) {
  const result: { [key: string]: any } = getCartByProducerId(cart);

  if (isSimplifiedStore && Object.keys(cart).length > 0) {
    const obj: any[] = Object.values(result); // TODO -  IMPROVE  IT

    return obj[0].products;
  }
  if (
    result.hasOwnProperty(producerId) &&
    result[producerId].hasOwnProperty("products")
  ) {
    return result[producerId].products;
  }

  return [];
}

export function getCartByProducerId(cart: any) {
  return Object.keys(cart).reduce((result: any, cartKey) => {
    const {
      quantity,
      product: { producer, ...product },
    } = cart[cartKey];

    if (result[producer.id]) {
      result[producer.id].products.push({
        ...product,
        quantity,
      });
    } else {
      result[producer.id] = {
        products: [
          {
            ...product,
            quantity,
          },
        ],
      };
    }

    return result;
  }, {});
}

export const getVariableWeightProducts = (cart: any) => {
  return Object.keys(cart)
    .filter((it) => cart[it].product.sellByVariableWeight)
    .map((it) => cart[it].product);
};
