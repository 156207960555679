import { CommonTypes } from "@kuupanda/kuu-contract";
import constants from "../constants";

const unitMap: { [string: string]: string } = {
  g: "g",
  kg: "kg",
  l: "L",
  ml: "mL",
  unit: "unité",
};

export const getProductName = (product: any) => {
  const { name, variableUnit, averageWeight } = product;

  if (averageWeight && variableUnit) {
    return `${name} - ${formatNumber(averageWeight)}${variableUnit}`;
  }

  return name;
};

export const getMaxAvailableUnits = (product: any) => {
  const unit = getUnit(product);

  if (unit === "unit") {
    return product.maxSaleQuantity === 1 ? "unité" : "unités";
  }

  return unitMap[unit];
};

const getUnit = (product: any) => {
  if (!product.variableWeightPrice) {
    return product.baseUnit;
  }

  return product.baseUnit === "unit"
    ? product.sellByUnit || "unit"
    : product.baseUnit;
};
export const getUnitName = (product: any) => {
  const unit = getUnit(product);
  return unitMap[unit];
};

export const getMinAvailableUnits = (product: any) => {
  const unit = getUnit(product);

  if (unit === "unit") {
    return product.minSaleQuantity === 1 ? "unité" : "unités";
  }

  return unitMap[unit];
};

const formatNumber = (number: number) => {
  return number.toString().replace(".", ",");
};

export const getProductPrice = (product: any, saleChannel: string) => {
  if (saleChannel === constants.SALE_CHANNEL.PROFESSIONAL) {
    return product.price * (1 + product.vat);
  }
  return product.price;
};

const checkIfChildProductsAreAvailable = (childProducts: any[]) => {
  return childProducts.every((childProduct) => {
    return childProduct.availableQuantity >= childProduct.quantity;
  });
};

export const isProductAvailable = (product: any) => {
  const { availableQuantity, childProducts, manageComboStock } = product;
  if (childProducts && childProducts.length > 0 && manageComboStock) {
    const childrenAvailable = checkIfChildProductsAreAvailable(
      product.childProducts
    );

    return availableQuantity > 0 && childrenAvailable;
  }

  return availableQuantity > 0;
};

export const isValidCombo = (product: any): boolean => {
  const { productComboType, childProducts } = product;
  return childProducts && childProducts.length > 0 && productComboType;
};

export const isComboSingle = (product: any): boolean => {
  const { productComboType } = product;
  return (
    isValidCombo(product) &&
    productComboType === CommonTypes.ProductComboType.SINGLE
  );
};

export const isComboMultiple = (product: any): boolean => {
  const { productComboType } = product;
  return (
    isValidCombo(product) &&
    productComboType === CommonTypes.ProductComboType.MULTIPLE
  );
};

export const isComboDefault = (product: any): boolean => {
  const { productComboType } = product;
  return (
    isValidCombo(product) &&
    productComboType === CommonTypes.ProductComboType.DEFAULT
  );
};
