import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import constants from "../../constants";
import { Box } from "@mui/material";

const IBANBox = styled("div")(
  ({ theme }) => `
  background: ${theme.palette.grey[200]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 5px;
  margin-top: 10px;
  align-items: center;
`
);

function BasicPaymentMethod({
  paymentMethod,
  iban,
}: {
  paymentMethod: any;
  iban?: string;
}) {
  return (
    <div>
      {paymentMethod === constants.PAYMENT_METHOD.TRANSFER && (
        <>
          <Typography component="div">
            <Box fontWeight={500} fontSize={14}>
              Information bancaire :
            </Box>
          </Typography>
          <IBANBox>
            <Typography color="textSecondary">IBAN :</Typography>
            <Typography component="div">
              <Box fontWeight={500} fontSize={14}>
                {iban ? iban : "--"}
              </Box>
            </Typography>
          </IBANBox>
        </>
      )}
    </div>
  );
}

export default BasicPaymentMethod;
