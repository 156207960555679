import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { PageLoader, Button } from "@kuupanda/commons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from "qs";
import { Box, Typography } from "@mui/material";
import { useApiConfig } from "../../context/ApiContext";
import { useHttpClient } from "../../hooks/useHttpClient";

const Container = styled("div")`
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: fixed;
`;

const OrderPaymentComplete = () => {
  const { orderId } = useParams<{
    orderId: string;
  }>() as {
    orderId: string;
  };

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = queryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { apiRoot }: any = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const transactionId = queryParams.transactionId as string;

  useEffect(() => {
    const checkOrder = async () => {
      try {
        const result = await httpClient.get(`/payment/status/${transactionId}`);

        if (result.status === "SUCCEEDED") {
          setSuccess(true);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
    };

    checkOrder();
  }, [apiRoot, httpClient, transactionId]);

  const onRetryPayment = useCallback(() => {
    navigate(`/order-payment/${orderId}`);
  }, [navigate, orderId]);

  if (error) {
    return (
      <Container>
        <Typography component="div" variant="subtitle1" color="textSecondary">
          <Box fontWeight={400} fontSize={20} textAlign="center">
            Échec du paiement, veuillez réessayer
          </Box>
        </Typography>

        <Button color="primary" onClick={onRetryPayment}>
          Réessayer
        </Button>
      </Container>
    );
  }

  if (success) {
    return (
      <Container>
        <Typography component="div" variant="subtitle1" color="textSecondary">
          <Box fontWeight={400} fontSize={20} textAlign="center">
            Paiement réalisé avec succès ! Le producteur s’occupe de votre
            commande
          </Box>
        </Typography>
      </Container>
    );
  }

  return <PageLoader />;
};

export default OrderPaymentComplete;
