export const LOCAL_STORAGE_KEYS = {
  CONTACT_POINT: "kuuwidgetRememberCP",
  CLIENT_ADDRESS: "kuuwidgetRememberAddress",
  CHOSEN_DATE: "kuuwidgetRememberDate",
  CUSTOMER_INFO: "kuuCustomerInfo",
  CART_INFO: "kuuCartInfo",
};
function isLocalStorageSupportedCheck() {
  const mod = "modernizr";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);

    return true;
  } catch (e) {
    return false;
  }
}
const isLocalStorageSupported = isLocalStorageSupportedCheck();
// initialize all LOCAL_STORAGE_KEYS as empty objects
Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
  if (!isLocalStorageSupported) {
    return;
  }
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, JSON.stringify({}));
  }
});

function setItem(keyName: string, keyValue: any) {
  if (!isLocalStorageSupported) {
    return;
  }

  return localStorage.setItem(keyName, keyValue);
}
function getItem(keyName: string) {
  if (!isLocalStorageSupported) {
    return "";
  }

  return localStorage.getItem(keyName) || "";
}
function clear() {
  if (!isLocalStorageSupported) {
    return;
  }

  return localStorage.clear();
}
function removeItem(keyName: string) {
  if (!isLocalStorageSupported) {
    return;
  }

  return localStorage.removeItem(keyName);
}

const utils = {
  setItem,
  getItem,
  clear,
  removeItem,
};

export default utils;
