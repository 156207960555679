import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FormControl } from "@mui/material";

import { BaseLabel, BaseInput } from "@kuupanda/commons";
import { isValidEmail } from "../helpers/customer";
import constants from "../constants";

const Container = styled("div")`
  background: white;
  border-radius: 8px;
  width: 100%;
`;

const Content = styled("div")(({ theme }) => ({
  padding: "16px 16px",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
  },
}));

const Row = styled("div")`
  display: flex;
  gap: 20px;
`;

const SimplifiedCustomerForm: React.FC<{
  saleChannel: any;
  customerInfo: any;
  handleChangeCustomerInfo: any;
  checkoutRef?: any;
}> = ({ saleChannel, customerInfo, handleChangeCustomerInfo, checkoutRef }) => {
  const [invalid, setInvalid] = useState({
    name: !Boolean(customerInfo?.name),
    lastName: !Boolean(customerInfo?.lastName),
    email: !Boolean(customerInfo?.email) || !isValidEmail(customerInfo?.email),
    phone: !Boolean(customerInfo?.phone),
    company: !Boolean(customerInfo?.company),
  });

  const [values, setValues] = useState<Record<string, any>>({
    name: customerInfo?.name || "",
    lastName: customerInfo?.lastName || "",
    email: customerInfo?.email || "",
    phone: customerInfo?.phone || "",
    company: customerInfo?.company || "",
  });

  const handleOnChange = useCallback(
    (event: any) => {
      let invalid = false;

      const { name, value } = event.target;

      if (name === "email") {
        invalid = !isValidEmail(value);
      } else {
        invalid = !Boolean(value && value.length > 0);
      }

      setValues((prev) => ({ ...prev, [name]: value }));
      setInvalid((prev) => ({ ...prev, [name]: invalid }));
      handleChangeCustomerInfo({ [name]: value });
    },
    [handleChangeCustomerInfo]
  );

  return (
    <Container id="information" ref={checkoutRef}>
      <Content>
        <Typography fontSize={22}>Vos informations</Typography>

        {saleChannel === constants.SALE_CHANNEL.PROFESSIONAL && (
          <Row>
            <FormControl fullWidth error={invalid.company} margin="normal">
              <BaseLabel htmlFor="company">Entreprise</BaseLabel>
              <BaseInput
                id="company"
                type="company"
                name="company"
                onChange={handleOnChange}
                value={values.company}
              />
            </FormControl>
          </Row>
        )}

        {saleChannel === constants.SALE_CHANNEL.STANDARD && (
          <Row>
            <FormControl fullWidth error={invalid.name} margin="normal">
              <BaseLabel htmlFor="name">Prénom</BaseLabel>
              <BaseInput
                id="name"
                type="name"
                name="name"
                onChange={handleOnChange}
                value={values.name}
              />
            </FormControl>

            <FormControl fullWidth error={invalid.lastName} margin="normal">
              <BaseLabel htmlFor="lastName">Nom</BaseLabel>
              <BaseInput
                id="lastName"
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleOnChange}
              />
            </FormControl>
          </Row>
        )}
        <Row>
          <FormControl fullWidth error={invalid.email} margin="normal">
            <BaseLabel htmlFor="email">Email</BaseLabel>
            <BaseInput
              id="email"
              type="text"
              name="email"
              value={values.email}
              onChange={handleOnChange}
            />
          </FormControl>
          <FormControl fullWidth error={invalid.phone} margin="normal">
            <BaseLabel htmlFor="phone">Téléphone</BaseLabel>
            <BaseInput
              id="phone"
              type="text"
              name="phone"
              value={values.phone}
              onChange={handleOnChange}
            />
          </FormControl>
        </Row>
      </Content>
    </Container>
  );
};

export default SimplifiedCustomerForm;
