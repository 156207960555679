import React from "react";
import { styled } from "@mui/material/styles";
import ResponsivePicture from "../common/ResponsivePicture";
import Typography from "@mui/material/Typography";
import { Link, Avatar, Box } from "@mui/material";
import { usePresentation } from "../context/PresentationContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { ImPhone } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "@kuupanda/commons";

const Container = styled("div")(({ theme }) => ({
  background: "white",
  borderRadius: "8px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginTop: "24px",
    position: "sticky",
    top: 24,
  },
}));

const ProfilePicture = styled(ResponsivePicture)`
  border-radius: 100%;
  width: 100px;
`;

const LabelContainer = styled("div")({
  display: "flex",
  gap: 5,
});

const Label = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: "2px 4px",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  color: theme.palette.secondary.dark,
  fontSize: "14px",
}));

const Categories = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: theme.palette.grey[200],
  padding: 10,
  borderRadius: 8,
  gap: 8,
}));

const LocationIcon = styled(FaMapMarkerAlt)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const PhoneIcon = styled(ImPhone)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const EmailIcon = styled(HiMail)(
  ({ theme }) => `
  color: ${theme.palette.primary.light};
`
);

const Content = styled("div")({
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const ProducerInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

const Info = styled("div")(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${theme.palette.primary.dark};
`
);

const CategoryLink = styled("a")(({ theme, selected }) => ({
  padding: 4,
  fontSize: 14,
  textDecoration: "none",
  color: theme.palette.others.a,
  ...(selected && {
    background: theme.palette.secondary.main,
    color: "white",
    borderRadius: 8,
  }),
}));

const StoreInfo: React.FC<{
  categories: { value: string; label: string }[];
  name: string;
  producer: any;
  phone?: string;
  email?: string;
  profilePicture?: string;
  address?: string;
  labels: string[];
  hideNameOnForm: boolean;
  hideLocationOnForm: boolean;
}> = ({
  categories,
  name,
  producer,
  phone,
  email,
  profilePicture,
  address,
  labels,
  hideNameOnForm,
  hideLocationOnForm,
}) => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const {
    presentationOptions: { hideInfo },
  } = usePresentation();

  if (hideInfo) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {profilePicture && (
            <Avatar alt={name} sx={{ height: 100, width: 100 }}>
              <ProfilePicture
                containerStyle={{ width: "auto" }}
                height={100}
                sizeQuery={"100px"}
                baseImgUrl={profilePicture}
              />
            </Avatar>
          )}

          <Typography fontWeight={700} fontSize={20}>
            {name}
          </Typography>

          {producer !== "" && !hideNameOnForm && (
            <Typography fontWeight={500}>{producer}</Typography>
          )}
        </Box>

        <LabelContainer>
          {labels.map((label, index) => (
            <Label key={index}>{label}</Label>
          ))}
        </LabelContainer>

        <ProducerInfo>
          {address && !hideLocationOnForm && (
            <Info>
              <LocationIcon />
              <Typography color="inherit" fontSize={14}>
                {address}
              </Typography>
            </Info>
          )}

          {phone && (
            <Info>
              <PhoneIcon />

              <Link
                color="inherit"
                fontSize={14}
                href={`tel:${phone}`}
                underline="none"
              >
                {phone}
              </Link>
            </Info>
          )}

          {email && (
            <Info>
              <EmailIcon />

              <Link
                color="inherit"
                fontSize={14}
                href={`mailto:${email}?subject=[Kuupanda]%20`}
                underline="none"
              >
                {email}
              </Link>
            </Info>
          )}
        </ProducerInfo>

        {!isMobile && (
          <Categories>
            {categories.map((category, index) => (
              <CategoryLink
                index={index}
                selected={location.hash === `#cat${category.value}`}
                href={`#cat${category.value}`}
                title={category.label}
              >
                {category.label}
              </CategoryLink>
            ))}
          </Categories>
        )}
      </Content>
    </Container>
  );
};

export default StoreInfo;
