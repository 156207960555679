import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  BaseCheckbox,
  BaseSelect,
  ButtonOnlyIcon,
  FilterCheckbox,
  BaseSearchBar,
  useIsMobile,
} from "@kuupanda/commons";
import { MenuItem, Tooltip } from "@mui/material";
import { RiImageLine, RiListCheck } from "react-icons/ri";
import { CommonTypes } from "@kuupanda/kuu-contract";

const Container = styled("div")`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const MobileContainer = styled("div")`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const ViewToggles = styled("div")`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
`;

const Toggles = styled("div")`
  display: flex;
  gap: 5px;
`;

const FlexSpacer = styled("div")`
  flex: 1;
`;

const FilterCheckboxContainer = styled("div")`
  width: 250px;
  display: flex;
  gap: 10px;
`;

const MenuItemStyle = styled(MenuItem, {
  shouldForwardProp: (prop: string) => prop !== "isSelected",
})<{ isSelected: boolean }>(
  ({ isSelected, theme }) => `
  padding: 4px 6px;

  :hover {
    ${
      !isSelected &&
      `background-color: ${theme.palette.primary.light} !important`
    };
  }
`
);

const Checkbox = styled(BaseCheckbox)({
  padding: "0 9px 0 0",
});

const ToggleButton = styled(ButtonOnlyIcon, {
  shouldForwardProp: (prop: any) => prop !== "selected",
})<{ selected: boolean }>(
  ({ theme, selected }) => `
  border-radius: 50%;
  background: ${
    selected ? theme.palette.secondary.main : theme.palette.grey[200]
  };
  svg {
    fill: ${selected ? "#FFFFFF" : theme.palette.others.a};
  }
  &:hover {
    background: ${
      selected ? theme.palette.secondary.dark : theme.palette.secondary.light
    };
  }
  height: 37px !important;
`
);

const Label = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.grey[700]};
`
);

const StyledSearchBar = styled(BaseSearchBar)`
  min-width: 200px;
`;

const Content = styled("div")({
  display: "flex",
  gap: 5,
});

const Placeholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.others.a,
}));

const Select = styled(BaseSelect)({
  width: 200,
});

const Filters: React.FC<{
  onChangeSearch: (val: any) => void;
  onClear: () => void;
  search: string;
  onChangeFilter: any;
  filters: any;
  categories: { value: string; label: string }[];
  currentView: string;
  changeView: (view: CommonTypes.ProductViewMode) => void;
  hasScrolled: boolean;
  producers?: { value: string; label: string }[];
}> = ({
  onChangeSearch,
  onClear,
  search,
  onChangeFilter,
  filters,
  categories,
  currentView,
  changeView,
  producers,
}) => {
  const isMobile = useIsMobile();

  const isSelected = (value: any, option: any) => {
    if (!value) return false;

    value = value.toString();
    return value && value.includes(option);
  };

  const renderValue = (selected: any, options: any[], placeholder: string) => {
    if (!selected || (selected && selected.length === 0)) {
      return <Placeholder variant="caption">{placeholder}</Placeholder>;
    }

    return (
      selected &&
      selected
        .map((key: string) => options.find((c) => c.value === key)?.label)
        .join(", ")
    );
  };

  if (isMobile) {
    return (
      <MobileContainer>
        <Content>
          <BaseSelect
            onChange={onChangeFilter}
            name="sortBy"
            value={filters.sortBy}
            fullWidth={isMobile}
          >
            <MenuItem value="default" disabled>
              Trier par
            </MenuItem>
            <MenuItem value="az">A-Z</MenuItem>
            <MenuItem value="za">Z-A</MenuItem>
            <MenuItem value="asc">Prix croissants</MenuItem>
            <MenuItem value="desc">Prix décroissants</MenuItem>
          </BaseSelect>

          <ViewToggles>
            <Toggles>
              <ToggleButton
                selected={currentView === CommonTypes.ProductViewMode.LIST}
                onClick={() => changeView(CommonTypes.ProductViewMode.LIST)}
              >
                <RiListCheck />
              </ToggleButton>
              <ToggleButton
                selected={currentView === CommonTypes.ProductViewMode.CARD}
                onClick={() => changeView(CommonTypes.ProductViewMode.CARD)}
              >
                <RiImageLine />
              </ToggleButton>
            </Toggles>
          </ViewToggles>
        </Content>

        {producers && producers.length > 1 && (
          <BaseSelect
            onChange={onChangeFilter}
            name="producer"
            value={filters.producer}
            fullWidth={isMobile}
            multiple={true}
            renderValue={(value: any) =>
              renderValue(value, producers, "Toutes les exploitations")
            }
          >
            {producers.map((producer) => (
              <MenuItem key={producer.value} value={producer.value}>
                <Checkbox
                  checked={isSelected(filters.producer, producer.value)}
                />
                {producer.label}
              </MenuItem>
            ))}
          </BaseSelect>
        )}

        <BaseSelect
          onChange={onChangeFilter}
          name="category"
          value={filters.category}
          fullWidth={isMobile}
          multiple={true}
          renderValue={(value: any) =>
            renderValue(value, categories, "Toutes les catégories")
          }
        >
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              <Checkbox
                checked={isSelected(filters.category, category.value)}
              />
              {category.label}
            </MenuItem>
          ))}
        </BaseSelect>

        <Content sx={{ div: { flex: 1 } }}>
          <FilterCheckbox
            checked={filters.highlight}
            label={"Nouveau"}
            onChange={onChangeFilter}
            name="highlight"
          />

          <FilterCheckbox
            checked={filters.promo}
            label={"Promo"}
            onChange={onChangeFilter}
            name="promo"
          />
        </Content>
      </MobileContainer>
    );
  }

  return (
    <Container>
      <StyledSearchBar
        onChange={onChangeSearch}
        onClear={onClear}
        value={search}
        fullWidth={isMobile}
        placeholder="Rechercher..."
      />

      {producers && producers.length > 1 && (
        <BaseSelect
          onChange={onChangeFilter}
          name="producer"
          value={filters.producer}
          fullWidth={isMobile}
          multiple={true}
          renderValue={(value: any) =>
            renderValue(value, producers, "Toutes les exploitations")
          }
        >
          {producers.map((producer) => (
            <MenuItem key={producer.value} value={producer.value}>
              <Checkbox
                checked={isSelected(filters.producer, producer.value)}
              />
              {producer.label}
            </MenuItem>
          ))}
        </BaseSelect>
      )}

      <Select
        onChange={onChangeFilter}
        name="category"
        value={filters.category}
        fullWidth={isMobile}
        multiple={true}
        renderValue={(value: any) =>
          renderValue(value, categories, "Toutes les catégories")
        }
      >
        {categories.map((category) => (
          <MenuItemStyle
            isSelected={isSelected(filters.category, category.value)}
            value={category.value}
            key={category.value}
          >
            <Box>
              <Checkbox
                checked={isSelected(filters.category, category.value)}
              />
              {category.label}
            </Box>
          </MenuItemStyle>
        ))}
      </Select>

      <BaseSelect
        onChange={onChangeFilter}
        name="sortBy"
        value={filters.sortBy}
        fullWidth={isMobile}
      >
        <MenuItem value="default" disabled>
          Trier par
        </MenuItem>
        <MenuItem value="az">A-Z</MenuItem>
        <MenuItem value="za">Z-A</MenuItem>
        <MenuItem value="asc">Prix croissants</MenuItem>
        <MenuItem value="desc">Prix décroissants</MenuItem>
      </BaseSelect>

      <FilterCheckboxContainer>
        <FilterCheckbox
          checked={filters.highlight}
          label={"Nouveau"}
          onChange={onChangeFilter}
          name="highlight"
        />

        <FilterCheckbox
          checked={filters.promo}
          label={"Promo"}
          onChange={onChangeFilter}
          name="promo"
        />
      </FilterCheckboxContainer>
      <FlexSpacer />

      <ViewToggles>
        <Label variant="caption">Affichage</Label>

        <Toggles>
          <Tooltip title="Liste" aria-label="list">
            <ToggleButton
              selected={currentView === CommonTypes.ProductViewMode.LIST}
              onClick={() => changeView(CommonTypes.ProductViewMode.LIST)}
            >
              <RiListCheck />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Galerie" aria-label="card">
            <ToggleButton
              selected={currentView === CommonTypes.ProductViewMode.CARD}
              onClick={() => changeView(CommonTypes.ProductViewMode.CARD)}
            >
              <RiImageLine />
            </ToggleButton>
          </Tooltip>
        </Toggles>
      </ViewToggles>
    </Container>
  );
};

export default Filters;
