import React, { useCallback, useEffect, useMemo, useState } from "react";
import MuiContainer from "@mui/material/Container";
import { createGlobalStyle } from "styled-components";
import { styled } from "@mui/material/styles";
import { Route, Routes, useNavigate, useResolvedPath } from "react-router-dom";
import constants from "../../constants";
import { usePresentation } from "../../context/PresentationContext";
import Meta from "../../common/Meta";
import Navigation from "./EmbeddedNavigation";
import Bag from "./Icons/Animated/Bag";
import localStorageManager from "../../helpers/localStorageManager";
import Delivery from "./Icons/Animated/Delivery";
import Payment from "./Icons/Animated/Payment";
import Information from "./Icons/Animated/Information";
import AgeConfirmationModal from "../../common/AgeConfirmationModal";
import {
  ButtonFab,
  PageLoader,
  storageBrandConfigItem,
  useIsMobile,
} from "@kuupanda/commons";
import SimplifiedCustomerForm from "../SimplifiedCustomerForm";
import SimplifiedDeliveryForm from "../SimplifiedDeliveryForm";
import SimplifiedPaymentForm from "../SimplifiedPaymentForm";
import SimplifiedOrderSubmit from "../SimplifiedOrderSubmit";
import OrderSuccess from "../OrderSuccess";
import StoreClosed from "../StoreClosed";
import {
  checkIfHasColdProducts,
  checkIfIsSelectable,
  getAvailableDates,
  getContactPointInfo,
  getMaxAvailableDate,
  getMaxPreparationTime,
} from "../../helpers/contactPoint";
import {
  getProductsForProducer,
  getVariableWeightProducts,
} from "../../helpers/cart";
import { useFormInfo } from "../../hooks/useFormInfo";
import { validateCustomerInfo } from "../../helpers/customer";
import SimplifiedOrderSummary from "../SimplifiedOrderSummary";
import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";

import {
  calculateDeliveryRate,
  isCpDelivery,
  isCpRelay,
  isCPTakeaway,
  isCpExternalShipping,
} from "../../helpers/delivery";
import {
  RiArrowUpLine,
  RiSearchLine,
  RiCloseLine,
  RiArrowDownLine,
} from "react-icons/ri";
import { isStoreOpen } from "../../helpers/store";
import StoreClosedDay from "../StoreClosedDay";
import { useApiConfig } from "../../context/ApiContext";
import { useHttpClient } from "../../hooks/useHttpClient";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import VariableExplanationModal from "../../common/VariableWeight/VariableExplanationModal";
import { closeModalIframe, setModalIframePosition } from "../../helpers/modal";
import { CommonTypes } from "@kuupanda/kuu-contract";
import {
  cleanProductId,
  getDateUTCString,
  getFormUrl,
} from "../../helpers/misc";
import { createStockNotifyRequest } from "../../helpers/stock";
import { reCaptchaWrapper } from "../../helpers/reCaptcha";
import PaymentComplete from "../PaymentComplete";
import { getMinAvailableUnits } from "../../helpers/product";
import EmbeddedSimplifiedHeader from "./EmbeddedSimplifiedHeader";
import EmbeddedSimplifiedProductList from "./EmbeddedSimplifiedProductList";
import EmbeddedCartItems from "./EmbeddedCartItems";
import { storeCartInLocalStorage } from "../../helpers/LocalStorageRepository";
import toast from "react-hot-toast";

const GlobalStyle = createGlobalStyle<{ isEmbedded: boolean }>`
  html,
  body,
  #root {
    height: auto;
    background: #FBFBFF;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
  }`;

const Container = styled(MuiContainer)`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BottomSpacer = styled("div")`
  height: 100px;
`;
const Spacer = styled("div")`
  height: 24px;
`;

const HeaderSpacer = styled("div")`
  height: 48px;
`;

const NavigationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "20px",
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: 3,
  [theme.breakpoints.down("md")]: {
    top: "60px",
  },
}));

const Popover = styled("div")(({ theme }) => ({
  right: "0",
  position: "absolute",
  top: "60px",
  border: `2px solid ${theme.palette.grey[100]}`,
  borderRadius: 8,
  background: "white",
  padding: "10px",
  zIndex: "6",
  width: "400px",
  [theme.breakpoints.down("md")]: {
    position: "fixed",
    left: "16px",
    right: "16px",
    bottom: "80px",
    top: "unset",
    width: "unset",
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const JumpToTop = styled(ButtonFab, {
  shouldForwardProp: (prop: any) => prop !== "isMobile" && prop !== "embedded",
})<{ embedded: boolean; isMobile: boolean }>(
  ({ embedded, isMobile }) => `
      position: fixed;
  bottom: 20px;
  ${!embedded && `right: 20px;`}
  ${!embedded && isMobile && `display: none`}
`
);

const StyledSearchBarMobile = styled(Input)<{ fixed?: boolean }>(
  ({ theme }) => `
  min-width: 200px;
  background: white;
  padding: 10px;
  font-weight: 400;
  line-height: 150%;
  font-size: 16px;
  color: ${theme.palette.grey[700]};
  svg {
    fill: ${theme.palette.grey[800]};
  }
  border-bottom: 2px solid ${theme.palette.grey[200]};
    position: fixed;
    top: 0;
    z-index: 2
`
);

const keyMap: { [key: string]: number } = {
  products: 0,
  information: 40,
  delivery: 230,
  payment: 260,
};

const EmbeddedStore: React.FC<{
  isLoading: boolean;
  productsByCategory: any[];
  categories: { value: string; label: string }[];
  store: any;
  contactPoints: any[];
  saleChannel: string;
  onSubmit: any;
  isGroup: boolean;
  validCodes: any[];
  couponLines: any[];
  setCartAmount: (amount: number) => void;
  setCustomer: (customer: string) => void;
  setCartProducts: (products: Record<string, number>) => void;
  setCodes: any;
  initialCodes: string[];
  codesEvaluated: any[];
  codes: any[];
  invalidDiscounts: any[];
  embedded: boolean;
  producerId: string;
  producer: any;
  storeFrontSlug?: string;
}> = ({
  isLoading,
  categories,
  store,
  saleChannel,
  isGroup,
  productsByCategory,
  producerId,
  contactPoints,
  producer,
  couponLines,
  setCodes,
  setCartAmount,
  setCustomer,
  setCartProducts,
  codes,
  storeFrontSlug,
  onSubmit,
  validCodes,
  invalidDiscounts,
  codesEvaluated,
  initialCodes,
}) => {
  const {
    isEmbedded,
    presentationOptions: { hideCover, hideInfo },
  } = usePresentation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const path = useResolvedPath(".");

  const { apiRoot }: any = useApiConfig();
  const httpClient = useHttpClient(apiRoot);

  const {
    formInfo: {
      customerInfo,
      contactPoint,
      clientAddress,
      deliveryRate,
      shipmentInfo,
      orderDueTo,
      billingAddress,
    },
    isFormInvalid,
    setFormInfo,
    loadedRecovery,
  } = useFormInfo(producerId, contactPoints, saleChannel);
  const timerRef = React.useRef<any>(null);
  const scrollRef = React.useRef<any>(null);
  const checkoutRef = React.useRef<any>(null);

  const [selected, setSelected] = useState("products");
  const [ageRestrictedProduct, setAgeRestrictedProduct] = useState<any>({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [comment, setComment] = useState(undefined);
  const [clicked, setIsClicked] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [hasScrolled, setHasScrolled] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(
    store?.paymentMethods[0].type
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPaymentBeingProcessed, setIsPaymentBeingProcessed] = useState(false);
  const [isCustomerInfoValid, setIsCustomerInfoValid] = useState(false);
  const [isDeliveryInfoValid, setIsDeliveryInfoValid] = useState(false);
  const [isVariableWeightModalOpen, setIsVariableWeightModalOpen] =
    useState(false);

  const [cart, setCart] = useState<{ [key: string]: any }>({});

  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState<{
    category: string[];
    sortBy: string;
    promo: boolean;
    highlight: boolean;
    producer: string[];
  }>({
    category: [],
    sortBy: "default",
    promo: false,
    highlight: false,
    producer: [],
  });

  const numCartItems = Object.values(cart).reduce(
    (quantity: number, item: any) => quantity + parseFloat(item.quantity),
    0
  );

  const isStoreClosedForPeriod = !isStoreOpen(new Date(), store.schedule);

  useEffect(() => {
    const productListMap = productsByCategory
      .flatMap((products: any) => products.products)
      .reduce(
        (acc: any, product: any) => ({
          ...acc,
          [product.id]: product,
        }),
        {}
      );

    setCart((cart) => {
      let newCart: { [key: string]: any } = {};
      for (const key of Object.keys(cart)) {
        const product = productListMap[key]
          ? productListMap[key]
          : cart[key].product;

        newCart[key] = {
          ...cart[key],
          product: { ...product, producer: cart[key].product.producer },
        };
      }

      return newCart;
    });
  }, [productsByCategory]);

  const items = useMemo(
    () => [
      {
        key: "products",
        name: "Les produits",
        icon: (selected: boolean) => <Bag selected={selected} />,
      },
      {
        key: "information",
        name: "Informations",
        icon: (selected: boolean) => <Information selected={selected} />,
      },
      {
        key: "delivery",
        name: "Livraison/Retrait",
        icon: (selected: boolean) => <Delivery selected={selected} />,
      },
      {
        key: "payment",
        name: "Paiement",
        icon: (selected: boolean) => <Payment selected={selected} />,
      },
    ],
    []
  );

  const { clientId, storeName, city, bannerUrl, fullName } = store;

  const onTabChange = useCallback((selected: string) => {
    setSelected(selected);
    setIsClicked(true);

    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const hasConfirmedOver18 = () =>
    localStorageManager.getItem("isOlderThan18") === "true" || false;

  const handleAgeConfirmation = (isOlder: boolean) => {
    localStorageManager.setItem("isOlderThan18", isOlder.toString());
    if (isOlder) {
      handleCartChange(ageRestrictedProduct);
    }
    setIsOpenModal(false);
  };

  const scrollHorizontal = useCallback((scrollOffset: number) => {
    if (scrollRef?.current) {
      scrollRef.current.scrollLeft = scrollOffset;
    }
  }, []);

  const onScrollNavigation = useCallback(() => {
    const offset = isMobile ? 300 : 350;
    const scrolled = window.scrollY - offset;

    const productHeight =
      document.getElementById("products")?.clientHeight || 0;
    const informationHeight =
      document.getElementById("information")?.clientHeight || 0;
    const deliveryHeight =
      document.getElementById("delivery")?.clientHeight || 0;

    //if scroll was triggered by the anchor don't change tab
    if (clicked) return;

    if (scrolled < productHeight) {
      setSelected("products");
    } else if (
      scrolled > productHeight &&
      scrolled < productHeight + informationHeight
    ) {
      setSelected("information");
      scrollHorizontal(keyMap["products"]);
    } else if (
      scrolled > productHeight + informationHeight &&
      scrolled < productHeight + informationHeight + deliveryHeight
    ) {
      setSelected("delivery");
      scrollHorizontal(keyMap["information"]);
    } else if (scrolled > productHeight + informationHeight + deliveryHeight) {
      setSelected("payment");
      scrollHorizontal(keyMap["delivery"]);
    }
  }, [isMobile, clicked, scrollHorizontal]);

  useEffect(() => {
    window.addEventListener("scroll", onScrollNavigation);

    return () => window.removeEventListener("scroll", onScrollNavigation);
  }, [onScrollNavigation]);

  const onScroll = useCallback(() => {
    setHasScrolled(window.scrollY > 450);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const updateCartAmount = async (cart: any) => {
    const productTotal = Object.values(cart).reduce(
      (acc: number, { quantity, product }: any) =>
        acc + quantity * product.price,
      0
    );

    const productTotalVat = Object.values(cart).reduce(
      (acc: number, { quantity, product }: any) =>
        acc + quantity * (product.price * (1 + product.vat || 0)),
      0
    );

    const productTotalToUse =
      saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
        ? productTotalVat
        : productTotal;

    setCartAmount(productTotalToUse);
    setCartProducts(
      Object.keys(cart).reduce((acc: Record<string, number>, key) => {
        const productId = cleanProductId(key);
        return {
          ...acc,
          [productId]: parseFloat(cart[key].quantity),
        };
      }, {})
    );
  };

  const debouncedUpdateCartAmount = useDebouncedCallback(async () => {
    await updateCartAmount(cart);
  }, 1500);

  const handleValidate = ({
    product,
    quantity,
  }: {
    product: any;
    quantity: string;
  }) => {
    let saleQuantity = quantity;

    const unit = getMinAvailableUnits(product);

    if (product.minSaleQuantity > 0 && quantity < product.minSaleQuantity) {
      toast.error(
        `Si vous souhaitez inclure ce produit à votre commande, alors vous devez en commander un minimum de ${product.minSaleQuantity} ${unit}`,
        {
          id: "min",
        }
      );

      saleQuantity = product.minSaleQuantity;
    }

    if (quantity > product.availableQuantity) {
      toast.error(
        "vous avez atteint la quantité maximale autorisée pour ce produit",
        {
          id: "max",
        }
      );

      saleQuantity = product.availableQuantity;
    }

    return saleQuantity;
  };
  const handleCartChange = useCallback(
    ({
      product,
      quantity,
      size,
    }: {
      product: any;
      quantity: any;
      size?: string;
    }) => {
      const newCart: { [key: string]: any } = { ...cart };
      let key = product.id;

      const value = quantity.toString().startsWith("0")
        ? quantity
        : Math.max(1, Math.min(product.availableQuantity, quantity));

      if (size) {
        key = `${product.id}_${size}`;
      }

      const isOlderThan18 = hasConfirmedOver18();

      if (product.ageVerification && !isOlderThan18) {
        setAgeRestrictedProduct({ product, quantity: value, size });
        setIsOpenModal(true);

        return;
      }

      if (parseFloat(quantity) === 0) {
        delete newCart[key];
      } else {
        newCart[key] = {
          quantity: value,
          product: { ...product, producer },
          size,
        };
      }

      setCart(newCart);
      storeCartInLocalStorage(newCart);

      debouncedUpdateCartAmount(newCart);
    },
    [cart, producer, debouncedUpdateCartAmount]
  );

  const handleCommentChange = useCallback(
    (event: any) => {
      setComment(event.target.value);
    },
    [setComment]
  );

  const availableDate = useMemo(() => getMaxAvailableDate(cart), [cart]);

  const preparationTime = useMemo(() => getMaxPreparationTime(cart), [cart]);

  const cartSubTotal = useMemo(
    () =>
      Object.values(cart).reduce(
        (acc: number, { quantity, product }: any) =>
          acc + quantity * product.price,
        0
      ),
    [cart]
  );

  const checkCartIsValid = useCallback(async () => {
    const cartDto = Object.fromEntries(
      Object.entries(cart).map(([productId, productInfo]) => {
        //remove size from the id
        const id = cleanProductId(productId);
        return [
          id,
          {
            ...productInfo,
            product: {
              ...productInfo.product,
              producer: {
                id: productInfo.product.producer.id,
                isGroup: productInfo.product.producer.isGroup || false,
              },
            },
          },
        ];
      })
    );

    const newCart = await httpClient.post("/cart/validateCart", {
      cart: cartDto,
      cartAmount: Object.values(cart).reduce(
        (acc: number, it: any) => acc + it.product.price * it.quantity,
        0
      ),
      saleChannel: saleChannel,
      component: "FORM",
      storeFrontSlug: storeFrontSlug,
      customer: customerInfo.email,
      cartProducts: Object.keys(cart).reduce(
        (acc: Record<string, number>, key) => {
          const productId = cleanProductId(key);
          return {
            ...acc,
            [productId]: parseFloat(cart[key].quantity),
          };
        },
        {}
      ),
      ...(validCodes && { codes: validCodes }),
    });

    return newCart.productErrors || {};
  }, [httpClient, validCodes, cart, saleChannel, storeFrontSlug, customerInfo]);

  const variableWeightProducts = useMemo(
    () => getVariableWeightProducts(cart),
    [cart]
  );

  const hasVariableWeightProducts = useMemo(
    () => variableWeightProducts.length > 0,
    [variableWeightProducts]
  );

  const orderDTO = useMemo(() => {
    const dto: any = {
      products: Object.values(cart).map(({ quantity, product, size }: any) => ({
        productCustom: product.id,
        quantity: parseFloat(quantity),
        size: size,
      })),
      paymentMethod: "_cash",
      orderDueTo: orderDueTo && getDateUTCString(orderDueTo),
      contactPoint: contactPoint,
      status: "_new",
      origin: constants.ORDER_ORIGIN.FORM,
      saleChannel: saleChannel,
      customer: { ...customerInfo, billingAddress: billingAddress },
      clientAddress: clientAddress,
      fees:
        deliveryRate && deliveryRate > 0
          ? [{ rate: deliveryRate, type: "DELIVERY" }]
          : [],
      comment: comment,
      codes,
      shipmentInfo: shipmentInfo && { relayPoint: shipmentInfo },
      storeFrontConfigSlug: storeFrontSlug,
      confirmed: !hasVariableWeightProducts,
      ...(isEmbedded && {
        metadata: {
          embedded: true,
          hostname: window.location.hostname,
        },
      }),
    };
    if (isGroup) {
      dto.group = store.id;
    } else {
      dto.producer = store.id;
    }

    return dto;
  }, [
    cart,
    orderDueTo,
    shipmentInfo,
    contactPoint,
    saleChannel,
    customerInfo,
    clientAddress,
    deliveryRate,
    comment,
    codes,
    storeFrontSlug,
    isGroup,
    store.id,
    billingAddress,
    hasVariableWeightProducts,
    isEmbedded,
  ]);

  const handleOrderComplete = useCallback(
    (order: any) => {
      navigate(`success`, { state: { order } });
      setCart({});
      setCartProducts({});
      setCodes(initialCodes);
      setCartAmount(0);
      setCustomer("");
    },
    [
      initialCodes,
      navigate,
      setCartAmount,
      setCartProducts,
      setCodes,
      setCustomer,
    ]
  );

  const handleCreditCardPayment = useCallback(async () => {
    await reCaptchaWrapper(async function (recaptchaToken: string) {
      const url = `${window.location.origin}${window.location.pathname}`;

      const payInCreationDTO = {
        returnURL: `${url}/payment-complete?embedded=true&hideCover=${hideCover}&hideInfo=${hideInfo}`,
        locale: "fr",
        amount: 100,
        buyer: customerInfo,
        orders: [orderDTO],
      };

      try {
        const res = await httpClient.post(`/payment/mangopay/payin`, {
          ...payInCreationDTO,
          recaptchaToken,
        });

        if (res && res.redirectURL) {
          //this is a bit of hack to reset the height before redirecting to mangopay
          if (isEmbedded && window.parentIFrame && window.parentIFrame.size) {
            window.parentIFrame.size(1000);
          }

          window.location = res.redirectURL;
        } else {
          setIsPaymentBeingProcessed(false);
          setIsFormSubmitting(false);
        }
      } catch (e) {
        setIsPaymentBeingProcessed(false);
        setIsFormSubmitting(false);

        console.log(e);
      }
    });
  }, [customerInfo, hideCover, hideInfo, httpClient, isEmbedded, orderDTO]);

  const handleOfflinePayment = useCallback(
    async ({ paymentMethod }: any) => {
      setIsPaymentBeingProcessed(false);
      setIsFormSubmitting(true);
      try {
        const order = await onSubmit({
          ...orderDTO,
          paymentMethod,
        });

        handleOrderComplete(order.data);
      } catch (e) {
        console.log(e);

        toast.error(
          "Une erreur s'est produite lors du traitement de votre commande"
        );
      }

      setIsPaymentBeingProcessed(false);
      setIsFormSubmitting(false);
    },
    [handleOrderComplete, onSubmit, orderDTO]
  );

  const handleStartPaymentProcess = useCallback(async () => {
    setIsFormSubmitting(true);

    const errors = await checkCartIsValid();

    if (Object.keys(errors).length > 0) {
      toast.error(
        "Le producteur a modifié les informations d’un ou plusieurs produits de votre panier. Veuillez svp renouveler votre commande."
      );

      setIsFormSubmitting(false);
      return;
    }

    setIsPaymentBeingProcessed(true);

    if (paymentMethod === constants.PAYMENT_METHOD.CREDIT_CARD) {
      return await handleCreditCardPayment();
    }

    return await handleOfflinePayment({ paymentMethod });
  }, [
    checkCartIsValid,
    paymentMethod,
    handleOfflinePayment,
    handleCreditCardPayment,
  ]);

  const handleChangeCustomerInfo = useCallback(
    (info: any) => {
      setFormInfo((prev: any) => ({
        ...prev,
        customerInfo: { ...prev.customerInfo, ...info },
      }));
    },
    [setFormInfo]
  );

  const handleChangeAddressInfo = useCallback(
    (info: any) => {
      setFormInfo((prev: any) => ({
        ...prev,
        clientAddress: info,
      }));
    },
    [setFormInfo]
  );

  const handleChangeBillingAddressInfo = useCallback(
    (info: any) => {
      setFormInfo((prev: any) => ({
        ...prev,
        billingAddress: info,
      }));
    },
    [setFormInfo]
  );

  const handleFormInfoChange = useCallback(
    (key: string, value: any) => {
      setFormInfo((prev: any) => ({ ...prev, [key]: value }));
    },
    [setFormInfo]
  );

  const codeEvaluationStatus = useMemo(() => {
    if (!codesEvaluated) {
      return constants.CODE_EVALUATION_STATUS.UNDETERMINED;
    }
    if (!validCodes || validCodes.length === 0) {
      return constants.CODE_EVALUATION_STATUS.INVALID;
    }
    if (validCodes && validCodes.length > 0) {
      return constants.CODE_EVALUATION_STATUS.VALID;
    }
  }, [validCodes, codesEvaluated]);

  const [selectedRate, invalidRate] = useMemo(() => {
    if (!contactPoint) return [undefined, true];

    const contactPointInfo = getContactPointInfo(contactPoints, {
      id: contactPoint,
    });

    if (!contactPointInfo) return [undefined, true];

    const isDelivery = isCpDelivery(contactPointInfo.type);

    const cartProducts = getProductsForProducer(cart, producerId, true);

    let selectedRate = calculateDeliveryRate({
      cartProducts,
      contactPointInfo,
      cartSubTotal,
      clientAddress,
    });

    const availableDates = getAvailableDates(
      contactPointInfo,
      availableDate,
      preparationTime,
      contactPoints,
      contactPoint
    );

    if (
      availableDates.length === 0 &&
      !isCpExternalShipping(contactPointInfo.type)
    ) {
      return [undefined, true];
    }

    if (isDelivery && clientAddress) {
      if (!clientAddress.lat || !clientAddress.lng) {
        return [undefined, true];
      }
    }

    if (contactPointInfo.clientDeliveryAmount) {
      if (cartSubTotal >= contactPointInfo.clientDeliveryAmount.minAmount) {
        selectedRate = contactPointInfo.clientDeliveryAmount.amount;
      }
    }

    if (contactPointInfo.clientDeliveryAmount && selectedRate > 0) {
      if (cartSubTotal >= contactPointInfo.clientDeliveryAmount.minAmount) {
        selectedRate = contactPointInfo.clientDeliveryAmount.amount;
      }
    }

    const invalidRate = isDelivery
      ? clientAddress && (selectedRate === undefined || selectedRate === null)
      : false;
    return [selectedRate, invalidRate];
  }, [
    contactPoint,
    cart,
    producerId,
    clientAddress,
    cartSubTotal,
    contactPoints,
    availableDate,
    preparationTime,
  ]);

  useEffect(() => {
    if (customerInfo) {
      const valid = validateCustomerInfo(customerInfo, saleChannel);
      setIsCustomerInfoValid(valid);
    }
    const contactPointInfo =
      contactPoint &&
      getContactPointInfo(contactPoints, {
        id: contactPoint,
      });
    const isSelectable =
      contactPointInfo &&
      checkIfIsSelectable(
        contactPointInfo,
        checkIfHasColdProducts(cart),
        cartSubTotal,
        cart,
        producerId
      );
    const clientAddressOk = Boolean(
      contactPointInfo &&
        (isCPTakeaway(contactPointInfo?.type) ||
          (clientAddress &&
            clientAddress.text &&
            clientAddress.text.length > 0))
    );

    const relayOk = Boolean(
      contactPointInfo &&
        (!isCpRelay(contactPointInfo?.type) ||
          (shipmentInfo && Object.keys(shipmentInfo).length > 0))
    );

    setIsDeliveryInfoValid(
      contactPoint && clientAddressOk && isSelectable && relayOk
    );
  }, [
    clientAddress,
    contactPoint,
    customerInfo,
    saleChannel,
    cart,
    cartSubTotal,
    contactPoints,
    shipmentInfo,
    producerId,
  ]);

  const scrollToTop = useCallback(() => {
    if (isEmbedded && window.parentIFrame) {
      window.parentIFrame.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [isEmbedded]);

  const applyCoupons = useCallback(
    (codes: string[]) => {
      setCodes(codes);
      setCartAmount(cartSubTotal);
      setCustomer(customerInfo.email);
      setCartProducts(
        Object.keys(cart).reduce((acc: Record<string, number>, key) => {
          const productId = cleanProductId(key);
          return {
            ...acc,
            [productId]: parseFloat(cart[key].quantity),
          };
        }, {})
      );
    },
    [
      setCodes,
      setCartAmount,
      cartSubTotal,
      setCustomer,
      customerInfo.email,
      setCartProducts,
      cart,
    ]
  );

  const onVariableWeightClick = useCallback(() => {
    if (!isVariableWeightModalOpen) {
      setIsVariableWeightModalOpen(true);
      setModalIframePosition(isEmbedded, isMobile, "kuu-weight-modal");
    }
  }, [
    isEmbedded,
    isMobile,
    isVariableWeightModalOpen,
    setIsVariableWeightModalOpen,
  ]);

  const closeVariableWeightModal = useCallback(() => {
    setIsVariableWeightModalOpen(false);
    closeModalIframe(isEmbedded, isMobile, "kuu-weight-modal");
  }, [isEmbedded, isMobile]);

  const onChangeSearch = useCallback((e: any) => {
    const { value } = e.target;
    setSearch(value);
  }, []);

  const onClear = useCallback(() => {
    setSearch("");
  }, []);

  const onChangeFilter = useCallback((e: any) => {
    const { name } = e.target;

    const value = Object.prototype.hasOwnProperty.call(e.target, "checked")
      ? e.target.checked
      : e.target.value;

    setFilters((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const sorter = useCallback((products: any, sort: string) => {
    let sorted = products;
    if (sort === "az") {
      sorted = products.sort((a: any, b: any) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
    }

    if (sort === "za") {
      sorted = products.sort((a: any, b: any) =>
        b.name.localeCompare(a.name, undefined, { sensitivity: "base" })
      );
    }

    if (sort === "asc") {
      sorted = products.sort(
        (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
      );
    }

    if (sort === "desc") {
      sorted = products.sort(
        (a: any, b: any) => parseFloat(b.price) - parseFloat(a.price)
      );
    }

    return sorted;
  }, []);

  const orderBy = useCallback(
    (products: any, sort: string) => {
      return sorter(products, sort);
    },
    [sorter]
  );

  const handleFilter = useCallback(
    (productsToSort: any) => {
      let products = [...productsToSort];

      if (filters.producer && filters.producer.length > 0) {
        products = products.filter((item: { producer: { id: string } }) =>
          filters.producer.includes(item.producer.id)
        );
      }

      if (filters.category && filters.category.length > 0) {
        products = products.filter((item: { category: string }) =>
          filters.category.includes(item.category)
        );
      }

      if (search.length) {
        products = products.filter((product: any) =>
          product.name.toLowerCase().includes(search.toLowerCase())
        );
      }

      if (filters.promo) {
        products = products.filter(
          (product: any) => product.discounts && product.discounts.length > 0
        );
      }

      if (filters.highlight) {
        products = products.filter(
          (product: any) =>
            product.highlight === CommonTypes.ProductHighlightType.NEW
        );
      }
      return orderBy(products, filters.sortBy);
    },
    [filters, search, orderBy]
  );

  const onSubmitNotifyStock = useCallback(
    async (value: Record<string, any>) => {
      try {
        const dto = {
          email: value.email,
          productId: value.product.id,
          url: window.location.pathname,
          productName: value.product.name,
          farmName: storeName,
          producerName: fullName,
          brandId: storageBrandConfigItem.jsonParsedValue?.id || "",
        };

        await createStockNotifyRequest(dto);

        toast.success(
          "Vous recevrez un email lorsque ce produit sera à nouveau disponible à la vente"
        );
      } catch (e) {
        console.log(e);
        toast.error("Quelque chose s'est mal passé");
      }
    },
    [storeName, fullName]
  );

  const jumpToCheckout = useCallback(() => {
    setPopoverOpen(false);
    if (checkoutRef && checkoutRef.current) {
      checkoutRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  const formUrl = getFormUrl(isGroup, saleChannel, clientId, storeFrontSlug);

  const paymentMethods = useMemo(() => {
    if (!contactPoint || !contactPoints) {
      return store.paymentMethods;
    }
    const contactPointInfo = getContactPointInfo(contactPoints, {
      id: contactPoint,
    });

    return contactPointInfo && contactPointInfo.onlinePaymentRequired
      ? [{ type: CommonTypes.PaymentMethod.CREDIT_CARD }]
      : store.paymentMethods;
  }, [contactPoints, contactPoint, store.paymentMethods]);

  if (isLoading || !loadedRecovery) {
    return <PageLoader />;
  }

  return (
    <>
      <GlobalStyle isEmbedded={isEmbedded} />
      <Meta
        title={`${storeName} - Découvrez mes produits et passez commande en ligne`}
        description={`${storeName}, producteur à ${city}. Découvrez des produits locaux, autour de chez vous`}
        keywords={`${storeName},local,produits,locaux,${city},circuit,court`}
        ogTitle={`${storeName} - Découvrez mes produits et passez commande en ligne`}
        ogDescription="Trouvez des producteurs locaux proches de chez vous et commandez sans intermédiaire pour des produits de qualité à des prix accessibles"
        ogURL={formUrl}
        ogImage={bannerUrl}
      />
      <Routes>
        <Route
          index
          element={
            <>
              {isMobile && (
                <>
                  <StyledSearchBarMobile
                    placeholder={"Rechercher"}
                    disableUnderline
                    inputProps={{
                      "aria-label": "Rechercher",
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <RiSearchLine />
                      </InputAdornment>
                    }
                    endAdornment={
                      search &&
                      search.length > 0 && (
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={onClear}>
                            <RiCloseLine />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    onChange={onChangeSearch}
                    fullWidth
                    value={search}
                  />
                  <HeaderSpacer />
                </>
              )}

              <Container maxWidth="lg">
                <EmbeddedSimplifiedHeader
                  bannerUrl={store.bannerUrl}
                  categories={categories}
                  description={store.presentation}
                  email={store.email}
                  name={store.storeName}
                  phone={store.phone}
                  producer={store.fullName}
                  profilePicture={store.profileUrl}
                  address={store.address}
                  labels={store.labels}
                  hideNameOnForm={store.hideNameOnForm}
                  hideLocationOnForm={store.hideLocationOnForm}
                />

                <AgeConfirmationModal
                  isOpen={isOpenModal}
                  handleCloseModal={() => setIsOpenModal(false)}
                  handleConfirmation={handleAgeConfirmation}
                />

                <NavigationContainer>
                  <Navigation
                    selected={selected}
                    onChange={onTabChange}
                    items={items}
                    scrollRef={scrollRef}
                  />

                  <EmbeddedCartItems
                    cart={cart}
                    onHover={setPopoverOpen}
                    open={popoverOpen}
                  />

                  {popoverOpen && (
                    <Popover onMouseLeave={() => setPopoverOpen(false)}>
                      <Typography fontSize={22}>
                        Récapitulatif de la commande
                      </Typography>
                      <SimplifiedOrderSummary
                        cart={cart}
                        couponLines={couponLines}
                        deliveryRate={deliveryRate}
                        applyCoupons={applyCoupons}
                        saleChannel={saleChannel}
                        invalidDiscounts={invalidDiscounts}
                        codeEvaluationStatus={codeEvaluationStatus}
                        initialCodes={initialCodes}
                        promoEnabled={false}
                        onChangeCart={handleCartChange}
                        variableWeightProducts={variableWeightProducts}
                        paymentMethod={paymentMethod}
                        onVariableWeightClick={onVariableWeightClick}
                        onValidateCart={handleValidate}
                        variableWeightPercent={store.variableWeightPercent}
                      />

                      {isMobile && (
                        <Box
                          sx={{
                            display: "flex",
                            mt: 1,
                            justifyContent: "center",
                          }}
                        >
                          <ButtonFab
                            color={"secondary"}
                            size={"small"}
                            onClick={jumpToCheckout}
                          >
                            <RiArrowDownLine size={24} />
                          </ButtonFab>
                        </Box>
                      )}
                    </Popover>
                  )}
                </NavigationContainer>

                <Spacer />

                <EmbeddedSimplifiedProductList
                  productsByCategory={productsByCategory}
                  cart={cart}
                  categories={categories}
                  onChangeCart={handleCartChange}
                  saleChannel={saleChannel}
                  hasScrolled={hasScrolled}
                  isGroup={isGroup}
                  invalidDiscounts={invalidDiscounts}
                  onVariableWeightClick={onVariableWeightClick}
                  handleFilter={handleFilter}
                  filters={filters}
                  onChangeSearch={onChangeSearch}
                  onClear={onClear}
                  search={search}
                  onChangeFilter={onChangeFilter}
                  onSubmitNotifyStock={onSubmitNotifyStock}
                  onValidateCart={handleValidate}
                  store={store}
                />

                <Spacer />

                <SimplifiedCustomerForm
                  saleChannel={saleChannel}
                  customerInfo={customerInfo}
                  handleChangeCustomerInfo={handleChangeCustomerInfo}
                />

                <Spacer />

                <SimplifiedDeliveryForm
                  checkoutRef={checkoutRef}
                  producerId={producerId}
                  storeName={store && store.storeName}
                  contactPoints={contactPoints}
                  cart={cart}
                  cartSubTotal={cartSubTotal}
                  availableDate={availableDate}
                  preparationTime={preparationTime}
                  isSimplifiedStore={true}
                  contactPoint={contactPoint}
                  clientAddress={clientAddress}
                  handleFormInfoChange={handleFormInfoChange}
                  handleChangeAddressInfo={handleChangeAddressInfo}
                  isDeliveryInfoValid={isDeliveryInfoValid}
                  selectedRate={selectedRate}
                  invalidRate={invalidRate}
                  selectedDate={orderDueTo}
                />

                <Spacer />

                <SimplifiedPaymentForm
                  cart={cart}
                  onChangeCart={handleCartChange}
                  couponLines={couponLines}
                  deliveryRate={deliveryRate}
                  applyCoupons={applyCoupons}
                  saleChannel={saleChannel}
                  paymentMethods={paymentMethods}
                  onChangePaymentType={setPaymentMethod}
                  validCodes={validCodes}
                  invalidDiscounts={invalidDiscounts}
                  paymentMethod={paymentMethod}
                  codes={codes}
                  codeEvaluationStatus={codeEvaluationStatus}
                  initialCodes={initialCodes}
                  handleChangeBillingAddressInfo={
                    handleChangeBillingAddressInfo
                  }
                  billingAddress={billingAddress}
                  iban={producer.owner.iban}
                  store={store}
                  onVariableWeightClick={onVariableWeightClick}
                  onValidateCart={handleValidate}
                  variableWeightPercent={store.variableWeightPercent}
                />

                <Spacer />

                <SimplifiedOrderSubmit
                  comment={comment}
                  handleCommentChange={handleCommentChange}
                  onSubmit={handleStartPaymentProcess}
                  disabled={
                    isFormInvalid ||
                    !isDeliveryInfoValid ||
                    !isCustomerInfoValid ||
                    isFormSubmitting ||
                    isPaymentBeingProcessed ||
                    numCartItems === 0 ||
                    invalidRate
                  }
                  isCustomerInfoValid={isCustomerInfoValid}
                  isDeliveryInfoValid={isDeliveryInfoValid}
                  invalidRate={invalidRate}
                  submitting={isFormSubmitting}
                />

                <BottomSpacer />

                <JumpToTop
                  onClick={scrollToTop}
                  embedded={isEmbedded}
                  isMobile={isMobile}
                  color="secondary"
                >
                  <RiArrowUpLine size={24} />
                </JumpToTop>
              </Container>
            </>
          }
        />
        <Route
          path={"success"}
          element={<OrderSuccess base={path.pathname} />}
        />

        <Route
          path={"payment-complete"}
          element={
            <PaymentComplete
              handleOrderComplete={handleOrderComplete}
              base={path.pathname}
              setCart={setCart}
            />
          }
        />
      </Routes>
      {store.clientClosedStore && <StoreClosed store={store} />}
      {!store.clientClosedStore && isStoreClosedForPeriod && (
        <StoreClosedDay store={store} />
      )}

      {isVariableWeightModalOpen && (
        <VariableExplanationModal
          open={isVariableWeightModalOpen}
          onClose={closeVariableWeightModal}
          variableWeightPercent={store.variableWeightPercent}
        />
      )}
    </>
  );
};

export default EmbeddedStore;
