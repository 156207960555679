import { ReactComponent as Default } from "./default.svg";
import HoverSelected from "./hover_selected.webp";

import React from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")`
  display: flex;
  align-items: center;
`;

const Delivery: React.FC<{ selected: boolean }> = ({ selected }) => {
  return (
    <Container>
      {selected ? (
        <img
          src={HoverSelected}
          height={20}
          width={20}
          alt="Livraison/Retrait"
        />
      ) : (
        <Default height={20} width={20} />
      )}
    </Container>
  );
};

export default Delivery;
