import constants from "../constants";
import Joi from "joi";
import { tlds } from "@hapi/tlds";
import { Store as StoreContract } from "@kuupanda/kuu-contract";

const schema = Joi.object().keys({
  email: Joi.string().email({
    tlds: {
      allow: tlds,
    },
  }),
});

export const isValidEmail = (email: string) => {
  if (!email) {
    return false;
  }

  const result = schema.validate({ email }).error;

  return !Boolean(result);
};

export function validateCustomerInfo(customerInfo: any, saleChannel: string) {
  return Boolean(
    (saleChannel === constants.SALE_CHANNEL.PROFESSIONAL
      ? customerInfo.company
      : true) &&
      (saleChannel === constants.SALE_CHANNEL.STANDARD
        ? customerInfo.name
        : true) &&
      (saleChannel === constants.SALE_CHANNEL.STANDARD
        ? customerInfo.lastName
        : true) &&
      customerInfo.email &&
      isValidEmail(customerInfo.email) &&
      customerInfo.phone
  );
}

export const sanitizeCustomer = (
  customer: StoreContract.Customer & { company?: string }
) => {
  return {
    ...customer,
    lastName: customer?.lastName || undefined,
    name: customer?.name || undefined,
    company: customer?.company || undefined,
    email: customer.email?.toLowerCase().trim(),
  };
};
