import React, { useCallback, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { usePresentation } from "../context/PresentationContext";
import ProductView from "./ProductView";

import Filters from "../common/Filters";
import { CommonTypes } from "@kuupanda/kuu-contract";

const Container = styled("div")`
  margin-top: 16px;
  background: white;
  border-radius: 8px;
  width: 100%;
`;

const Content = styled("div")(({ theme }) => ({
  padding: "16px 16px",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
  },
}));

const Spacer = styled("div")`
  height: 24px;
`;

const getProducers = (products: any) => {
  const map = products
    .flatMap((product: any) => product.products)
    .reduce(
      (acc: Record<string, string>, product: any) => ({
        ...acc,
        [product.producer.id]: product.producer.storeName,
      }),
      {}
    );

  return Object.keys(map).map((producer) => ({
    value: producer,
    label: map[producer],
  }));
};

const SimplifiedProductList: React.FC<{
  categories: { value: string; label: string }[];
  productsByCategory: any;
  cart: any;
  onChangeCart: any;
  saleChannel: any;
  isGroup: boolean;
  invalidDiscounts: any[];
  onVariableWeightClick: () => void;
  handleFilter: (products: any) => any[];
  filters: {
    category: string[];
    sortBy: string;
    promo: boolean;
    highlight: boolean;
  };
  onChangeSearch: (e: any) => void;
  onClear: () => void;
  search: string;
  onChangeFilter: (products: any) => void;
  onSubmitNotifyStock: (value: Record<string, any>) => Promise<void>;
  onValidateCart: any;
  store: Record<string, any>;
}> = ({
  categories,
  productsByCategory,
  cart,
  onChangeCart,
  saleChannel,
  isGroup,
  invalidDiscounts,
  onVariableWeightClick,
  handleFilter,
  filters,
  onChangeSearch,
  onClear,
  search,
  onChangeFilter,
  onSubmitNotifyStock,
  onValidateCart,
  store,
}) => {
  const producers = useMemo(
    () => getProducers(productsByCategory),
    [productsByCategory]
  );

  const {
    presentationOptions: { productViewMode },
  } = usePresentation();

  const [currentView, setCurrentView] = useState(productViewMode);

  const changeView = useCallback((view: CommonTypes.ProductViewMode) => {
    setCurrentView(view);
  }, []);

  const productsGroupedByCategory = useMemo(
    () =>
      Object.values(productsByCategory).reduce(
        (acc: { [category: string]: any[] }, obj: any) => {
          const products = handleFilter(obj.products);
          if (products.length === 0) {
            return acc;
          }
          return {
            ...acc,
            [obj.category]: products,
          };
        },
        {}
      ),
    [productsByCategory, handleFilter]
  );

  return (
    <Container id="products">
      <Content>
        {invalidDiscounts.map((error) => (
          <Typography
            fontStyle="italic"
            color={"textSecondary"}
            fontSize={14}
            component={"div"}
          >
            {error.error}
          </Typography>
        ))}

        <Filters
          changeView={changeView}
          onChangeSearch={onChangeSearch}
          onClear={onClear}
          search={search}
          onChangeFilter={onChangeFilter}
          filters={filters}
          categories={categories}
          currentView={currentView}
          producers={producers}
        />

        <Spacer />

        <ProductView
          currentView={currentView}
          products={productsGroupedByCategory}
          onChangeCart={onChangeCart}
          cart={cart}
          saleChannel={saleChannel}
          isGroup={isGroup}
          categories={categories}
          onVariableWeightClick={onVariableWeightClick}
          onSubmitNotifyStock={onSubmitNotifyStock}
          onValidateCart={onValidateCart}
          store={store}
        />

        {Object.keys(productsGroupedByCategory).length === 0 && (
          <Typography>Aucun résultat correspond à cette recherche</Typography>
        )}
      </Content>
    </Container>
  );
};

export default SimplifiedProductList;
